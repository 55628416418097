import { useEffect, useState } from "react";
import { db } from "../firebase";
import { ref, get } from "firebase/database";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase";

//약관 동의 bull state 회원가입 페이지
//header get auth info self

function UsagePage() {
    const navigate = useNavigate();

    const [billarray, setbillarray] = useState([]);
    
    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            if (user) {
                const billRef = ref(db, "/users/" + auth.currentUser.uid + "/bill/");
                get(billRef).then((snapshot) => {
                    let arr = [];
                    snapshot.forEach((childSnapshot) => {
                        const childData = childSnapshot.val();
                        let a = {};
                        a["KoreanName"] = childData.KoreanName;
                        a["FirstName"] = childData.FirstName;
                        a["LastName"] = childData.LastName;
                        a["Address"] = childData.Address;
                        a["Address2"] = childData.Address2;
                        a["customcode"] = childData.customcode;
                        a["payment"] = childData.payment;
                        a["request"] = childData.request;
                        a["coupon"] = childData.coupon;
                        a["couponprice"] = childData.couponprice;
                        a["couponkey"] = childData.couponkey;
                        a["date"] = childData.date;
                        a["status"] = childData.status;
                        a["type"] = childData.type;
                        a["price"] = childData.price;
                        a["dilivered"] = childData.dilivered;
                        a["paymentkey"] = childData.paymentkey;
                        a["key"] = childSnapshot.key;
                        if(childData.order){
                            a["order"] = childData.order;
                        } else{
                            a["orderlist"] = childData.orderlist;
                        }
                        arr.push(a);
                    });
                    arr.sort((a, b) => b.date - a.date);
                    setbillarray(arr);
                }).catch((error) => {
                    
                });
            } else {
                
            }
        });
    }, []);

    const billing = (data) => {
        if(data.order){
            navigate('/billing/' + data.key, {state: {type: data.type, price : data.price , KoreanName : data.KoreanName, FirstName : data.FirstName, dilivered : data.dilivered,
                LastName : data.LastName, Address : data.Address, Address2 : data.Address2, customcode : data.customcode, payment : data.payment, request : data.request, order : data.order,
                coupon : data.coupon, couponprice : data.couponprice, couponkey : data.couponkey, date : data.date, status : data.status, key : data.key, paymentkey : data.paymentkey  }});
        } else{
            navigate('/billing/' + data.key, {state: {type: data.type, price : data.price , KoreanName : data.KoreanName, FirstName : data.FirstName, dilivered : data.dilivered,
                LastName : data.LastName, Address : data.Address, Address2 : data.Address2, customcode : data.customcode, payment : data.payment, request : data.request, orderlist : data.orderlist,
                coupon : data.coupon, couponprice : data.couponprice, couponkey : data.couponkey, date : data.date, status : data.status, key : data.key, paymentkey : data.paymentkey  }});
        }
    }
        

    return (
        <div className="Coupon-background">
            <div className='Billing-title'>
                <h1 className='contents-text-title font18'>주문내역</h1>
            </div>
            {billarray.map((bill, index) => (
            <div className="Billing-div" onClick={() => billing(bill)} key={index}>
                <div className="Billing-contents-div-text">
                <div className="Billing-contents-title">
                    {
                        bill.status == "결제완료" ? 
                            bill.dilivered : 
                            bill.status
                    }
                </div>
                <div className="Billing-contents-text">
                    {bill.KoreanName} / {bill.FirstName} {bill.LastName}
                </div>
                <div className="Billing-contents-address">
                    {bill.Address}{bill.Address2}
                </div>
                <div className="Billing-contents-status">
                    {bill.status}
                </div>
                <div className="Billing-contents-date">
                    {new Date(bill.date).getFullYear()
                        + "-" + (new Date(bill.date).getMonth() + 1) + "-"  + new Date(bill.date).getDate()}
                </div>
                </div>
            </div>
            ))
            }
        </div>
    );
  }

export default UsagePage;
