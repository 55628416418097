import { useEffect, useState } from "react";
import { db } from "../firebase";
import { ref, get, set } from "firebase/database";
import { useNavigate, useLocation } from "react-router-dom";
import { auth } from "../firebase";

import CouponCompnent from "./CouponComponent";


//약관 동의 bull state 회원가입 페이지
//header get auth info self

function CouponPage() {
    const navigate = useNavigate();
    const pathname = useLocation().pathname;

    const [couponcode, setcouponcode] = useState("");
    const [couponsarray, setcouponsarray] = useState([]);

    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            if (user) {
                loadcoupons();
            } else {
                
            }
        });
    }, []);

    const loadcoupons = () => {
        
        const testRef = ref(db, "/users/" + auth.currentUser.uid + "/coupons/");
        get(testRef).then((snapshot) => {
            setcouponsarray([]);
            snapshot.forEach((childSnapshot) => {
                const childData = childSnapshot.val();
                if(childData.used == false){
                    setcouponsarray(couponsarray => [...couponsarray, <CouponCompnent price={childData.price}
                    name={childData.name} date={childData.date} summary={childData.summary} min={childData.min} />]);
                }
            }
            );
        }).catch((error) => {
            
        });
    }
    
    const addcoupon = () => {
        const testRef = ref(db, "/coupon/" + couponcode);
        get(testRef).then((snapshot) => {
            
            if(snapshot.exists()){
                const childData = snapshot.val();
                const userRef = ref(db, "/users/" + auth.currentUser.uid + "/coupons/" + couponcode);
                get(userRef).then((snapshot2) => {
                    if(snapshot2.exists()){
                        setErrorMessage("이미 등록된 쿠폰입니다.");
                    } else{
                        set(userRef, childData);
                        setErrorMessage("쿠폰이 등록되었습니다.");
                        loadcoupons();
                    }
                });
            } else{
                setErrorMessage("존재하지 않는 쿠폰입니다.");
            }
        }).catch((error) => {
            
        });
    }

    return (
        <div className="Coupon-background">
            <div className="couponcode-div">
                <input className="couponcode-input" name="text" placeholder="쿠폰번호를 입력해주세요" 
                value={couponcode}
                onChange={e => setcouponcode(e.target.value)}/>
                <button className="couponcode-button" onClick={addcoupon}>쿠폰등록</button>
            </div>
            { errorMessage == "" ?
                null
                :
                <span className="message-div">{errorMessage}</span>
            }
            {couponsarray}
        </div>
    );
  }

export default CouponPage;
