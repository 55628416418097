import { useState } from "react";


function OrderComponent(props) {

    const [visible, setVisible] = useState(true);

    const inputvaluechange = (data) => {
        if(props.contents[0] == null && props.contents[1] == null){
            deleteorder();
        }
        props.contents[data.target.id] = data.target.value;
        
    }

    const deleteorder = () => {
        setVisible(false);
        props.contents[0] = null;
        props.contents[1] = null;
    }

    return (
    <tr>
        {
        visible ?
        <>
        <td>
            <input className="order-input-product" defaultValue={props.contents[0]} id={0}  onChange={e => inputvaluechange(e)}/>
        </td>
        <td>
            <input className="order-input-product" defaultValue={props.contents[1]} id={1} onChange={e => inputvaluechange(e)}/>
        </td>
        <td>
            <button className="order-button" onClick={() => deleteorder()}>x</button>
        </td>
        </>
        : null}
    </tr>
    );
  }

export default OrderComponent;
