import React from "react";

import { db } from "../firebase";
import { onValue, ref, get, set } from "firebase/database";

function Projects() {


  const starCountRef = ref(db, "/user");
  onValue(starCountRef, (snapshot) => {
    
  });


  const getdatas = () => {
        const testRef = ref(db, "/users");

        get(testRef).then((snapshot) => {
          
        }).catch((error) => {

        });
  }

  //firebase set data
  const setdatas = () => {
    //time now to string
    const time = new Date().getTime().toString();
    set(ref(db, 'products/' + time), {
      title: "1231236126",
      time: '2022-12-03',
      summary : 'plain text alignplain text alignplain text alignplain text alignplain text alignplain text alignplain text align'
    }).catch((error) => {
      
    });
  }

  return (
    <div>
    <button onClick={getdatas}>get</button>
    <button onClick={setdatas}>set</button>
    </div>
  );
}

export default Projects;
