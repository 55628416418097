import { useState, useEffect} from "react";
import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";

//약관 동의 bull state 회원가입 페이지
//header get auth info self

function SuccesPage() {
    const { state } = useLocation();

    const [direct, setDirection] = useState("/");
    
    useEffect(() => {
        if(state.direct){
            setDirection(state.direct);
        }
    }, []);


    return (
        <div className="Coupon-background" >
            <div className="Success-title">
                {state.title}
            </div>
            <div className="Success-text">
                {state.text}
            </div>
            <div className="Success-button-div">
                <Link to={direct}>
                <button className="Success-button">확인하기</button>
                </Link>
            </div>
        </div>
    );
  }

export default SuccesPage;
