import { useState } from "react";
import { Link } from 'react-router-dom';
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from 'react-router-dom';

import { auth } from "../firebase";


function LoginPage() {

    const navigate = useNavigate();
    //firebase auth
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const login = () => {
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in
            //nav to home
            
            navigate(`/`)
            // ...
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
        });
    }




    return (
        <div>
            <div className='About-Background'>
                <div className='body-div'>
                    <div className='contents-div'>
                            <div className='title'>
                                <div className='contents-text-title font18'>로그인</div>
                            </div>
                            <h2 className="login-title">이메일</h2>
                            <div className="login-div">
                                <input className="login-input" type="text" onChange={e => setEmail(e.target.value)}></input>
                            </div>
                            <h2 className="login-title">비밀번호</h2>
                            <div className="login-div">
                                <input className="login-input" type="password" onChange={e => setPassword(e.target.value)}></input>
                            </div>
                            <div className="login-div">
                                <button className="login-button button-margin" onClick={login}>로그인</button>
                            </div>
                            <div className="login-buttons-div">
                                <Link to="/find/account">
                                    <button className="login-side-button">아이디 / 비밀번호찾기</button>
                                </Link>
                                <Link to="/signup">
                                    <button className="login-side-button">회원가입</button>
                                </Link>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    );
  }

export default LoginPage;
