import { useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { db } from "../firebase";
import { ref, get, set, remove } from "firebase/database";
import { useNavigate, useLocation } from "react-router-dom";
import { auth } from "../firebase";


//약관 동의 bull state 회원가입 페이지
//header get auth info self

function PaymentPage() {
    const navigate = useNavigate();
    const pathname = useLocation().pathname;
    const location = useLocation();
    const params = new URLSearchParams(location.search);
  

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            get(ref(db, "/users/" + auth.currentUser.uid + "/bill/" + params.get('orderId'))).then((snapshot) => {
        
                
                if(snapshot.val().paymentkey == pathname.split('/')[2]){
                    set(ref(db, "/users/" + auth.currentUser.uid + "/bill/" + params.get('orderId') + "/status"), "결제완료").then(() => {
                        if(snapshot.val().couponkey != ""){
                            remove(ref(db, "/users/" + auth.currentUser.uid + "/coupons/" + snapshot.val().couponkey));
                            set(ref(db, "/users/" + auth.currentUser.uid + "/coupons/" + snapshot.val().couponkey + "/used"), true);
                        }
                        navigate("/success" , {state : { title : "결제완료" , text : "결제가 성공적으로 완료되었습니다."} });
                    }).catch((error) => {
                        
                    });
                }
            }).catch((error) => {
                console.log(error);
            });
        });
    }, []);

    return (
        <div className="Coupon-background">
            <div className='Billing-title'>
                <h1 className='contents-text-title font18'>정보</h1>
            </div>
            
        </div>
    );
  }

export default PaymentPage;
