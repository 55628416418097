import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { db } from "../firebase";
import { ref, get } from "firebase/database";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase";

import EditPage from "./EditPage";

import profile from '../img/profile.webp';


//약관 동의 bull state 회원가입 페이지
//header get auth info self

function Userpage() {
    const navigate = useNavigate();

    const [user, setUser] = useState(null);

    const [coupons, setCoupons] = useState(0);
    const [bill, setBill] = useState(0);

    const [showedit, setShowedit] = useState(false);

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            if (user) {
                setUser(user);

                get(ref(db, "/users/" + auth.currentUser.uid + "/coupons/")).then((snapshot) => {
                    setCoupons(0);
                    snapshot.forEach((childSnapshot) => {
                        if(!childSnapshot.val().used){
                            setCoupons(coupons => coupons + 1);
                        }
                    });
                });
                get(ref(db, "/users/" + auth.currentUser.uid + "/bill/")).then((snapshot) => {
                    setBill(0);
                    setBill(snapshot.size)
                });
            } else {
                setUser(null);
            }
        });
    }, []);

    const logout = () => {
        auth.signOut();
        navigate('/');
    }

    const show = () => {
        setShowedit(!showedit);
    }

    return (
        <div>
            <div className='About-Background'>
                <div className='body-div'>
                    <div className='user-contents-div'>
                            <div className="user-title-div-profile">
                                <img className="user-title-div-profile-icon" alt="profile" src={profile} />
                                <div className="user-title-div-profile-text">
                                    <div>
                                        {user?.email}
                                        <div onClick={logout} className="header-user-flex">
                                            로그아웃>
                                        </div>
                                    </div>
                                    <div className="user-title-div-profile-text-div">
                                        <Link to="/usage">
                                            <div className="user-flex1">
                                                주문내역 {bill}
                                            </div>
                                        </Link>
                                        <Link to="/coupon">
                                            <div className="user-flex1">
                                                쿠폰 {coupons}
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div onClick={show} className="Header-menu-div">
                            회원정보 변경
                            </div>
                            {showedit ? <EditPage /> : null}
                    </div>
                </div>
            </div>
        </div>
    );
  }

export default Userpage;
