import { useEffect, useState } from "react";
import { db } from "../firebase";
import { ref, get, set, remove } from "firebase/database";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { auth } from "../firebase";

import { Term3 } from "./Termpages";

import { loadTossPayments } from '@tosspayments/payment-sdk'
const clientKey = "test_ck_0Poxy1XQL8RKdZ1AxA937nO5Wmlg";


//약관 동의 bull state 회원가입 페이지
//header get auth info self

function BillingPage() {
    const navigate = useNavigate();
    const { state } = useLocation();

    if(state.type == null || state.price == null){
        navigate('/');
    } else{
        
    }

    const payment = ["카드결제", "토스페이", /*"카카오페이",*/ "무통장입금"];

    const cardcom = [
        "KB국민카드",
        "신한카드",
        "BC카드",
        "삼성카드",
        "현대카드",
        "NH농협카드",
        "롯데카드",
        "하나카드",
        "우리카드",
        "카카오뱅크",
        "기업",
        "새마을금고",
        "신협",
        "씨티카드",
        "Sh수협은행",
        "우리BC카드",
        "우체국예금보험",
        "저축은행중앙회",
        "케이뱅크",
        "토스뱅크",
        "KDB산업은행",
        "광주은행",
        "전북은행",
        "제주은행",]
        
        
    const cardcode = [
        "11","41","31","51","61","91","71","21","W1","15","3K","38","62","36","34","33","37","39","3A","24","30","46","35","42"
    ]

    const [diliverarray, setdiliverarray] = useState([]);
    const [diliverselected, setdiliverselected] = useState(0);

    const [paymentarray, setpaymentarray] = useState([]);
    const [paymentselected, setpaymentselected] = useState(0);
    const [cardselected, setcardselected] = useState(0);
    const [showcardarray, setshowcardarray] = useState(false);

    const [couponarray, setcouponarray] = useState([]);
    const [couponselected, setcouponselected] = useState("");

    const [showdiliverarray , setshowdiliverarray] = useState(false);
    const [showpaymentarray , setshowpaymentarray] = useState(false);
    const [showcouponarray , setshowcouponarray] = useState(false);
    const [showdiliver, setShowdiliver] = useState(false);
    const [showedit, setShowedit] = useState(false);
    const [showterm, setShowterm] = useState(false);

    const [selectedname, setselectedname] = useState("");
    const [selectedaddress, setselectedaddress] = useState("");

    const [selectedcoupon, setselectedcoupon] = useState("할인쿠폰 적용하기");
    const [selectedcouponprice, setselectedcouponprice] = useState(0);
    const [selectedcoupondate, setselectedcoupondate] = useState("");
    const [selectedcouponkey, setselectedcouponkey] = useState("");


    const [selectedKoreanName, setSelectedKoreanName] = useState("");
    const [selectedFirstName, setSelectedFirstName] = useState("");
    const [selectedLastName, setSelectedLastName] = useState("");
    const [selectedAddress, setSelectedAddress] = useState("");
    const [selectedAddress2, setSelectedAddress2] = useState("");
    const [selectedCode, setSelectedCode] = useState("");
    const [selecteddiliver, setSelecteddiliver] = useState("");
    const [selecteddiliveraddress, setSelecteddiliveraddress] = useState("새로운 배송지를 등록해주세요.");
    const [selecteddiliveraddress2, setSelecteddiliveraddress2] = useState("");
    const [selecteddiliverfirstname, setSelecteddiliverfirstname] = useState("");
    const [selecteddiliverlastname, setSelecteddiliverlastname] = useState("");
    const [selectedKey, setSelectedKey] = useState("");


    const [selectedpayment, setselectedpayment] = useState(0);

    const [request , setrequest] = useState("");
    const [product, setproduct] = useState("");

    const [termcheck, settermcheck] = useState(false);

    const [errmessage, seterrmessage] = useState("");

    useEffect(() => {
        if(state.type == "shipping"){
            console.log("shipping")
        }

        auth.onAuthStateChanged((user) => {
            if (user) {
                getdiliver();
                
                const couponRef = ref(db, "/users/" + auth.currentUser.uid + "/coupons/");
                get(couponRef).then((snapshot) => {
                    setcouponarray([]);
                    snapshot.forEach((childSnapshot, index) => {

                        const childData = childSnapshot.val();
                        
                        if(childData.used == false && childData.date > Date.now()){

                            setcouponarray(couponarray => [...couponarray, {"price": childData.price
                        , "name": childData.name, "key": childSnapshot.key, "date": new Date(childData.date).getFullYear()
                        + "-" + (new Date(childData.date).getMonth() + 1) + "-"  + new Date(childData.date).getDate()}]);

                        }
                        
                    }
                    );

                }).catch((error) => {
                    
                });
            } else {
                navigate('/login');   
            }
        });
    }, []);


    const diliverselect = (index) => {
        setSelecteddiliver(diliverarray[index].KoreanName);
        setSelecteddiliveraddress(diliverarray[index].Address);
        setSelecteddiliveraddress2(diliverarray[index].Address2);
        setSelecteddiliverfirstname(diliverarray[index].FirstName);
        setSelecteddiliverlastname(diliverarray[index].LastName);
        setSelectedAddress(diliverarray[index].Address);
        setSelectedAddress2(diliverarray[index].Address2);
        setSelectedCode(diliverarray[index].Code);
        setSelectedFirstName(diliverarray[index].FirstName);
        setSelectedLastName(diliverarray[index].LastName);
        setSelectedKoreanName(diliverarray[index].KoreanName);
        setSelectedKey(diliverarray[index].Key);
        setShowdiliver(false);
    }

    const showtermfunc = () => {
        setShowterm(!showterm);
    }

    const showpaayment = () => {
        setshowpaymentarray(!showpaymentarray);
    }

    const paymentselect = (index) => {
        setpaymentselected(index);
        setshowpaymentarray(false);
    }

    const cardselect = (index) => {
        setcardselected(index);
        setshowcardarray(false);
    }

    const showcard = () => {
        setshowcardarray(!showcardarray);
    }

    const showcoupon = () => {
        
        setshowcouponarray(!showcouponarray);
        
    }

    const couponselect = (index) => {
        setcouponselected(index);
        setselectedcoupon(couponarray[index].name);
        setselectedcouponprice(couponarray[index].price);
        setselectedcouponkey(couponarray[index].key);
        setselectedcoupondate(couponarray[index].date);
        setshowcouponarray(false);
    }

    const resetcoupon = () => {
        setcouponselected(null);
        setselectedcoupon("할인쿠폰 적용하기");
        setselectedcouponprice(0);
        setselectedcoupondate("");
        setshowcouponarray(false);
    }

    
    const show = () => {
        setShowdiliver(!showdiliver);
        setShowedit(false);
    }

    const edit = () => {
        setShowedit(!showedit);
        setShowdiliver(false);
    }

    const nodiliver = () => {
        setSelectedAddress("");
        setSelectedAddress2("");
        setSelectedCode("");
        setSelectedFirstName("");
        setSelectedLastName("");
        setSelectedKoreanName("");
        setSelecteddiliver("");
        setSelecteddiliveraddress("새로운 배송지를 등록해주세요.");
        setSelecteddiliveraddress2("");
        setSelecteddiliverfirstname("");
        setSelecteddiliverlastname("");
        setSelectedKey("");
        setShowdiliver(true);
    }

    const adddiliver = () => {
        edit();
        setSelectedAddress("");
        setSelectedAddress2("");
        setSelectedCode("");
        setSelectedFirstName("");
        setSelectedLastName("");
        setSelectedKoreanName("");
        setSelecteddiliver("");
        setSelectedKey("");
        setSelecteddiliveraddress("새로운 배송지를 등록해주세요.");
        setSelecteddiliveraddress2("");
        setSelecteddiliverfirstname("");
        setSelecteddiliverlastname("");
        setShowdiliver(false);
    }

    const editdiliver = () => {
        if(selectedKey){
            if(selectedAddress != "" && selectedAddress2 != "" && selectedCode != "" && selectedFirstName != "" && selectedLastName != "" && selectedKoreanName != ""){
                
                
                let a = {};
                a["KoreanName"] = selectedKoreanName;
                a["Address"] = selectedAddress;
                a["Address2"] = selectedAddress2;
                a["FirstName"] = selectedFirstName;
                a["LastName"] = selectedLastName;
                a["customcode"] = selectedCode;

                set(ref(db, "/users/" + auth.currentUser.uid + "/diliver/" + selectedKey), a);
                
                getdiliver();
                setShowdiliver(false);
                setShowedit(false);
                
            }
        }
        else{
            //new
            if(selectedAddress != "" && selectedAddress2 != "" && selectedCode != "" && selectedFirstName != "" && selectedLastName != "" && selectedKoreanName != ""){
               
                let a = {};
                a["KoreanName"] = selectedKoreanName;
                a["Address"] = selectedAddress;
                a["Address2"] = selectedAddress2;
                a["FirstName"] = selectedFirstName;
                a["LastName"] = selectedLastName;
                a["customcode"] = selectedCode;

                let b = Math.random().toString(30).substring(2, 15);

                

                if(get(ref(db, "/users/" + auth.currentUser.uid + "/diliver/" + b))){
                    set(ref(db, "/users/" + auth.currentUser.uid + "/diliver/" + b + "A"), a);
                } else{
                    set(ref(db, "/users/" + auth.currentUser.uid + "/diliver/" + b), a);
                }

                getdiliver();
                setShowdiliver(false);
                setShowedit(false);
                
            }
        }
    }
    
    const getdiliver = () => {
        setdiliverarray([]);
        const testRef = ref(db, "/users/" + auth.currentUser.uid);
        get(testRef).then((snapshot) => {
            const childData = snapshot.val();
            

            const diliverref = ref(db, "/users/" + auth.currentUser.uid + "/diliver/");
            get(diliverref).then((snapshot) => {
                let a = true;
                if(snapshot.val()[snapshot.val().default]){
                    a = false;
                    setSelecteddiliver(childData.diliver[childData.diliver.default].KoreanName);
                    setSelecteddiliveraddress(childData.diliver[childData.diliver.default].Address);
                    setSelecteddiliveraddress2(childData.diliver[childData.diliver.default].Address2);
                    setSelecteddiliverfirstname(childData.diliver[childData.diliver.default].FirstName);
                    setSelecteddiliverlastname(childData.diliver[childData.diliver.default].LastName);
                    setSelectedKoreanName(childData.diliver[childData.diliver.default].KoreanName);
                    setSelectedFirstName(childData.diliver[childData.diliver.default].FirstName);
                    setSelectedLastName(childData.diliver[childData.diliver.default].LastName);
                    setSelectedAddress(childData.diliver[childData.diliver.default].Address);
                    setSelectedAddress2(childData.diliver[childData.diliver.default].Address2);
                    setSelectedCode(childData.diliver[childData.diliver.default].customcode);
                    setSelectedKey(childData.diliver.default);
                }
                snapshot.forEach((childSnapshot) => {
                    if(childSnapshot.key != "default"){
                        const childData = childSnapshot.val();
                        setdiliverarray(diliverarray => [...diliverarray, 
                        {KoreanName: childData.KoreanName, Address: childData.Address, Address2: childData.Address2, 
                        Code: childData.customcode, FirstName: childData.FirstName, LastName: childData.LastName,
                        Key : childSnapshot.key}]);
                    }
                    if(a){
                        if(childSnapshot.val() != 0){
                            setSelecteddiliver(childSnapshot.val().KoreanName);
                            setSelecteddiliveraddress(childSnapshot.val().Address);
                            setSelecteddiliveraddress2(childSnapshot.val().Address2);
                            setSelecteddiliverfirstname(childSnapshot.val().FirstName);
                            setSelecteddiliverlastname(childSnapshot.val().LastName);
                            setSelectedKoreanName(childSnapshot.val().KoreanName);
                            setSelectedFirstName(childSnapshot.val().FirstName);
                            setSelectedLastName(childSnapshot.val().LastName);
                            setSelectedAddress(childSnapshot.val().Address);
                            setSelectedAddress2(childSnapshot.val().Address2);
                            setSelectedCode(childSnapshot.val().customcode);
                            setSelectedKey(childSnapshot.key);
                            a = false;
                        }
                    }
                });
            }).catch((error) => {
                
            });


        }).catch((error) => {
            
        });
    }
    
    const billing = (status, orderkey, paymentkey, coupon) => {
        
            let a = {}
            a["KoreanName"] = selectedKoreanName;
            a["FirstName"] = selectedFirstName;
            a["LastName"] = selectedLastName;
            a["Address"] = selectedAddress;
            a["Address2"] = selectedAddress2;
            a["customcode"] = selectedCode;
            a["payment"] = payment[paymentselected];
            a["request"] = request;
            if(selectedcoupon == "할인쿠폰 적용하기" || !coupon){
                a["coupon"] = "";
                a["couponprice"] = 0;
                a["couponkey"] = "";
            } else{
                a["coupon"] = selectedcoupon;
                a["couponprice"] = selectedcouponprice;
                a["couponkey"] = selectedcouponkey;
            }
            a["date"] = Date.now();
            a["status"] = status;
            a["type"] = state.type;
            a["price"] = state.price;
            a["dilivered"] = "입고대기중";
            a["paymentkey"] = paymentkey;
            a["product"] = product;
            
            if(state.orderlist){
                a["orderlist"] = state.orderlist;
            }
            if(state.shippinglist){
                a["order"] = state.shippinglist;
            }


            setbill(orderkey, a);
    }

    const setbill = (orderkey, data) => {
        get(ref(db, "/users/" + auth.currentUser.uid + "/bill/" + orderkey)).then((snapshot) => {
            const childData = snapshot.val();
            if(childData){
                let b = Math.random().toString(30).substring(2, 15);
                setbill(orderkey + b)
            } else{
                if((state.price - selectedcouponprice) > 0){
                    set(ref(db, "/users/" + auth.currentUser.uid + "/bill/" + orderkey), data);
                    testcrypt(orderkey, data.paymentkey, data);
                } else{
                    if(state.price <= 0){
                        set(ref(db, "/request/order/" + auth.currentUser.uid + "/" + orderkey), data);
                        set(ref(db, "/users/" + auth.currentUser.uid + "/bill/" + orderkey), data);
                        navigate("/success" , {state : { title : "주문완료" , text : "주문이 정상적으로 접수되었습니다."} });
                    }
                    else{
                        set(ref(db, "/request/" + auth.currentUser.uid + "/" + orderkey), data);
                        set(ref(db, "/users/" + auth.currentUser.uid + "/bill/" + orderkey), data);
                        navigate("/success" , {state : { title : "결제완료" , text : "주문이 정상적으로 접수되었습니다."} });
                    }
                }
            }
        }).catch((error) => {
            
        });
    }

    const billtest = () => {
        if(selectedKoreanName == "" || selectedFirstName == "" || selectedLastName == "" || selectedAddress == "" || selectedAddress2 == "" || selectedCode == ""){
            seterrmessage("배송 정보를 입력해주세요.");
        } else{
            if(!termcheck){
                seterrmessage("약관에 동의해주세요.");
            } else{
                if(state.type == "shipping"){
                    if(product != ""){
                        let a = Math.random().toString(30).substring(2, 15);
                        let b = Math.random().toString(30).substring(2, 15);
                        if(state.price > 0){
                            if((state.price - selectedcouponprice) > 0){
                                billing("결제대기중", a, b, true);
                            } else{
                                billing("결제완료", a, b, true);
                            }
                        } else{
                            billing("주문접수중", a, b, false);
                        }
                    } else{
                        seterrmessage("상품 URL을 입력해주세요.");
                    }
                }else{
                    let a = Math.random().toString(30).substring(2, 15);
                    let b = Math.random().toString(30).substring(2, 15);
                    if(state.price > 0){
                        if((state.price - selectedcouponprice) > 0){
                            billing("결제대기중", a, b, true);
                        } else{
                            billing("결제완료", a, b, true);
                        }
                    } else{
                        billing("주문접수중", a, b, false);
                    }
                }
            }
        }
    }

    const tossPayments = loadTossPayments(clientKey)

    const testcrypt = (orderkey ,paymentkey, data) => {
        let type = "";
        if(state.type == "shipping"){
            type = "배송대행";
        }
        if(state.type == "order"){
            type = "구매대행";
        }

        set(ref(db, "/request/" + auth.currentUser.uid + "/" + orderkey), data);
        set(ref(db, "/users/" + auth.currentUser.uid + "/bill/" + orderkey), data);
        if(selectedcouponkey != ""){
            set(ref(db, "/users/" + auth.currentUser.uid + "/coupons/" + selectedcouponkey + "/used"), true);
        }
        if(paymentselected == 2){
            loadTossPayments(clientKey).then(tossPayments => {
                tossPayments.requestPayment('가상계좌', { // 결제 수단 파라미터
                // 결제 정보 파라미터
                amount: state.price - selectedcouponprice, // 결제 금액
                orderId: orderkey,
                orderName: type + "결제",
                customerName: selectedKoreanName,
                successUrl: 'http://localhost:3000/payment/' + paymentkey,
                failUrl: 'http://localhost:3000/',
                flowMode: "DEFAULT",
              }).catch(function (error) {
                if (error.code === 'USER_CANCEL') {
                    remove(ref(db, "/request/" + auth.currentUser.uid + "/" + orderkey));
                    remove(ref(db, "/users/" + auth.currentUser.uid + "/bill/" + orderkey));
                    if(selectedcouponkey != ""){
                        set(ref(db, "/users/" + auth.currentUser.uid + "/coupons/" + selectedcouponkey + "/used"), true);
                    }
                } else if (error.code === 'INVALID_CARD_COMPANY') {
                    remove(ref(db, "/request/" + auth.currentUser.uid + "/" + orderkey));
                    remove(ref(db, "/users/" + auth.currentUser.uid + "/bill/" + orderkey));
                    if(selectedcouponkey != ""){
                        set(ref(db, "/users/" + auth.currentUser.uid + "/coupons/" + selectedcouponkey + "/used"), true);
                    }
                }
              })
              
              })
        } else if(paymentselected == 0){
            loadTossPayments(clientKey).then(tossPayments => {
            
                tossPayments.requestPayment("카드", { // 결제 수단 파라미터
                // 결제 정보 파라미터
                amount: state.price - selectedcouponprice, // 결제 금액
                orderId: orderkey,
                orderName: type + "결제",
                customerName: selectedKoreanName,
                successUrl: 'http://localhost:3000/payment/' + paymentkey,
                failUrl: 'http://localhost:3000/',
                flowMode: "DIRECT",
                cardCompany: cardcode[cardselected],
              }).catch(function (error) {
                if (error.code === 'USER_CANCEL') {
                    remove(ref(db, "/request/" + auth.currentUser.uid + "/" + orderkey));
                    remove(ref(db, "/users/" + auth.currentUser.uid + "/bill/" + orderkey));
                    if(selectedcouponkey != ""){
                        set(ref(db, "/users/" + auth.currentUser.uid + "/coupons/" + selectedcouponkey + "/used"), true);
                    }
                } else if (error.code === 'INVALID_CARD_COMPANY') {
                    remove(ref(db, "/request/" + auth.currentUser.uid + "/" + orderkey));
                    remove(ref(db, "/users/" + auth.currentUser.uid + "/bill/" + orderkey));
                    if(selectedcouponkey != ""){
                        set(ref(db, "/users/" + auth.currentUser.uid + "/coupons/" + selectedcouponkey + "/used"), true);
                    }
                }
              })
              
              })
        } else if(paymentselected == 1){
            loadTossPayments(clientKey).then(tossPayments => {
            
                tossPayments.requestPayment("간편결제", { // 결제 수단 파라미터
                // 결제 정보 파라미터
                amount: state.price - selectedcouponprice, // 결제 금액
                orderId: orderkey,
                orderName: type + "결제",
                customerName: selectedKoreanName,
                successUrl: 'http://localhost:3000/payment/' + paymentkey,
                failUrl: 'http://localhost:3000/',
                flowMode: "DIRECT",
                easyPay: "TOSSPAY",
              }).catch(function (error) {
                if (error.code === 'USER_CANCEL') {
                    remove(ref(db, "/request/" + auth.currentUser.uid + "/" + orderkey));
                    remove(ref(db, "/users/" + auth.currentUser.uid + "/bill/" + orderkey));
                    if(selectedcouponkey != ""){
                        set(ref(db, "/users/" + auth.currentUser.uid + "/coupons/" + selectedcouponkey + "/used"), true);
                    }
                } else if (error.code === 'INVALID_CARD_COMPANY') {
                    remove(ref(db, "/request/" + auth.currentUser.uid + "/" + orderkey));
                    remove(ref(db, "/users/" + auth.currentUser.uid + "/bill/" + orderkey));
                    if(selectedcouponkey != ""){
                        set(ref(db, "/users/" + auth.currentUser.uid + "/coupons/" + selectedcouponkey + "/used"), true);
                    }
                }
              })
              
              })
        } /*else if(paymentselected == 2){
            loadTossPayments(clientKey).then(tossPayments => {
            
                tossPayments.requestPayment("간편결제", { // 결제 수단 파라미터
                // 결제 정보 파라미터
                amount: state.price - selectedcouponprice, // 결제 금액
                orderId: orderkey,
                orderName: type + "결제",
                customerName: selectedKoreanName,
                successUrl: 'http://localhost:3000/payment/' + paymentkey,
                failUrl: 'http://localhost:3000/',
                flowMode: "DIRECT",
                easyPay: "KAKAOPAY",
              }).catch(function (error) {
                if (error.code === 'USER_CANCEL') {
                    remove(ref(db, "/users/" + auth.currentUser.uid + "/bill/" + orderkey));
                    if(selectedcouponkey != ""){
                        set(ref(db, "/users/" + auth.currentUser.uid + "/coupons/" + selectedcouponkey + "/used"), true);
                    }
                } else if (error.code === 'INVALID_CARD_COMPANY') {
                    remove(ref(db, "/users/" + auth.currentUser.uid + "/bill/" + orderkey));
                    if(selectedcouponkey != ""){
                        set(ref(db, "/users/" + auth.currentUser.uid + "/coupons/" + selectedcouponkey + "/used"), true);
                    }
                }
              })
              
              })
        }*/
          
          
    }

    const checkterm = () => {
        settermcheck(!termcheck);
    }

    const handleOpenNewTab = (url) => {
        if(url){
            window.open(url, "_blank", "noopener, noreferrer");
        }
    };

    return (
        <div className="Coupon-background">
            {state.type == "shipping" ?
            <div className="Billing-title">
                <h1 className='contents-text-title font18'>배송상품 URL 주소</h1>
                <textarea placeholder="필수 입력사항입니다." value={product} onChange={e => setproduct(e.target.value)} className="Billing-input" />
            </div> : null}
            <div className='Billing-title'>
                <h1 className='contents-text-title font18'>결제금액</h1>
                <div className='Bill2'>
                    {  (state.price - selectedcouponprice) > 0 ? state.price - selectedcouponprice : 0}원 { selectedcouponprice == 0 ? null : "(할인 " + selectedcouponprice + "원)"}
                </div>
            </div>
            <div className='Billing-title'>
                <h1 className='contents-text-title font18'>배송정보</h1>
                                <div className="Billing-div-user" onClick={show}>
                                    <div className="Billing-contents-div-text">
                                    <div className="Billing-contents-title">
                                        {selecteddiliver} {selecteddiliverfirstname} {selecteddiliverlastname}
                                    </div>
                                    <div className="Billing-contents-text">
                                        {selecteddiliveraddress} {selecteddiliveraddress2}
                                    </div>
                                    <div className="Billing-contents-date font20"></div>
                                    </div>
                                </div>
                                    {
                                        showdiliver ?
                                        <div className="Billing-card-div-user">
                                        {diliverarray.map((diliver, index) => (
                                            <div className="Billing-card"
                                            key={index} >
                                                <div className="Billing-contents-div-text-user"
                                                onClick={() => diliverselect(index)}>
                                                    <div className="Billing-contents-title">
                                                    {diliver.KoreanName} / {diliver.FirstName} {diliver.LastName}
                                                    </div>
                                                    <div className="Billing-contents-text">
                                                    {diliver.Address} {diliver.Address2}
                                                    </div>
                                                    <div className="Billing-contents-date font20">통관번호 {diliver.Code}</div>
                                                </div>
                                            </div>
                                        ))}
                                            <div className="Billing-card" onClick={adddiliver}>
                                                <div className="Billing-add">
                                                    배송정보 추가하기
                                                    <div className="font30">&#43;</div>
                                                </div>
                                            </div>
                                        </div>
                                    :
                                    null}
                                    {
                                        showedit ?
                                        <div className="Billing-card-div-user">
                                            <div className="Billing-card-user">
                                                <div className="Billing-contents-div-text">
                                                    <div className="Billing-title-user">이름</div>
                                                    <input className="Billing-input-user" type="text" placeholder="이름" value={selectedKoreanName} onChange={e => setSelectedKoreanName(e.target.value)}></input>
                                                </div>
                                                <div className="Billing-contents-div-text">
                                                    <div className="Billing-title-user">영문이름</div>
                                                    <input className="Billing-input-user-half" type="text" placeholder="First Name / 이름" value={selectedFirstName} onChange={e => setSelectedFirstName(e.target.value)}></input>
                                                    <input className="Billing-input-user-half" type="text" placeholder="Last Name / 성" value={selectedLastName} onChange={e => setSelectedLastName(e.target.value)}></input>
                                                </div>
                                                <div className="Billing-contents-div-text">
                                                    <div className="Billing-title-user">주소</div>
                                                    <input className="Billing-input-user" type="text" placeholder="주소" value={selectedAddress} onChange={e => setSelectedAddress(e.target.value)}></input>
                                                    <input className="Billing-input-user" type="text" placeholder="상세주소" value={selectedAddress2} onChange={e => setSelectedAddress2(e.target.value)}></input>
                                                </div>
                                                <div className="Billing-contents-div-text">
                                                    <div className="Billing-title-user">통관번호</div>
                                                    <input className="Billing-input-user" type="text" placeholder="통관번호" value={selectedCode} onChange={e => setSelectedCode(e.target.value)}></input>
                                                </div>
                                                <div className="Billing-button-div" onClick={editdiliver}>
                                                    <button className="Billing-button button-margin">정보등록</button>
                                                </div>
                                            </div>
                                        </div>
                                    : 
                                        null
                                    }
            </div>
            <div className='Billing-title'>
                <h1 className='contents-text-title font18'>결제정보</h1>

            <div className="Billing-payments-div" onClick={showpaayment}>
                <div className="Billing-contents-div-text">
                <div className="Billing-payments-title">
                    {payment[paymentselected]}
                </div>
                <div className="Billing-contents-text">
                </div>
                <div className="Billing-contents-date font20"></div>
                </div>
            </div>
            {
                showpaymentarray ?
                <div className="Billing-card-div">
                    {payment.map((payment, index) => (
                        <div className="Billing-payments-card"
                        key={index} 
                        onClick={() => paymentselect(index)}>
                            <div className="Billing-contents-div-text">
                                <div className="Billing-payments-title">
                                {payment}
                                </div>
                                <div className="Billing-contents-text">
                                </div>
                                <div className="Billing-contents-date font20"></div>
                            </div>
                        </div>
                    ))}
                </div>
                :
                null
            }
            {
                paymentselected == 0 ?
                <div>
                <div className="Billing-payments-div" onClick={showcard}>
                    <div className="Billing-contents-div-text">
                    <div className="Billing-payments-title">
                        {cardcom[cardselected]}
                    </div>
                    <div className="Billing-contents-text">
                    </div>
                    <div className="Billing-contents-date font20"></div>
                    </div>
                </div>
                {
                    showcardarray ?
                    <div className="Billing-card-div">
                        {cardcom.map((payment, index) => (
                            <div className="Billing-payments-card"
                            key={index} 
                            onClick={() => cardselect(index)}>
                                <div className="Billing-contents-div-text">
                                    <div className="Billing-payments-title">
                                    {payment}
                                    </div>
                                    <div className="Billing-contents-text">
                                    </div>
                                    <div className="Billing-contents-date font20"></div>
                                </div>
                            </div>
                        ))}
                    </div>
                    :
                    null
                }
                </div> : null
            }
            </div>

            
            <div className='Billing-title'>
                <h1 className='contents-text-title font18'>할인쿠폰</h1>
                <div className="Billing-div-user" onClick={showcoupon}>
                    <div className="Billing-contents-div-text">
                    <div className="Billing-contents-title">
                    {
                        selectedcouponprice == 0 ?
                        null : selectedcouponprice + "원" 
                    }
                    </div>
                    <div className="Billing-contents-text">
                        {selectedcoupon}
                    </div>
                    <div className="Billing-contents-date">
                        {selectedcoupondate}
                    </div>
                    </div>
                </div>
                {
                    showcouponarray ?
                    <div className="Billing-card-div">
                        {couponarray.map((coupon, index) => (
                            <div className="Billing-card"
                            key={index}
                            onClick={() => couponselect(index)}>
                                <div className="Billing-contents-div-text">
                                    <div className="Billing-contents-title">
                                    {coupon.price}원
                                    </div>
                                    <div className="Billing-contents-text">
                                    {coupon.name}
                                    </div>
                                    <div className="Billing-contents-date">
                                        {coupon.date}
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div className="Billing-div-add"
                        onClick={() => {resetcoupon(); setselectedcoupon("할인쿠폰 적용하기")} }>
                            <div className="Billing-contents-div-text">
                                <div className="Billing-contents-title">
                                    
                                </div>
                                <div className="Billing-contents-text">
                                    다음에 사용하기
                                </div>
                                <div className="Billing-contents-date font20"></div>
                            </div>
                        </div>
                    </div>
                    :
                    null
                }
            </div>
            
            <div className='Billing-title'>
                <h1 className='contents-text-title font18'>요청사항</h1>
            </div>
            <div className="Billing-title">
                <textarea value={request} onChange={e => setrequest(e.target.value)} className="Billing-input" />
            </div>
            <div className="Billing-title">
                <h1 className='contents-text-title font18'>배송대행지 안내</h1>
                <button  onClick={() => handleOpenNewTab("https://www.iporter.com/ko/guide/transport")} className="Billing-button button-margin">바로가기</button>
            </div>
            <div className="Billing-button-div">
                                <div className="Billing-term" onClick={checkterm}>
                                    <div className="signup-checkbox-input-div">
                                        <input className="signup-checkbox" type="checkbox" checked={termcheck} />
                                    </div>
                                    <span className="signup-checkbox-text"> 전자거래 약관에 동의합니다</span>
                                    <div className="signup-checkbox-term" onClick={showtermfunc}>내용보기</div>
                                    {
                                        showterm ?
                                        <div className="term">
                                            <Term3 />
                                        </div>
                                        : null
                                    }
                                </div>
                                    <span className="signup-checkbox-text">{errmessage}</span>
                <button onClick={billtest} className="Billing-button button-margin">결제하기</button>
            </div>
        </div>
    );
  }

export default BillingPage;
