import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './App.css'
import './css/About.css';
import './css/Billing.css';
import './css/Coupon.css';
import './css/Footer.css';
import './css/Header.css';
import './css/Login.css';
import './css/Order.css';
import './css/Shipping.css';
import './css/Trade.css';
import './css/User.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import Header from './Components/Header';
import EditPage from './Components/EditPage';
import Footer from './Components/Footer';
import { About } from './Components/Pages';
import Contact from './Components/ProductPage';
import OrderPage from './Components/OrderPage';
import BillingPage from './Components/BillingPage';
import BillComponent from './Components/BillingComponent';
import InfoPage from './Components/InfoPage';
import LoginPage from './Components/LoginPage';
import { FindAccount } from './Components/FindComponent';
import ShippingPage from './Components/ShippingPage';
import Signuppage from './Components/Signuppage';
import Userpage from './Components/UserPage';
import SuccesPage from './Components/SuccessPage';
import Pathpage from './Components/pathpage';
import UsagePage from './Components/UsagePage';
import CouponPage from './Components/CouponPage';
import SellerPage from './Components/SellerPage';
import PaymentPage from './Components/PaymentPage';
import { Term1, Term2, Term3, Term4, Term5 } from './Components/Termpages';
import { BrowserRouter, Routes, Route } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
  <BrowserRouter>
    <div style={{ overflow: 'hidden' }}>
      <Header/>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/edit" element={<EditPage />} />
        <Route path="/info" element={<InfoPage />} />
        <Route path="/about" element={<About />} />
        <Route path="/Term/1" element={<div className='headerpadding'><Term1 /></div>} />
        <Route path="/Term/2" element={<div className='headerpadding'><Term2 /></div>} />
        <Route path="/Term/3" element={<div className='headerpadding'><Term3 /></div>} />
        <Route path="/Term/4" element={<div className='full-page'><Term4 /></div>} />
        <Route path="/usage" element={<UsagePage />} />
        <Route path="/billing" element={<BillingPage />} />
        <Route path="/billing/*" element={<BillComponent />} />
        <Route path="/coupon" element={<CouponPage />} />
        <Route path="/coupon/*" element={<CouponPage />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/order" element={<OrderPage />} />
        <Route path="/payment/*" element={<PaymentPage />} />
        <Route path="/shipping" element={<ShippingPage />} />
        <Route path="/seller" element={<SellerPage />} />
        <Route path="/find/account" element={<FindAccount />} />
        <Route path="/success" element={<SuccesPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<Signuppage />} />
        <Route path="/user" element={<Userpage />} />
        <Route path="*" element={<Pathpage />} />
      </Routes>
      <Footer/>
    </div>
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
