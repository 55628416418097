import { useEffect, useState } from "react";
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";
import { db } from "../firebase";
import { ref, get, set } from "firebase/database";
import { useNavigate } from "react-router-dom";
import { RecaptchaVerifier, signInWithPhoneNumber, EmailAuthProvider, linkWithCredential } from "firebase/auth";
import DaumPostcode from 'react-daum-postcode';

import { Term1, Term2, Term3 } from "./Termpages";

//약관 동의 bull state 회원가입 페이지
//header get auth info self

function Signuppage() {

    const navigate = useNavigate();
    //firebase auth
    const auth = getAuth();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordcheck, setPasswordcheck] = useState("");
    const [KoreanName, setKoreanName] = useState("");
    const [FirstName, setFirstName] = useState("");
    const [LastName, setLastName] = useState("");
    const [Address, setAddress] = useState("");
    const [Address2, setAddress2] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [confirmationResult, setConfirmationResult] = useState(null);
    const [code, setCode] = useState("");

    const [Signup, setSignup] = useState(false);

    const [terms, setTerms] = useState(false);
    const [terms1, setTerms1] = useState(false);
    const [terms2, setTerms2] = useState(false);
    const [terms3, setTerms3] = useState(false);
    const [terms4, setTerms4] = useState(false);
    const [terms5, setTerms5] = useState(false);

    const [showterms1, setShowterms1] = useState(false);
    const [showterms2, setShowterms2] = useState(false);
    const [showterms3, setShowterms3] = useState(false);

    const [allterms, setAllterms] = useState(false);

    const [reacaptcha, setReacaptcha] = useState(true);

    const [post , setPost] = useState(false);

    const emailRegex = new RegExp(/^\S+@\S+\.\S+$/) 
    const passwordRegex = new RegExp(/(?=.*\d)(?=.*[a-z])(?=.*[!@#$%^+\-=]).{8,}/)
    const phoneNumberRegex = new RegExp(/^[0-9]{11}$/ )

    const [ErrMessage, setErrMessage] = useState("")


    const handleComplete = (data) => {
        let fullAddress = data.address;
        let extraAddress = ''; 

        if (data.addressType === 'R') {
            if (data.bname !== '') {
            extraAddress += data.bname;
            }
            if (data.buildingName !== '') {
            extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
            }
            fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
        }
        setAddress(fullAddress);
        setPost(false);
    }

    const login = () => {
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            //nav to home
            
            // ...
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
        });
    }

    //firebase email register
    const register = () => {
        createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            // ..
        });
    }
    
    const signup = () => {
        createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            const credential = userCredential.user;
            const userdata = {
                email: email,
                KoreanName: KoreanName,
                FirstName: FirstName,
                LastName: LastName,
                Address: Address,
                Address2: Address2,
                phoneNumber: phoneNumber,
            }
            set(ref(db, "/users/" + credential.uid + "/"), userdata).catch((error) => {
                
            });
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            // ..
        });
    }

    const link = () => {
        
        let credential = EmailAuthProvider.credential(email, password);
        
        let user = auth.currentUser;
        linkWithCredential(auth.currentUser, credential).then(usercred => {
            var user = usercred.user;
            
            }).catch(err => {
                
            });
            
    }

    const Signuprequest = () => {
        let requesting = true;
        if(password != passwordcheck){
            setErrMessage("비밀번호가 일치하지 않습니다.");
            requesting = false;
        }
        if(!emailRegex.test(email)){
            setErrMessage("이메일 형식이 올바르지 않습니다.");
            requesting = false;
        }
        if(!passwordRegex.test(password)){
            setErrMessage("비밀번호 형식이 올바르지 않습니다.");
            requesting = false;
        }
        if(!phoneNumberRegex.test(phoneNumber)){
            setErrMessage("전화번호 형식이 올바르지 않습니다.");
            requesting = false;
        }
        if(KoreanName == "" || FirstName == "" || LastName == "" || Address == "" || phoneNumber == ""){
            setErrMessage("모든 정보를 입력해주세요.");
            requesting = false;
        }
        if(requesting){
            setErrMessage("");
            handleSubmitPhoneNumber();
        }
    }
    
  const handleSubmitPhoneNumber = () => {
    const a = "+82" + phoneNumber.substring(1);
    
    setReacaptcha(true)
    const appVerifier = new RecaptchaVerifier('recaptcha-container', {
        size: "invisible", // this property is important otherwise the captcha will be displayed on the screen
        }, auth);
    signInWithPhoneNumber(auth, a, appVerifier).then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        // ...
        
        setSignup(true);
        setReacaptcha(false);
    }).catch((error) => {
        setReacaptcha(false);
        
    })
  };

    useEffect(() => {
        if(!reacaptcha){
            setReacaptcha(true)
        }
    }, [reacaptcha]);
  

  const handleSubmitCode = () => {
    let confirmationResult = window.confirmationResult;
    confirmationResult.confirm(code)
      .then(result => {
        const user = result.user;
        
        
        let b = Math.floor(Math.random() * 1000000);

        linkWithCredential(result.user, EmailAuthProvider.credential(email, password))
        .then((userCredential) => {
            const credential = userCredential.user;
            const userdata = {
                email: email,
                KoreanName: KoreanName,
                FirstName: FirstName,
                LastName: LastName,
                Address: Address,
                Address2: Address2,
                phoneNumber: phoneNumber,
                postcode: b,
            }
            set(ref(db, "/users/" + credential.uid + "/"), userdata).catch((error) => {
                
            });

            navigate('/');
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode);
            console.log(errorMessage);
            if(user){
                navigate("/success" , {state : { title : "로그인" , text : "이미 가입된 전화번호입니다.", direct: "/"} });
            }
        });
      })
      .catch(err => {
        
      });

  };

    const showTerms1 = () => {
        setShowterms1(!showterms1);
        setShowterms2(false);
        setShowterms3(false);
    }

    const showTerms2 = () => {
        setShowterms1(false);
        setShowterms2(!showterms2);
        setShowterms3(false);
    }

    const showTerms3 = () => {
        setShowterms1(false);
        setShowterms2(false);
        setShowterms3(!showterms3);
    }

    const checkallterms = () => {
        if(allterms){
            setAllterms(false);
            setTerms1(false);
            setTerms2(false);
            setTerms3(false);
            setTerms4(false);
            setTerms5(false);
        } else{
            setAllterms(true);
            setTerms1(true);
            setTerms2(true);
            setTerms3(true);
            setTerms4(true);
        }

    }

    const checkterms = () => {
        if(terms1 && terms2 && terms3){
            setTerms(true);
        }
    }

    return (
        <div>
            <div className='About-Background'>
                <div className='body-div'>
                    <div className='contents-div'>
                            <div className='title'>
                                <div className='contents-text-title font18'>회원가입</div>
                            </div>
                            {
                                reacaptcha ?
                                <div id="recaptcha-container"></div>
                                : null
                            }
                            { terms ?
                            <div>
                                <div className="login-title">이메일</div>
                                <div className="login-div">
                                    <input className="login-input" type="text" placeholder="이메일주소" onChange={e => setEmail(e.target.value)}></input>
                                </div>
                                <div className="login-title">비밀번호</div>
                                <div className="login-div">
                                    <input className="login-input" type="password" placeholder="영문,숫자,특수기호 포함 8자~30자"  onChange={e => setPassword(e.target.value)}></input>
                                </div>
                                <div className="login-title">비밀번호 확인</div>
                                <div className="login-div">
                                    <input className="login-input" type="password" placeholder="영문,숫자,특수기호 포함 8자~30자" onChange={e => setPasswordcheck(e.target.value)}></input>
                                </div>
                                <div className="login-title">한글명</div>
                                <div className="login-div">
                                    <input className="login-input" type="text" placeholder="홍길동" onChange={e => setKoreanName(e.target.value)}></input>
                                </div>
                                <div className="login-title">영문명</div>
                                <div className="login-div">
                                    <input className="login-input" type="text" placeholder="First Name / 이름" onChange={e => setFirstName(e.target.value)}></input>
                                    <input className="login-input" type="text" placeholder="Last Name / 성" onChange={e => setLastName(e.target.value)}></input>
                                </div>
                                <div className="login-title">주소</div>
                                <div className="login-div">
                                    <input className="login-input" type="text" placeholder="도로명주소" value={Address} onFocus={() => setPost(true)}/>
                                    {post ? <div> <DaumPostcode onComplete={handleComplete} height={700} /></div>  : null }
                                    <input className="login-input" type="text" placeholder="상세주소" onChange={e => setAddress2(e.target.value)} onClick={() => setPost(false)}></input>
                                </div>
                                <div className="login-title">휴대전화</div>
                                <div className="login-div flex">
                                    <input className="phone-input" type="tel" id="phonenumber" name="phonenumber" pattern="[0-9]{3}-[0-9]{4}-[0-9]{4}" placeholder="010-xxxx-xxxx" 
                                    value={phoneNumber}
                                    onChange={e => setPhoneNumber(e.target.value)}/>
                                </div>
                                {ErrMessage}
                                {
                                    Signup ?
                                    <div>
                                        <div className="login-div">
                                            <button className="login-button button-margin" onClick={handleSubmitPhoneNumber}>인증번호 다시받기</button>
                                        </div>
                                        <form className="login-div flex button-margin">
                                            <input className="code-input" type="number"  placeholder="인증번호"
                                            value={code}
                                            onChange={e => setCode(e.target.value)}
                                            />
                                        </form>
                                        <div className="login-div">
                                            <button className="login-button button-margin" onClick={handleSubmitCode}>회원가입</button>
                                        </div>
                                    </div>
                                    :
                                    <div className="login-div">
                                        <button className="login-button button-margin" onClick={Signuprequest}>인증번호 받기</button>
                                    </div>
                                }
                            </div>
                                :
                            <div>
                                <div className="signup-checkbox-div" onClick={() => setTerms1(!terms1)}>
                                    <div className="signup-checkbox-input-div">
                                        <input className="signup-checkbox" type="checkbox" checked={terms1} />
                                    </div>
                                    <div className="signup-checkbox-text">서비스 이용약관 동의</div>
                                    <div onClick={showTerms1} className="signup-checkbox-term">내용보기</div>
                                </div>
                                {
                                    showterms1 ?
                                    <div className="term">
                                        <Term1 />
                                    </div>
                                    : null
                                }
                                <div className="signup-checkbox-div" onClick={() => setTerms2(!terms2)}>
                                    <div className="signup-checkbox-input-div">
                                        <input className="signup-checkbox" type="checkbox" checked={terms2} />
                                    </div>
                                    <span className="signup-checkbox-text">개인정보수집 및 이용 동의</span>
                                    <div onClick={showTerms2} className="signup-checkbox-term">내용보기</div>
                                </div>
                                {
                                    showterms2 ?
                                    <div className="term">
                                        <Term2 />
                                    </div>
                                    : null
                                }
                                <div className="signup-checkbox-div" onClick={() => setTerms3(!terms3)}>
                                    <div className="signup-checkbox-input-div">
                                        <input className="signup-checkbox" type="checkbox" checked={terms3} />
                                    </div>
                                    <span className="signup-checkbox-text">전자거래 이용 약관</span>
                                    <div onClick={showTerms3} className="signup-checkbox-term">내용보기</div>
                                </div>
                                {
                                    showterms3 ?
                                    <div className="term">
                                        <Term3 />
                                    </div>
                                    : null
                                }
                                <div className="signup-checkbox-div" onClick={() => setTerms4(!terms4)}>
                                    <div className="signup-checkbox-input-div">
                                        <input className="signup-checkbox" type="checkbox" checked={terms4} />
                                    </div>
                                    <span className="signup-checkbox-text-2">14세 미만은 서비스 이용 및 회원가입이 제한됩니다</span>
                                </div>
                                <div className="signup-checkbox-div" onClick={() => setTerms5(!terms5)}>
                                    <div className="signup-checkbox-input-div">
                                        <input className="signup-checkbox" type="checkbox" checked={terms5}/>
                                    </div>
                                    <span className="signup-checkbox-text-2">[선택] 광고성 정보 수신 동의</span>
                                </div>
                                <div className="signup-checkbox-div checkbox-all" onClick={() => checkallterms()}>
                                    <div className="signup-checkbox-input-div">
                                        <input className="signup-checkbox" type="checkbox" checked={allterms} />
                                    </div>
                                    <span className="signup-checkbox-text-2">전체 약관에 동의합니다</span>
                                </div>
                                    <div className="login-div" onClick={checkterms}>
                                        <button className="login-button button-margin" >회원가입</button>
                                    </div>
                            </div>
                            }
                    </div>
                </div>
            </div>
        </div>
    );
  }

export default Signuppage;
