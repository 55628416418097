import { useEffect, useState } from "react";
import { EmailAuthProvider, reauthenticateWithCredential, updatePassword } from "firebase/auth";
import { db } from "../firebase";
import { ref, get, set, remove } from "firebase/database";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase";

import DaumPostcode from "react-daum-postcode";

//약관 동의 bull state 회원가입 페이지
//header get auth info self

function EditPage() {
    const navigate = useNavigate();

    const [user, setUser] = useState(null);
    const [email, setEmail] = useState("");
    const [passwordorigin, setPasswordorigin] = useState("");
    const [password, setPassword] = useState("");
    const [passwordcheck, setPasswordcheck] = useState("");
    const [koreanName, setKoreanName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [address, setAddress] = useState("");
    const [address2, setAddress2] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [diliverarray, setDiliverarray] = useState([]);

    const [selecteddiliver, setSelecteddiliver] = useState("");
    const [selecteddiliverlastname, setSelecteddiliverlastname] = useState("");
    const [selecteddiliverfirstname, setSelecteddiliverfirstname] = useState("");
    const [selecteddiliveraddress, setSelecteddiliveraddress] = useState("새로운 배송지를 등록해주세요.");
    const [selecteddiliveraddress2, setSelecteddiliveraddress2] = useState("");


    const [showdiliver, setShowdiliver] = useState(false);
    const [showedit, setShowedit] = useState(false);

    const [selectedKoreanName, setSelectedKoreanName] = useState("");
    const [selectedFirstName, setSelectedFirstName] = useState("");
    const [selectedLastName, setSelectedLastName] = useState("");
    const [selectedAddress, setSelectedAddress] = useState("");
    const [selectedAddress2, setSelectedAddress2] = useState("");
    const [selectedCode, setSelectedCode] = useState("");
    const [selectedKey, setSelectedKey] = useState("");

    const [errorMessage, setErrorMessage] = useState("");

    const [post, setPost] = useState(false);
    const [newpost, setNewpost] = useState(false);

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            if (user) {
                getdiliver();
            } else {
                navigate('/');
            }
        });
    }, []);

    const handleComplete = (data) => {
        let fullAddress = data.address;
        let extraAddress = ''; 

        if (data.addressType === 'R') {
            if (data.bname !== '') {
            extraAddress += data.bname;
            }
            if (data.buildingName !== '') {
            extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
            }
            fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
        }
        setAddress(fullAddress);
        setPost(false);
    }

    const newhandleComplete = (data) => {
        let fullAddress = data.address;
        let extraAddress = ''; 

        if (data.addressType === 'R') {
            if (data.bname !== '') {
            extraAddress += data.bname;
            }
            if (data.buildingName !== '') {
            extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
            }
            fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
        }
        setSelectedAddress(fullAddress);
        setNewpost(false);
    }

    const getdiliver = () => {
        setDiliverarray([]);
        const testRef = ref(db, "/users/" + auth.currentUser.uid);
        const diliverref = ref(db, "/users/" + auth.currentUser.uid + "/diliver");
        get(testRef).then((snapshot) => {
            const childData = snapshot.val();
            try{
                setEmail(childData.email);
                setKoreanName(childData.KoreanName);
                setFirstName(childData.FirstName);
                setLastName(childData.LastName);
                setAddress(childData.Address);
                setAddress2(childData.Address2);
                setPhoneNumber(childData.phoneNumber.slice(0, 3) + "-" + childData.phoneNumber.slice(3,7) + "-" + childData.phoneNumber.slice(7,11));
            } catch(e){
            
            }
            
            

            get(diliverref).then((snapshot) => {
                let a = true;
                if(snapshot.val()[snapshot.val().default]){
                    a = false;
                    setSelecteddiliver(childData.diliver[childData.diliver.default].KoreanName);
                    setSelecteddiliveraddress(childData.diliver[childData.diliver.default].Address);
                    setSelecteddiliveraddress2(childData.diliver[childData.diliver.default].Address2);
                    setSelecteddiliverfirstname(childData.diliver[childData.diliver.default].FirstName);
                    setSelecteddiliverlastname(childData.diliver[childData.diliver.default].LastName);
                    setSelectedKoreanName(childData.diliver[childData.diliver.default].KoreanName);
                    setSelectedFirstName(childData.diliver[childData.diliver.default].FirstName);
                    setSelectedLastName(childData.diliver[childData.diliver.default].LastName);
                    setSelectedAddress(childData.diliver[childData.diliver.default].Address);
                    setSelectedAddress2(childData.diliver[childData.diliver.default].Address2);
                    setSelectedCode(childData.diliver[childData.diliver.default].customcode);
                    setSelectedKey(childData.diliver.default);
                }
                snapshot.forEach((childSnapshot) => {
                    if(childSnapshot.key != "default"){
                        const childData = childSnapshot.val();
                        setDiliverarray(diliverarray => [...diliverarray, 
                        {KoreanName: childData.KoreanName, Address: childData.Address, Address2: childData.Address2, 
                        Code: childData.customcode, FirstName: childData.FirstName, LastName: childData.LastName,
                        Key : childSnapshot.key}]);
                    }
                    if(a){
                        if(childSnapshot.val() != 0){
                            setSelecteddiliver(childSnapshot.val().KoreanName);
                            setSelecteddiliveraddress(childSnapshot.val().Address);
                            setSelecteddiliveraddress2(childSnapshot.val().Address2);
                            setSelecteddiliverfirstname(childSnapshot.val().FirstName);
                            setSelecteddiliverlastname(childSnapshot.val().LastName);
                            setSelectedKoreanName(childSnapshot.val().KoreanName);
                            setSelectedFirstName(childSnapshot.val().FirstName);
                            setSelectedLastName(childSnapshot.val().LastName);
                            setSelectedAddress(childSnapshot.val().Address);
                            setSelectedAddress2(childSnapshot.val().Address2);
                            setSelectedCode(childSnapshot.val().customcode);
                            setSelectedKey(childSnapshot.key);
                            a = false;
                        }
                    }
                });
            }).catch((error) => {
                
            });


        }).catch((error) => {
            
        });
    }

    const changepassword = () => {
        if(passwordorigin == "" || password == "" || passwordcheck == ""){
            
        }
        else{
            if(password == passwordcheck){
                const cred = EmailAuthProvider.credential(auth.currentUser.email, passwordorigin);
                reauthenticateWithCredential(auth.currentUser, cred).then(() => {
                    updatePassword(auth.currentUser, password).then(() => {
                        
                    }).catch((error) => {
                        
                    });
                }).catch((error) => {
                    
                });
            }
            else{
            }
        }
    }

    const show = () => {
        setShowdiliver(!showdiliver);
        setShowedit(false);
    }

    const edit = () => {
        
        setShowedit(!showedit);
        setShowdiliver(false);
    }

    const nodiliver = () => {
        setSelectedAddress("");
        setSelectedAddress2("");
        setSelectedCode("");
        setSelectedFirstName("");
        setSelectedLastName("");
        setSelectedKoreanName("");
        setSelecteddiliver("");
        setSelecteddiliveraddress("새로운 배송지를 등록해주세요.");
        setSelecteddiliveraddress2("");
        setSelecteddiliverfirstname("");
        setSelecteddiliverlastname("");
        setSelectedKey("");
        setShowdiliver(true);
    }

    const adddiliver = () => {
        edit();
        setSelectedAddress("");
        setSelectedAddress2("");
        setSelectedCode("");
        setSelectedFirstName("");
        setSelectedLastName("");
        setSelectedKoreanName("");
        setSelecteddiliver("");
        setSelectedKey("");
        setSelecteddiliveraddress("새로운 배송지를 등록해주세요.");
        setSelecteddiliveraddress2("");
        setSelecteddiliverfirstname("");
        setSelecteddiliverlastname("");
        setShowdiliver(false);
    }

    const editdiliver = () => {
        if(selectedKey){
            if(selectedAddress != "" && selectedAddress2 != "" && selectedCode != "" && selectedFirstName != "" && selectedLastName != "" && selectedKoreanName != ""){
                
                
                let a = {};
                a["KoreanName"] = selectedKoreanName;
                a["Address"] = selectedAddress;
                a["Address2"] = selectedAddress2;
                a["FirstName"] = selectedFirstName;
                a["LastName"] = selectedLastName;
                a["customcode"] = selectedCode;

                set(ref(db, "/users/" + auth.currentUser.uid + "/diliver/" + selectedKey), a);
                
                getdiliver();
                setShowdiliver(false);
                setShowedit(false);
                
            }
        }
        else{
            //new
            if(selectedAddress != "" && selectedAddress2 != "" && selectedCode != "" && selectedFirstName != "" && selectedLastName != "" && selectedKoreanName != ""){
               
                let a = {};
                a["KoreanName"] = selectedKoreanName;
                a["Address"] = selectedAddress;
                a["Address2"] = selectedAddress2;
                a["FirstName"] = selectedFirstName;
                a["LastName"] = selectedLastName;
                a["customcode"] = selectedCode;

                let b = Math.random().toString(30).substring(2, 15);

                

                if(get(ref(db, "/users/" + auth.currentUser.uid + "/diliver/" + b))){
                    set(ref(db, "/users/" + auth.currentUser.uid + "/diliver/" + b + "A"), a);
                } else{
                    set(ref(db, "/users/" + auth.currentUser.uid + "/diliver/" + b), a);
                }

                getdiliver();
                setShowdiliver(true);
                setShowedit(false);
                
            }
        }
    }
    
    const removediliver = (a, index) => {
        
        diliverarray.splice(index, 1);
        set(ref(db, "/users/" + auth.currentUser.uid + "/diliver/default"), "0");
        remove(ref(db, "/users/" + auth.currentUser.uid + "/diliver/" + a));
        edit();
        getdiliver();
        setSelecteddiliver("");
        setSelecteddiliveraddress("새로운 배송지를 등록해주세요.");
        setSelecteddiliveraddress2("");
        setSelecteddiliverfirstname("");
        setSelecteddiliverlastname("");
        setShowdiliver(false);
        setShowedit(false);
    }

    const diliverselect = (index) => {
        setSelecteddiliver(diliverarray[index].KoreanName);
        setSelecteddiliveraddress(diliverarray[index].Address);
        setSelecteddiliveraddress2(diliverarray[index].Address2);
        setSelecteddiliverfirstname(diliverarray[index].FirstName);
        setSelecteddiliverlastname(diliverarray[index].LastName);
        setSelectedAddress(diliverarray[index].Address);
        setSelectedAddress2(diliverarray[index].Address2);
        setSelectedCode(diliverarray[index].Code);
        setSelectedFirstName(diliverarray[index].FirstName);
        setSelectedLastName(diliverarray[index].LastName);
        setSelectedKoreanName(diliverarray[index].KoreanName);
        setSelectedKey(diliverarray[index].Key);
        set(ref(db, "/users/" + auth.currentUser.uid + "/diliver/default"), diliverarray[index].Key);
        setShowdiliver(false);
    }

    const edituser = () => {
                set(ref(db, "/users/" + auth.currentUser.uid + "/FirstName/"), firstName);
                set(ref(db, "/users/" + auth.currentUser.uid + "/LastName/"), lastName);
                set(ref(db, "/users/" + auth.currentUser.uid + "/Address/"), address);
                set(ref(db, "/users/" + auth.currentUser.uid + "/Address2/"), address2);
                setErrorMessage("정상적으로 수정되었습니다.");
    }

    return (
        <div className='About-Background'>
            <div className=''>
                <div className='contents-div'>
                        <div className='title'>
                            <h1 className='contents-text-title font18'>회원정보 변경</h1>
                        </div>   
                            <div>
                                <div className="login-title">이름</div>
                                <div className="login-div flex">
                                    <div className="login-info-div">{koreanName}</div>
                                </div>
                                <div className="login-title">이메일</div>
                                <div className="login-div">
                                    <div className="login-info-div">{email}</div>
                                </div>
                                <div className="login-title">휴대전화</div>
                                <div className="login-div flex">
                                    <div className="login-info-div">{phoneNumber}</div>
                                </div>
                                <div className="login-title"> 비밀번호</div>
                                <div className="login-div">
                                    <input className="login-input" type="password" placeholder="영문,숫자,특수기호 포함 8자~30자" 
                                    value={passwordorigin}  onChange={e => setPasswordorigin(e.target.value)}></input>
                                </div>
                                <div className="login-title">새 비밀번호</div>
                                <div className="login-div">
                                    <input className="login-input" type="password" placeholder="영문,숫자,특수기호 포함 8자~30자" 
                                    value={password} onChange={e => setPassword(e.target.value)}></input>
                                </div>
                                <div className="login-title">새 비밀번호 확인</div>
                                <div className="login-div">
                                    <input className="login-input" type="password" placeholder="영문,숫자,특수기호 포함 8자~30자" 
                                    value={passwordcheck} onChange={e => setPasswordcheck(e.target.value)}></input>
                                </div>
                                <div className="login-div">
                                    <button onClick={changepassword} className="login-button margin-2">비밀번호 변경</button>
                                </div>
                                <div className="login-title">영문명</div>
                                <div className="login-div">
                                    <input className="login-input" type="text" placeholder="First Name / 이름" value={firstName} onChange={e => setFirstName(e.target.value)}></input>
                                    <input className="login-input" type="text" placeholder="Last Name / 성" value={lastName} onChange={e => setLastName(e.target.value)}></input>
                                </div>
                                <div className="login-title">주소</div>
                                <div className="login-div">
                                    <input className="login-input" type="text" placeholder="도로명주소" value={address} onFocus={() => setPost(true)}></input>
                                    {post ? <div> <DaumPostcode onComplete={handleComplete} height={700} /></div>  : null }
                                    <input className="login-input" type="text" placeholder="상세주소" value={address2} onChange={e => setAddress2(e.target.value)} onFocus={() => setPost(false)}></input>
                                </div>
                                <div className="login-title">배송주소</div>
                                <div className="width-98">
                                        <div className="Billing-div-user" onClick={show}>
                                            <div className="Billing-contents-div-text">
                                            <div className="Billing-contents-title">
                                                {selecteddiliver} {selecteddiliverfirstname} {selecteddiliverlastname}
                                            </div>
                                            <div className="Billing-contents-text">
                                                {selecteddiliveraddress} {selecteddiliveraddress2}
                                            </div>
                                            <div className="Billing-contents-date font20"></div>
                                            </div>
                                        </div>
                                    {
                                        showdiliver ?
                                        <div className="Billing-card-div-user">
                                        {diliverarray.map((diliver, index) => (
                                            <div className="Billing-card"
                                            key={index} >
                                                <div className="Billing-contents-div-text-user"
                                                onClick={() => diliverselect(index)}>
                                                    <div className="Billing-contents-title">
                                                    {diliver.KoreanName} / {diliver.FirstName} {diliver.LastName}
                                                    </div>
                                                    <div className="Billing-contents-text">
                                                    {diliver.Address} {diliver.Address2}
                                                    </div>
                                                    <div className="Billing-contents-date font20">통관번호 {diliver.Code}</div>
                                                </div>
                                                <div className="Billing-contents-delete-user" onClick={() => removediliver(diliver.Key, index)}>
                                                    <div>x</div>
                                                </div>
                                            </div>
                                        ))}
                                        <div className="Billing-card" onClick={adddiliver}>
                                            <div className="Billing-add">
                                                배송정보 추가하기
                                                <div className="font30">&#43;</div>
                                            </div>
                                        </div>
                                        </div>
                                    :
                                    null}
                                    {
                                        showedit ?
                                        <div className="Billing-card-div-user">
                                            <div className="Billing-card-user">
                                                <div className="Billing-contents-div-text">
                                                    <div className="Billing-title-user">이름</div>
                                                    <input className="Billing-input-user" type="text" placeholder="이름" value={selectedKoreanName} onChange={e => setSelectedKoreanName(e.target.value)}></input>
                                                </div>
                                                <div className="Billing-contents-div-text">
                                                    <div className="Billing-title-user">영문이름</div>
                                                    <input className="Billing-input-user-half" type="text" placeholder="First Name / 이름" value={selectedFirstName} onChange={e => setSelectedFirstName(e.target.value)}></input>
                                                    <input className="Billing-input-user-half" type="text" placeholder="Last Name / 성" value={selectedLastName} onChange={e => setSelectedLastName(e.target.value)}></input>
                                                </div>
                                                <div className="Billing-contents-div-text">
                                                    <div className="Billing-title-user">주소</div>
                                                    <input className="Billing-input-user" type="text" placeholder="주소" value={selectedAddress} onFocus={() => setNewpost(true)}></input>
                                                    {newpost ? <div> <DaumPostcode onComplete={newhandleComplete} height={700} /></div>  : null }
                                                    <input className="Billing-input-user" type="text" placeholder="상세주소" value={selectedAddress2} onChange={e => setSelectedAddress2(e.target.value)} onFocus={() => setNewpost(false)}></input>
                                                </div>
                                                <div className="Billing-contents-div-text">
                                                    <div className="Billing-title-user">통관번호</div>
                                                    <input className="Billing-input-user" type="text" placeholder="통관번호" value={selectedCode} onChange={e => setSelectedCode(e.target.value)} ></input>
                                                </div>
                                                <div className="Billing-button-div" onClick={editdiliver}>
                                                    <button className="Billing-button button-margin">정보등록</button>
                                                </div>
                                            </div>
                                        </div>
                                    : 
                                        !showdiliver && (selecteddiliver != "") ? 
                                        <div className="Billing-card-div-user">
                                            <div className="Billing-button-div" onClick={edit}>
                                                <button className="login-button">배송지 수정하기</button>
                                            </div>
                                        </div>
                                        : null
                                    }
                                </div>
                            </div>
                    <div className="login-div button-margin">{errorMessage}</div>
                    <div className="Billing-button-div" onClick={edituser}>
                        <button className="Billing-button button-margin">회원정보수정</button>
                    </div>
                </div>
            </div>
        </div>
    );
  }

export default EditPage;
