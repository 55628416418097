import { useState, useEffect } from "react";

function ShippingComponent(props) {

  const [expired, setExpired] = useState(false);

  

    const year = new Date(props.date).getFullYear();
    const month = new Date(props.date).getMonth() + 1;
    const day = new Date(props.date).getDate();

    useEffect(() => {
      if(new Date(props.date) < new Date()){
        setExpired(true);
      }
    }, []);

    return ( 
      <div className="Coupon-div">
        <div className="Coupon-contents-div-text">
          <div className="Coupon-contents-title font20">{props.price}원
          {
            expired ?
              <span className="Coupon-contents-expired">사용불가</span>
            :
            null

          }</div>
          <div className="Coupon-contents-text font20">{props.name}</div>
          <div className="Coupon-contents-date font20">~{year + "-" + month + "-" + day}</div>
        </div>
      </div>
    );
  }

export default ShippingComponent;
