import Crud from './curd.js';
import React, { useEffect, useState } from "react";

import { db } from "../firebase";
import { onValue, ref, get, set } from "firebase/database";


import ProductComponent from "./ProductComponent";

function Contact() {

    const [productsarray, setproductsarray] = useState([]);
    const testRef = ref(db, "/products/");
    
    const getdatas = () => {

        get(testRef).then((snapshot) => {
            setproductsarray([]);
            snapshot.forEach((childSnapshot) => {
                const childData = childSnapshot.val();
                setproductsarray(productsarray => [productsarray,<ProductComponent contents={childData}></ProductComponent>]);
            });
        }).catch((error) => {
  
        });

    }

    useEffect(() => {
        
        onValue(testRef, (snapshot) => {
            setproductsarray([]);
            snapshot.forEach((childSnapshot) => {
                const childData = childSnapshot.val();
                setproductsarray(productsarray => [productsarray,<ProductComponent contents={childData}></ProductComponent>]);
            });
        })

    }, []);

    return (
      <div>
        <div className='About-Background'>
          <div className='body-contents'>
            <div className='contents-div'>
                <Crud> </Crud>
                <div className='trade-contents-div-text'>
                    <button onClick={getdatas}>get</button>
                    <h2 className='contents-text font20'>매입주문</h2>
                </div>
                <div className='trade-div'>
                    <ul className='trade-ul'>
                        {productsarray}
                    </ul>
                </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

export default Contact;
