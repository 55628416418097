
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

//약관 동의 bull state 회원가입 페이지
//header get auth info self

function Pathpage() {
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/');
    }, []);

    return (
        <div>
            
        </div>
    );
  }

export default Pathpage;
