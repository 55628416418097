import React from "react";
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from '../img/logo-white.webp';

function Footer(){

  const [setisMobile, setIsmobile] = useState(false);
  
  useEffect(() => {
      
    const position = window.innerWidth;
    if (position < 1240) {
      setIsmobile(true);
    } else {
      setIsmobile(false);
    }
  }, []);
  
  const handlesize = () => {
    const position = window.innerWidth;
    if (position < 1240) {
      setIsmobile(true);
    } else {
      setIsmobile(false);
    }
  };


  useEffect(() => {
    window.addEventListener('resize', handlesize, { passive: true });
    
    return () => {
        window.removeEventListener('resize', handlesize);
    };
}, []);

    return (
        <div className='footer'>
          {setisMobile ?
          <div className='footer-main'>
            <div className='footer-mobile-contents'>
              <div className='footer-service-link-div'>
                <Link to="/term/1" className='footer-mobile-service-link'>서비스 이용약관</Link>
                <Link to="/term/2" className='footer-mobile-service-link'>개인정보 수집 및 이용약관</Link>
                <Link to="/term/3" className='footer-mobile-service-link'>전자거래 약관</Link>
                <Link to="/term/4" className='footer-mobile-service-link'>환불안내</Link>
                <Link className='footer-mobile-service-link'>공지사항</Link>
                <Link className='footer-mobile-service-link'>사업문의</Link>
              </div>

              <div className='footer-mobile-companyinfo'>
                <div className='footer-companyinfo-line'>
                <span className="footer-text">(주)한국외상결제 | </span>
                <span className="footer-text">사업자 등록번호 674-88-02597 | </span>
                <span className="footer-text">전화번호 070-8657-2258 | </span>
                <span className="footer-text">통신판매업신고 2023-부산해운대-1000 | </span>
                <span className="footer-text">대표이사 임견우 </span>
                </div>
                <div className='footer-companyinfo-line'>
                부산광역시 해운대구 센텀중앙로 97, 센텀스카이비즈 A동 1607, 58058
                </div>
                <div className='footer-companyinfo-line'>
                  Copyright © Korea Credit Payments. All rights reserved.
                </div>
              </div>
            </div>
          </div>
          :
          <div className='footer-main'>
            <div className='footer-logo'>
              <img alt="company-logo" className='footer-logo-image' src={logo}></img>
            </div>
            <div className='footer-contents'>
              <div className='footer-service-link-div'>
                <Link to="/term/1" className='footer-service-link'>서비스 이용약관</Link>
                <Link to="/term/2" className='footer-service-link'>개인정보 이용약관</Link>
                <Link to="/term/3" className='footer-service-link'>전자거래 약관</Link>
                <Link to="/term/4" className='footer-service-link'>환불안내</Link>
                <Link className='footer-service-link'>공지사항</Link>
                <Link className='footer-service-link'>사업문의</Link>
              </div>

              <div className='footer-companyinfo'>
                <div className='footer-companyinfo-line'>
                <span>(주)한국외상결제 | </span>
                <span>사업자 등록번호 674-88-02597 | </span>
                <span>전화번호 070-8657-2258 | </span>
                <span>통신판매업신고 2023-부산해운대-1000 | </span>
                <span>대표이사 임견우 </span>
                </div>
                <div className='footer-companyinfo-line'>
                부산광역시 해운대구 센텀중앙로 97, 센텀스카이비즈 A동 1607, 58058
                </div>
                <div className='footer-companyinfo-line'>
                  Copyright © Korea Credit Payments. All rights reserved.
                </div>
              </div>
            </div>
          </div>
          }
        </div>
    );
 }

 export default Footer;
