

//약관 동의 bull state 회원가입 페이지
//header get auth info self

function InfoPage() {

    return (
        <div className="Coupon-background">
            <div className='Billing-title'>
                <h1 className='contents-text-title font18'>정보</h1>
            </div>
            
        </div>
    );
  }

export default InfoPage;
