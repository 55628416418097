import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import OrderComponent from "./OrderComponent";
import { db } from "../firebase";
import { ref, set } from "firebase/database";



function OrderPage() {
    const navigate = useNavigate();

    const [order, setOrder] = useState([]);
    
    const [data, setData] = useState([
        {
        id: "url",
        value: ""
        },
        {
        id: "quantity",
        value: 0
        }
    ]);
    const [product, setProduct] = useState("");
    const [quantity, setQuantity] = useState("");

    //console log when product or quantity changed
    useEffect(() => {
        
    }, [product, quantity]);



    //make array split with '""' and push to orderlist from order
    const getorderlist = (data) =>{
        if(data != ""){

            var list = data.split(/\s+/);
            

            
            for(var i = 0; i < list.length; i++){
                const contentsdata = [];
                if(i % 2 == 0){
                    contentsdata.push(list[i]);
                    
                    try{
                        for(var j = 1; j < 2; j++){
                            contentsdata.push(list[i+j]);
                            
                        }
                    }catch(e){
                        
                    }

                    //if when 7, push to orderlist
                    setOrder(order => [...order, <OrderComponent contents={contentsdata}></OrderComponent>]);
                }
            
            }
        }
        
    }

    const logorder = () =>{
        
    }

    const onchangeInput = (val, index) =>{
        let temp = data
        temp[index].value = val.target.value
        setData(temp)
      }


    //when product and quantity changed, push to data
    useEffect(() => {
        if(product == "" || quantity == 0){
            return;
        }
        else{
            let temp = ["", ""];
            temp[0] = product;
            temp[1] = quantity;
            setOrder(order => [...order, <OrderComponent contents={temp}></OrderComponent>]);
            setProduct("");
            setQuantity(0);
        }
    }, [product, quantity]);

    const logouttest = () => {
        
    }

    const orderbill = () => {
        let a = [];

        order.map((data, index) => {
            a.push(data.props.contents);
        })

        console.log(a.length)
        if(a.length > 0){
            navigate('/billing', {state: {type: "order", price : 0, orderlist: a}});
        }
    }
    
  const setdatas = (keydata, valuedata) => {
    //time now to string
    
    const a = "중국";
    const b = "웨이하이";
    const c = "항공";
    const d = "아무21";

    const currency = "krw";
    const weight = "kg";

    set(ref(db, "/cities/" + a + "/" + b + "/" + c + "/" + d + "/price/" + keydata), valuedata).catch((error) => {
    
        
    });
    
    set(ref(db, "/cities/" + a + "/" + b + "/" + c + "/" + d + "/" + "currency"), currency).catch((error) => {
   
        
    });
    set(ref(db, "/cities/" + a + "/" + b + "/" + c + "/" + d + "/" + "weight"), weight).catch((error) => {
     
        
    });
    
    
  }

    const updatedata = () => {
        order.map((data, index) => {
            if(data.props.contents[0] == null || data.props.contents[1] == null){
                
            }
            else{
                let a = (Number(data.props.contents[0]) * 2);
                setdatas(a, data.props.contents[1]);
            }
        })
    }



    return (
        <div>
            <div className='About-Background'>
                <div className='body-div'>
                    <div className='contents-div'>
                            <div className='title'>
                                <h1 className='contents-text-title font18'>구매대행 주문</h1>
                            </div>
                        <input onChange={e => getorderlist(e.target.value)} value={""} className='order-input' placeholder="물품목록을 붙여넣기로 입력해주세요"></input>
                        <table className="order-table">
                            <tr>
                                <th className="order-th1">주문상품</th>
                                <th className="order-th2">주문수량</th>
                                <th className="order-th3"></th>
                            </tr>
                            { order == [] ?
                                <tr>
                                    <td>상품의 URL 주소</td>
                                    <td>1</td>
                                </tr>
                            : null}
                            {order}
                            <tr>
                                <td>
                                    <input onChange={e => setProduct(e.target.value) } value={product} className='order-input-product' placeholder="상품의 URL 주소"></input>
                                </td>
                                <td>
                                    <input onChange={e => setQuantity(e.target.value)} value={quantity} className='order-input-product' type="number" placeholder="주문수량"></input>
                                </td>
                            </tr>
                        </table>
                            <div className="login-title">구매대행 상품은 캐러벨 배송대행 서비스로 배송됩니다.</div>
                          <div className="shipping-button-align-center">
                            <button onClick={orderbill} className="service-button">주문하기</button>
                          </div>      
                    </div>
                </div>
            </div>
        </div>
    );
  }

export default OrderPage;
