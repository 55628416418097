import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import logo from '../img/CARAVEL.webp';
import whitelogo from '../img/CARAVEL-white.webp';
import { auth } from "../firebase";
import profile from '../img/profile.webp';


function Header(){

  // get usercredential from firebase
  const [user, setUser] = useState(null);
  const [Ismobile, setIsmobile] = useState(false);
  const [Menu, setMenu] = useState(false);
  const [HeaderTop, setHeaderTop] = useState(true);

  const logo1 = logo;
  const logo2 = whitelogo;

    const navigate = useNavigate();
    const pathname = useLocation();

    const handleScroll = () => {
        const position = window.pageYOffset;
        if(!Menu){
          if(position > 0){
            setHeaderTop(false);
          }
          else{
            setHeaderTop(true);
          }
        }
    };
    
    const handlesize = () => {
      const position = window.innerWidth;
      if (position < 1240) {
        setIsmobile(true);
      } else {
        setIsmobile(false);
      }
    };

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                setUser(user);
            } else {
                setUser(null);
            }
        });
        return () => {
          unsubscribe()
          handlesize()
        };
    }, []);


    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        window.addEventListener('resize', handlesize, { passive: true });
        


        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handlesize);
        };
    }, []);

    useEffect(() => {
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      
   }, [pathname]);

    const goToTop = () => {
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    };

        const goToMain = () => {
              navigate(`/`)
              setMenu(false);
              const position = window.pageYOffset;
              if(position > 0){
                setHeaderTop(false);
              }
              else{
                setHeaderTop(true);
              }
        };


        const goToCS = () => {
            navigate(`/about`)
        };


              const goToSecond = () => {
                navigate(`/`)
                  window.scrollTo({
                      top: (window.innerHeight * 1),
                      behavior: 'smooth',
                  });
              };

      const goToThird = () => {
          window.scrollTo({
              top: (window.innerHeight * 2),
              behavior: 'smooth',
          });
      };

            const goToFour = () => {
                window.scrollTo({
                    top: (window.innerHeight * 3),
                    behavior: 'smooth',
                });
            };

    const togglemenu = () => {
      setMenu(Menu => !Menu);
      if(Menu){
        const position = window.pageYOffset;
          if(position > 0){
            setHeaderTop(false);
          }
          else{
            setHeaderTop(true);
          }
      } else {
        setHeaderTop(false);
      }
    }

    useEffect(() => {
      
      const position = window.innerWidth;
      if (position < 1240) {
        setIsmobile(true);
      } else {
        setIsmobile(false);
      }
      const a = window.pageYOffset;
      if(Menu){

      } else{
        if(a > 0){
          setHeaderTop(false);
        }
        else{
          setHeaderTop(true);
        }
      }
    }, []);

    const logout = () => {
      auth.signOut();
      togglemenu();
      navigate('/');
  }

    return (
          <div>
            {
              Ismobile ?
             
              HeaderTop && !Menu && pathname.pathname == "/"?
                <div className='Header-top'>
                  <div className='Header-mobile-main'>
                    <ul className='Header-ul'>
                    <img alt="logo" className='logo-image' src={logo2} onClick={goToMain} />
                    </ul>
                      <ul className='Header-user-ul'>
                        <li className="Header-mobile-li" onClick={togglemenu}><span className="Header-top-menu-icon">&#9776;</span></li>
                      </ul> 
                  </div>
                </div>
                :
                    <div className='Header'>
                      <div className='Header-mobile-main'>
                        <ul className='Header-ul'>
                        <img alt="logo" className='logo-image' src={logo1}  onClick={goToMain} />
                        </ul>
                          <ul className='Header-user-ul'>
                            <li className="Header-mobile-li" onClick={togglemenu}><span className="Header-menu-icon">&#9776;</span></li>
                          </ul> 
                      </div>
                    </div>
                :
                HeaderTop && pathname.pathname == "/" ?
              
                <div className='Header-top'>
                  <div className='Header-top-main'>
                    <img alt="logo" className='logo-image' src={logo2} onClick={goToMain} />
                    <ul className='Header-ul'>
                      <Link to="/shipping"><li className="Header-li"><span className="Header-top-menu-link">배송대행</span></li></Link>
                      <Link to="/order"><li className="Header-li"><span className="Header-top-menu-link">구매대행</span></li></Link>
                      <Link to="/seller"><li className="Header-li"><span className="Header-top-menu-link">셀러주문</span></li></Link>
                      <Link to="/about"><li className="Header-li"><span className="Header-top-menu-link">고객센터</span></li></Link>
                    </ul>
                    {
                      user ?
                      <ul className='Header-user-ul'>
                        <Link to="/user"><li className="Header-li"><span className="Header-top-menu-link">회원정보</span></li></Link>
                      </ul> :      
                      <ul className='Header-user-ul'>
                        <Link to="/login"><li className="Header-li"><span className="Header-top-menu-link">로그인</span></li></Link>
                        <Link to="/signup"><li className="Header-li"><span className="Header-top-menu-link">회원가입</span></li></Link>
                      </ul>
                    }
                  </div>
              </div>
              :
                  <div className='Header'>
                    <div className='Header-main'>
                      <img alt="logo" className='logo-image' src={logo1}  onClick={goToMain} />
                      <ul className='Header-ul'>
                          <Link to="/shipping"><li className="Header-li"><span className="Header-menu-link">배송대행</span></li></Link>
                          <Link to="/order"><li className="Header-li"><span className="Header-menu-link">구매대행</span></li></Link>
                          <Link to="/seller"><li className="Header-li"><span className="Header-menu-link">셀러주문</span></li></Link>
                          <Link to="/about"><li className="Header-li"><span className="Header-menu-link">고객센터</span></li></Link>
                      </ul>
                      {
                          user ?
                          <ul className='Header-user-ul'>
                            <Link to="/user"><li className="Header-li"><span className="Header-menu-link">회원정보</span></li></Link>
                          </ul>  :                    
                          <ul className='Header-user-ul'>
                            <Link to="/login"><li className="Header-li"><span className="Header-menu-link">로그인</span></li></Link>
                            <Link to="/signup"><li className="Header-li"><span className="Header-menu-link">회원가입</span></li></Link>
                          </ul>
                      }
                    </div>
                  </div>
             
             }
             <div>
              {Menu && Ismobile ?
                auth.currentUser ?
                <div>
                  <div className="Header-menu-top">
                    <Link to="/edit" onClick={togglemenu}>
                      <div className="user-title-div-profile">
                          <img className="user-title-div-profile-icon" src={profile} />
                          <div className="user-title-div-profile-text">
                          <div>
                            <div className="header-username">
                            {user?.email}
                            </div>
                            <div className="user-title-div-profile-text-div">
                              <div onClick={logout} className="header-user-flex">
                                로그아웃>
                              </div>
                              <div className="user-flex">
                              </div>
                              <div className="user-flex">
                              </div>
                              <div className="user-flex">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                    <Link to="/shipping" onClick={togglemenu}>
                      <div className="Header-menu-div">
                        배송대행
                      </div>
                    </Link>
                    <Link to="/order" onClick={togglemenu}>
                      <div className="Header-menu-div">
                        구매대행
                      </div>
                    </Link>
                    <Link to="/usage" onClick={togglemenu}>
                      <div className="Header-menu-div">
                        주문내역
                      </div>
                    </Link>
                    <Link to="/coupon" onClick={togglemenu}>
                      <div className="Header-menu-div">
                        할인쿠폰
                      </div>
                    </Link>
                    <Link to="/about" onClick={togglemenu}>
                      <div className="Header-menu-div">
                        고객센터
                      </div>
                    </Link>
                  </div>
                </div>
                :
                <div>
                  <div className="Header-menu-top">
                    <div className="user-title-div-profile">
                        <img className="user-title-div-profile-icon" src={profile} />
                        <div className="user-title-div-profile-text">
                        <Link to="/login" onClick={togglemenu}>
                          <div className="header-username">
                            로그인
                          </div>
                        </Link>
                      </div>
                    </div>
                  <Link to="/shipping" onClick={togglemenu}>
                    <div className="Header-menu-div">
                      배송대행
                    </div>
                  </Link>
                  <Link to="/order" onClick={togglemenu}>
                    <div className="Header-menu-div">
                      구매대행
                    </div>
                  </Link>
                  <Link to="/about" onClick={togglemenu}>
                    <div className="Header-menu-div">
                      고객센터
                    </div>
                  </Link>
                  <Link to="/login" onClick={togglemenu}>
                    <div className="Header-menu-footer">
                      로그인
                    </div>
                  </Link>
                  </div>
                </div>

              :
              <div>
              </div>
            }
             </div>
          </div>
    );
 }

 export default Header;
