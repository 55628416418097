import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { db } from "../firebase";
import { ref, get, set } from "firebase/database";


import ShippingComponent from "./shippingcomponent";

import { loadTossPayments } from '@tosspayments/payment-sdk'
const clientKey = 'test_ck_4vZnjEJeQVxabxZBQZbrPmOoBN0k'

// get database from firebase each call ?
// set db on each location 

// Promise를 사용하는 경우

function ShippingPage() {
    const navigate = useNavigate();

    const [Ismobile, setIsmobile] = useState(false);

    const [currency, setCurrency] = useState({
      usd: 0,
      cny: 0,
      eur: 0,
      jpy: 0,
      krw: 0,
      gbp: 0
    });
    const [shippingtablearray, setshippingtablearray] = useState([]);
    
  //usestate 
    const [selectedButton, setSelectedButton] = useState(0);
    const [cityselectedbutton, setCitySelectedButton] = useState(0);
    const [shippingselectedbutton, setShippingSelectedButton] = useState(0);
    const [optionselectedbutton, setOptionSelectedButton] = useState([
      true, false, false, false, false
    ]); 
    const [rateselectedbutton, setRateSelectedButton] = useState(0);

    const [weight, setWeight] = useState(null);

    const [shippingprice, setShippingPrice] = useState(0);

    const countrybuttons = ['미국', '중국', '유럽', '일본', '호주'];
    const citybuttoname = ['전국','오리건', '델라웨어', '뉴저지', '캘리포니아'];
    const citybuttonchi = ['전국','웨이하이', '상하이', '홍콩']
    const citybuttoneur = ['영국', '프랑스', '독일', '스페인', '이탈리아']
    const citybuttonjap = ['전국']
    const citybuttonaus = ['호주', '뉴질랜드']

    const citybuttons = [citybuttoname, citybuttonchi, citybuttoneur, citybuttonjap, citybuttonaus]

    const shippingbuttons = ['항공','해운','EMS']
    const optionbuttons = ['검수', '정밀검수', '재포장', '통관대행', 'EMS']
    const ratebuttons = ['kg', 'lbs']

    const [dataref, Setdataref] = useState(ref(db, "/"));

    const handleClick = (index) => {
      setSelectedButton(index);
      setCitySelectedButton(0);
    };

    const cityhandleClick = (index) => {
      setCitySelectedButton(index);
    }

    const shippinghandleclick = (index) => {
      setShippingSelectedButton(index);
    }

    const optionhandleclick = (index) => {
      let oth = [...optionselectedbutton]
      oth[index] = !oth[index]
      setOptionSelectedButton(optionselectedbutton => oth);
    }

    const ratehandleclick = (index) => {
      setRateSelectedButton(index);
    }

    useEffect(()=> {
      
      const fetchData = async () => {
        const testRef = ref(db, "/Exchange/");
        get(testRef).then((snapshot) => {
          setCurrency(currency => snapshot.val());
        }).catch((error) => {
          
        });
      };

      fetchData();
    }, []);


    const tossPayments = loadTossPayments(clientKey)

    const testcrypt = () => {

        loadTossPayments(clientKey).then(tossPayments => {
            
            tossPayments.requestPayment('카드', { // 결제 수단 파라미터
            // 결제 정보 파라미터
            amount: 15000,
            orderId: 'JYq5cf0PwH-d_fYA6qDSw',
            orderName: '토스 티셔츠 외 2건',
            customerName: '박토스',
            successUrl: 'http://localhost:3000/success',
            failUrl: 'http://localhost:3000/fail',
          })
          .catch(function (error) {
            if (error.code === 'USER_CANCEL') {
              // 결제 고객이 결제창을 닫았을 때 에러 처리
            } else if (error.code === 'INVALID_CARD_COMPANY') {
              // 유효하지 않은 카드 코드에 대한 에러 처리
            }
          })
          
          })
          
          
    }

    const search = () => {
      setshippingtablearray([]);
      
      searchtest();
    }

    const searchtest = () => {
      if(currency.krw != 0){
        // search shipping company
        // get shipping price
        let chargeweight = weight * 2;
        let chargeweightlbs = weight * 2;

        if(ratebuttons[rateselectedbutton] == 'lbs'){
          chargeweight = Math.ceil(chargeweightlbs * 0.453592)
          chargeweightlbs = Math.ceil(chargeweightlbs)
        } else{
          chargeweight = Math.ceil(chargeweightlbs)
          chargeweightlbs = Math.ceil(chargeweightlbs * 2.20462)
        }

        let testRef = ref(db, "/cities/" + countrybuttons[selectedButton] + "/" + citybuttons[selectedButton][cityselectedbutton] + "/");

        let array = [];

        if(citybuttons[selectedButton][cityselectedbutton] == '전국'){
          testRef = ref(db, "/cities/" + countrybuttons[selectedButton] + "/");
          if(testRef != dataref){
            get(testRef).then((snapshot) => {
              snapshot.forEach((childSnapshot) => {
                childSnapshot.forEach((childSnapshot2) => {
                  childSnapshot2.forEach((childSnapshot3) => {
                    if(childSnapshot3.val().weight == 'kg'){
                      let weightkey = Object.keys(childSnapshot3.val().price).filter(key => Number(key) >= chargeweight)[0]
                      let price = Math.floor(childSnapshot3.val().price[weightkey] * currency[childSnapshot3.val().currency])
                      if(price !== price){
                        
                      } else{
                        if(price % 100 != 0){
                          price = price - (price % 100) + 100;
                        }
                        array.push(<ShippingComponent price={price} title={childSnapshot3.key} location={childSnapshot.key}
                          weight={weightkey} shipping={childSnapshot2.key} measure={childSnapshot3.val().weight} currency={childSnapshot3.val().currency} />)
                    }
                    }
                    else{
                      let weightkey = Object.keys(childSnapshot3.val().price).filter(key => Number(key) >= chargeweightlbs)[0]
                      let price = Math.floor(childSnapshot3.val().price[weightkey] * currency[childSnapshot3.val().currency])
                      if(price !== price){
                        
                      } else{
                        if(price % 100 != 0){
                          price = price - (price % 100) + 100;
                        }
                        array.push(<ShippingComponent price={price} title={childSnapshot3.key} location={childSnapshot.key}
                          weight={weightkey} shipping={childSnapshot2.key} measure={childSnapshot3.val().weight} currency={childSnapshot3.val().currency}  />);
                      }
                    }
                  })
                })
              });
              array.sort((a, b) => a.props.price - b.props.price)
              setshippingtablearray(array)
              setShippingPrice(array[0].props.price)
              Setdataref(dataref => testRef)
            }).catch((error) => {
              
            });
          }
        }
        else{
          testRef = ref(db, "/cities/" + countrybuttons[selectedButton] + "/" + citybuttons[selectedButton][cityselectedbutton] + "/");
            get(testRef).then((childSnapshot) => {
              childSnapshot.forEach((childSnapshot2) => {
                childSnapshot2.forEach((childSnapshot3) => {
                  if(childSnapshot3.val().weight == 'kg'){
                    let weightkey = Object.keys(childSnapshot3.val().price).filter(key => Number(key) >= chargeweight)[0]
                    let price = Math.floor(childSnapshot3.val().price[weightkey] * currency[childSnapshot3.val().currency])
                    if(price !== price){
                      
                    } else{
                      if(price % 100 != 0){
                        price = price - (price % 100) + 100;
                      }
                      array.push(<ShippingComponent price={price} title={childSnapshot3.key} location={childSnapshot.key}
                        weight={weightkey} shipping={childSnapshot2.key} measure={childSnapshot3.val().weight} currency={childSnapshot3.val().currency} />);
                  }
                  }
                  else{
                    let weightkey = Object.keys(childSnapshot3.val().price).filter(key => Number(key) >= chargeweightlbs)[0]
                    let price = Math.floor(childSnapshot3.val().price[weightkey] * currency[childSnapshot3.val().currency])
                    if(price !== price){
                      
                    } else{
                      if(price % 100 != 0){
                        price = price - (price % 100) + 100;
                      }
                      array.push(<ShippingComponent price={price} title={childSnapshot3.key} location={childSnapshot.key}
                        weight={weightkey} shipping={childSnapshot2.key} measure={childSnapshot3.val().weight} currency={childSnapshot3.val().currency}  />)
                    }
                  }
                })
              })
              array.sort((a, b) => a.props.price - b.props.price)
              setshippingtablearray(array)
              setShippingPrice(array[0].props.price)
            Setdataref(dataref => testRef)
            }).catch((error) => {
              
            });
        }
        
      }
    }

    const searchtest3 = () => {
      
      // search shipping company
    }


    const sortarray = () => {
      
      const a = [...shippingtablearray].sort((a, b) => a.props.price - b.props.price)
      setshippingtablearray(a)
      const b = a[0].props.price
      setShippingPrice(b)
    }

    const sortarray2 = () => {
      const a = [...shippingtablearray].sort((a, b) => b.props.price - a.props.price)
      setshippingtablearray(a)
      const b = a[0].props.price
      setShippingPrice(b)
    }

    const searchtest2 = () => {
      
      let testRef = ref(db, "/cities/cities");

      get(testRef).then((snapshot) => {
        snapshot.forEach((childSnapshot) => {
          childSnapshot.forEach((childSnapshot2) => {
            childSnapshot2.forEach((childSnapshot3) => {
              childSnapshot3.forEach((childSnapshot4) => {
                set(ref(db, snapshot.key + "/" + childSnapshot.key + "/" + childSnapshot2.key + "/" + childSnapshot3.key + "/" + childSnapshot4.key), childSnapshot4.val()).catch((error) => {

                  
                });
              })
            })
          })
        });
      }).catch((error) => {
        
      });

    }
    
    
    useEffect(() => {
      
      const position = window.innerWidth;
      if (position < 1240) {
        setIsmobile(true);
      } else {
        setIsmobile(false);
      }
    }, []);
    
    const handlesize = () => {
      const position = window.innerWidth;
      if (position < 1240) {
        setIsmobile(true);
      } else {
        setIsmobile(false);
      }
    };


    useEffect(() => {
      window.addEventListener('resize', handlesize, { passive: true });
      
      return () => {
          window.removeEventListener('resize', handlesize);
      };
  }, []);



    const shippingbill = (price) => {
        navigate('/billing', {state: {type: "shipping", price : price}});
    }


    return (
        <div>
        <script src="https://js.tosspayments.com/v1/payment"></script>
            <div className='body-div'>
                <div className='contents-div'>
                        <div className="shipping-body">
                            <div className='title'>
                                <h1 className='contents-text-title font18'></h1>
                            </div>
                          <div className="shipping-select-div">
                            <h2>물류센터 위치</h2>
                            <div className={ Ismobile === true ? 'shipping-mobile-buttons-table' : 'shipping-buttons-table'} >
                              <div className={ Ismobile === true ? 'shipping-mobile-buttons-div' : 'shipping-buttons-div'}>
                              {countrybuttons.map((button, index) => (
                                    <button
                                      key={index}
                                      onClick={() => handleClick(index)}
                                      className={Ismobile === true ? 
                                        selectedButton === index ?
                                        'shipping-mobile-button-selected' : 'shipping-mobile-button'
                                        :
                                        selectedButton === index ?
                                        'shipping-button-selected' : 'shipping-button'
                                      
                                      }
                                    >
                                      {button}
                                    </button>
                                  ))}
                              </div>
                              <div className={ Ismobile === true ? 'shipping-mobile-buttons-div' : 'shipping-buttons-div'}>
                              {citybuttons[selectedButton].map((button, index) => (
                                    <button 
                                    key={index} 
                                    onClick={() => cityhandleClick(index)}
                                    className={Ismobile === true ? 
                                      cityselectedbutton === index ?
                                      'shipping-mobile-button-selected' : 'shipping-mobile-button'
                                      :
                                      cityselectedbutton === index ?
                                      'shipping-button-selected-under' : 'shipping-button-under'
                                    
                                    }
                                    >
                                      {button}
                                    </button>
                                  ))}
                              </div>
                            </div>
                          </div>
                          { /*<div className="shipping-select-div">
                            <h2>부가서비스</h2>
                            <tr className="shipping-buttons-div">
                              {optionbuttons.map((button, index) => (
                                <td
                                  key={index}
                                  onClick={() => optionhandleclick(index)}
                                  className={ optionselectedbutton[index] ? 'shipping-button-selected' : 'shipping-button'}
                                >
                                  {button}
                                </td>
                              ))}
                            </tr>
                          </div> 
                          */ } 
                          <div className="shipping-select-div">
                              <h2>예상무게</h2>
                              {
                                Ismobile ?
                                <div>
                                  <tr className="shipping-rate">
                                    <input className="shipping-mobile-input" placeholder="0.5kg 단위로 예상 배송금액을 책정합니다" type="number" value={weight} onChange={e => setWeight(e.target.value) } ></input>
                                  </tr>
                                  <tr className="shipping-rate">
                                    {ratebuttons.map((button, index) => (
                                      <td
                                        key={index}
                                        onClick={() => ratehandleclick(index)}
                                        className={ rateselectedbutton === index ? 'shipping-mobile-button-selected' : 'shipping-mobile-button'}
                                      >
                                        {button}
                                      </td>
                                    ))}
                                  </tr>
                                </div> : 
                                
                                <tr className="shipping-rate">
                                <input className="shipping-input" placeholder="0.5kg 단위로 예상 배송금액을 책정합니다" type="number" value={weight} onChange={e => setWeight(e.target.value) } ></input>
                                <div>
                                  
                                </div>
                                {ratebuttons.map((button, index) => (
                                  <td
                                    key={index}
                                    onClick={() => ratehandleclick(index)}
                                    className={ rateselectedbutton === index ? 'shipping-button-selected' : 'shipping-button'}
                                  >
                                    {button}
                                  </td>
                                ))}
                                </tr>
                              }
                          </div>
                          <div className="shipping-button-align-center">
                            <button onClick={search} className="service-button">검색하기</button>
                          </div>      
                        </div>
                  <div className='shipping-list'>
                    <table className="shipping-table">
                      {shippingtablearray}
                    </table>
                  </div>
                </div>
            </div>
        </div>
    );
  }

export default ShippingPage;
