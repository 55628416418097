import { useNavigate } from "react-router-dom";


function ShippingComponent(props) {
    const navigate = useNavigate();
    const shippingbill = (price) => {
        let a = [];
        a.push(props.title);
        a.push(props.location);
        a.push(props.shipping);
        a.push(props.weight);
        a.push(props.measure);

        navigate('/billing', {state: {type: "shipping", price : price, shippinglist: a}});
    }

    return (
        <tr className="shipping-tr">
            <td className="shipping-td-1">
                {props.title}<br/>
                ({props.location})<br/>
                {props.shipping}
                {props.weight/2}
                {props.measure}
            </td>
            <td className="shipping-td-2">
                {props.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원
            </td>
            <td className="shipping-td-3">
                <button onClick={() => shippingbill(props.price)} className="shipping-buy-button">결제하기</button>
            </td>
        </tr>
    );
  }

export default ShippingComponent;
