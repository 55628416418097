

function ProductComponent(props) {

    return (
        <li className='trade-li'>
            <div className='trade-li-checkbox-div'>
            <input type="checkbox" className='trade-li-checkbox' />
            </div>
            <div className='trade-li-image-div'>
                
            </div>
            <div className='trade-li-info-div'>
            <div>
                <strong className='trade-li-info-strong'>{}</strong>
            </div>
            <div className='trade-li-info-div-price'>
                <div>
                <strong className='trade-li-info-strong'>매입 (1달)   </strong>
                <strong className='trade-li-price-buy'>45,000 (1.5%)</strong>
                </div>
                <div>
                <strong className='trade-li-info-strong'>현재 판매중 </strong>
                <strong className='trade-li-info-strong'>44,300</strong>
                </div>
            </div>
            </div>
            <div className='trade-li-price-div'>
            <div className='trade-li-info-textbox'>
            <div>
                <strong>계약확인완료</strong>
            </div>
            <div>
                <strong>지급보증</strong>
            </div>
            </div>
            </div>
            <div className='trade-li-date-div'>
            <div className='trade-li-time-textbox'>
                <strong>1:10:12</strong>
            </div>
            </div>
        </li>
    );
  }

export default ProductComponent;
