 export function Term1() {
    return (
      <div>
        한국외상결제 캐러벨 서비스 이용약관
        <br/>제1조(목적)
        <br/>주식회사 한국외상결제(이하 ‘당사’, ‘회사’, ‘캐러벨’)는 당사가 회원에게 제공하는 "해외배송대행 서비스" 및 "수입대행 서비스"를 이용함에 있어 회사와 회원의 권리•의무 및 책임사항을 규정함을 목적으로 합니다.
        <br/>본 규약과는 별도로 당사의 판단에 따라 수시로 개별 규약을 규정할 수 있으며, 해당 개별 규약은 본 규약과 합쳐져 하나의 규약을 구성합니다.
        <br/>따라서 본 서비스를 이용하기 위해서는 이러한 모든 규약에 동의해야 합니다.
        <br/>또한 본 규약과 개별 규약이 상충될 경우는 개별 규약을 우선하며, 그 밖의 부분은 본 규약과 개별 규약을 동시에 적용합니다.
        <br/>제2조 (정의)
        <br/>•	1.“캐러벨”이라 함은 회사가 이 약관에 의하여 재화나 용역을 회원에게 제공하기 위하여 컴퓨터 등 정보 통신설비를 이용하여 재화나 용역을 거래할 수 있도록 설정한 가상의 영업장을 말합니다.
        <br/>•	2."이용자"라 함은 "캐러벨"에 접속하여 이 약관에 따라 "당사"가 제공하는 서비스를 받는 이용하는 자를 말합니다.
        <br/>•	3.“회원”이라 함은 본 규약을 승인한 후 인터넷을 사용해 당사가 운영하는 본 서비스를 이용하기 위해 회원 가입을 신청하고, 당사가 회원으로서 회원 가입을 인정하여 회원 ID를 발급한 자를 의미합니다. 회원은 회원 자격을 제3자에게 이용하게 하거나 대여, 양도, 매매, 담보 설정 등에 이용할 수 없습니다.
        <br/>•	4."캐러벨"과 회원간에 발생하는 거래계약 유형에 대한 정의는 다음과 같습니다.
        <br/>가. "배송대행형" 거래계약 유형은 회원이 직접 해외의 전자상거래 사이트에 접속하여 구매(결제)된 물품이 "캐러벨"이 회원에게 제공하는 해외의 중간배송처에 입고된 경우,  회사의 책임으로 국제 운송 및 수입통관을 거쳐 회원의 국내 수취처까지 운송을 하여 회원이 수령할 수 있도록 하는 것을 의미합니다.
        <br/>나. "수입대행형" 거래계약 유형은 당사가 해외에서 구매대행 가능한 상품에 대하여 이용자가 "캐러벨"에 수입대행을 의뢰하면 회사가 해당 상품을 판매하는 해외 인터넷 쇼핑몰 등에서 이용자를 대신해 구매 및 결제를 하고 구매대행된 상품이 당사가 제공하는 해외의 중간배송처에 입고된 이후 한국외상결제의 책임으로 국제운송 및 수입통관을 거쳐 이용자의 국내 수취처까지 운송을 하여 이용자가 수령할 수 있도록 하는 것을 의미합니다.
        <br/>•	5."캐러벨"에서 제공하는 서비스에 대한 정의는 다음과 같습니다.
        <br/>가. "배송대행"이라 함은 배송대행형 거래계약 유형에 해당하는 서비스로서, 회원이 해외 인터넷 쇼핑몰 등에서 물품을 구매하려는 경우 회사가 해외에 물품 중간배송처로 활용될 수 있는 주소(이하" 캐러벨 주소"라 함)를 제공하고, 이후 회원이 직접 해외 인터넷 쇼핑몰 등에서 구매한 물품이 " 캐러벨 주소"로 배송되면, 회원이 서비스 요금(국제운송료+국내통관에 따른 세금 등의 제비용)을 결제시에 당사의 책임으로 국제운송 및 수입통관을 거쳐 회원이 지정하는 국내 수취처로 물품을 배송하여 회원이 물품을 수령할 수 있도록 하는 서비스를 의미합니다.
        <br/>나. "구매대행"이라 함은 수입대행형 거래계약 유형에 해당하는 서비스로서, 회원이 해외 인터넷 쇼핑몰에서 구매하고자 하는 물품을 구체적으로 명시하여 구매를 요청한 것에 대하여 이용자가 물품구매대행에 따르는 총 비용을 결제시 회사가 해외사용이 가능한 기명식 법인신용카드 및 해외송금(Wire Transfer) 등을 통해 해당 상품을 판매하는 해외 인터넷 쇼핑몰 등에서 이용자를 대신해 구매 및 결제를 하고 구매대행 된 상품이 회사가 제공하는 해외의 중간 배송처에 입고된 이후 회원이 서비스 요금(국제운송료+국내통관에 따른 세금 등의 제비용)을 결제시에 “배송대행”서비스를 통하여 당사의 책임으로 국제운송 및 수입통관을 거쳐 이용자가 지정하는 국내 수취처까지 배송하여 이를 이용자가 수령할 수 있도록 하는 서비스를 의미합니다.
        <br/>제3조 (약관의 명시와 설명 및 개정)
        <br/>•	1. 회사는 이 약관의 내용과 상호 및 대표자 성명, 영업소 소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함), 전화번호, 팩스번호, 전자우편주소, 사업자등록번호, 통신판매업 신고번호, 개인정보 관리책임자 등을 회원이 쉽게 알 수 있도록 "캐러벨"의 초기 서비스화면(전면)에 게시합니다. 다만, 약관의 내용은 이용자가 연결화면을 통하여 볼 수 있도록 할 수 있습니다.
        <br/>•	2. 회사는 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제에 관한 법률, 전자거래기본법, 전자서명법, 정보통신망이용촉진 등에 관한 법률, 방문판매 등에 관한 법률, 소비자보호법 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
        <br/>•	3. 회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행 약관과 함께 “캐러벨” 홈페이지의 초기 화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다.
        <br/>다만, 이용자 및 회원에게 불리하게 약관 내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다. 이 경우 "캐러벨"은 개정 전 내용과 개정 후 내용을 명확하게 비교하여 이용자가 알기 쉽도록 표시합니다.
        <br/>•	4.“캐러벨"이 약관을 개정할 경우에는 그 개정약관은 그 적용일자 이후에 체결되는 계약에만 적용되고 그 이전에 이미 체결된 계약에 대해서는 개정전의 약관조항이 그대로 적용됩니다.
        <br/>다만 이미 계약을 체결한 이용자가 개정약관 조항의 적용을 받기를 원하는 뜻을 제3항에 의한 개정약관의 공지기간 내에 "캐러벨"에 송신하여 "캐러벨"의 동의를 받은 경우에는 개정약관 조항이 적용됩니다.
        <br/>•	5.이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제 등에 관한 법률, 공정거래위원회가 정하는 전자상거래 등에서의 소비자보호지침 및 관계법령 또는 상관례에 따릅니다.
        <br/>•	
        <br/>제4조(서비스의 제공 및 변경)
        <br/>•	1."캐러벨"은 다음과 같은 업무를 수행합니다.
        <br/>o	가. 서비스에 대한 정보제공
        <br/>o	나. 회원이 해외에서 구매한 운송물의 수령, 보관, 검수, 운송, 인도
        <br/>o	다. 회원이 해외에서 구매 또는 수입대행을 의뢰한 물건에 대한 운송계약의 체결
        <br/>o	라. 수입, 통관 대행 관련 업무
        <br/>o	마. 반품, 교환, 환불 등 국제반송 관련 업무
        <br/>o	바. 기타 "캐러벨"이 정하는 업무
        <br/>•	2. 회사는 재화 또는 용역의 품절 또는 기술적 사양의 변경 등의 경우에는 장차 체결되는 계약에 의해 제공할 재화 또는 용역의 내용을 변경할 수 있습니다.
        <br/>이 경우에는 변경된 재화 또는 용역의 내용 및 현재의 재화 또는 용역의 내용을 게시한 곳에 즉시 공지합니다.
        <br/>•	3."캐러벨"이 제공하기로 이용자와 계약을 체결한 서비스의 내용을 재화 등의 품절 또는 기술적 사양의 변경 등의 사유로 변경할 경우에는 그 사유를 이용자에게 즉시 통지합니다.
        <br/>제5조(서비스의 중단)
        <br/>•	1."캐러벨"은 컴퓨터 등 정보통신설비의 보수, 점검, 교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.
        <br/>•	2."캐러벨"은 제1항의 사유로 서비스의 제공이 일시적 중단됨으로 인하여 이용자 또는 제3자가 입은 손해에 대하여 배상합니다. 단,  당사가 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.
        <br/>•	3.사업종목의 전환, 사업의 포기, 업체간의 통합 등의 이유로 서비스를 제공할 수 없게 되는 경우에는 "캐러벨"은 제8조에 정한 방법으로 이용자에게 통지하고 이용자가 이로 인하여 손해를 입은 경우에는 당초 "캐러벨"에서 제시한 조건에 따라 이용자에게 보상합니다.
        <br/>제6조 (회원 가입)
        <br/>•	1.이용자는 "캐러벨"이 정한 가입양식에 따라 회원정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로서 회원가입을 신청합니다.
        <br/>•	2."캐러벨"이 제1항과 같이 회원으로 가입할 것을 신청한 회원 중 다음 각 호에 해당하지 않는 한 회원으로 등록합니다.
        <br/>o	가. 가입신청자가 이 약관 제7조 제3항에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 다만 제7조 제3항에 의한 회원자격 상실 후 3년이 경과한 자로서 "캐러벨"의 회원 재가입 승낙을 얻은 경우에는 예외로 합니다.
        <br/>o	나. 등록 내용에 허위, 기재누락, 오기가 있는 경우
        <br/>o	다. 회원으로 등록하는 것이 "캐러벨"의 기술상 현저히 지장이 있다고 판단되는 경우
        <br/>•	3.회원가입계약의 성립시기는 "캐러벨"의 회원가입 승낙이 회원에게 도달한 시점으로 합니다.
        <br/>•	4.회원은 회원가입시 기입한 회원정보에 변경이 있는 경우, 즉시 전자우편이나 기타 방법으로 "캐러벨"에 그 변경사항을 알려야 합니다.
        <br/>•	5.회원가입 정보를 받은 경우도 본조 2항 가, 나 항목에 해당될 시에는 회원가입이 승인되지 않은 것으로 합니다.
        <br/>제7조(회원탈퇴 및 자격상실 등)
        <br/>•	1.회원은 "캐러벨"에 언제든지 회원탈퇴를 요청할 수 있으며 "캐러벨"은 즉시 회원탈퇴를 처리합니다.
        <br/>•	2.회원이 다음 각 호의 사유에 해당하는 경우, "캐러벨"은 회원자격을 제한 또는 정지시킬 수 있습니다.
        <br/>o	가. 가입신청 내역에 허위내용이 발견된 경우
        <br/>o	나. "캐러벨" 사용과 관련하여 서비스요금 미납 등 회원이 부담하는 채무를 기일 내에 이행하지 않는 경우
        <br/>o	다. 다른 사람의 "캐러벨" 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우
        <br/>o	라. 회원이 제출한 주소 또는 연락처의 변경통지를 하지 않는 등 회원의 귀책사유로 인해 회원이 소재 불명 되어 "캐러벨"이 회원에게 통지, 연락을 할 수 없다고 판단되는 경우
        <br/>o	마. "캐러벨"을 이용하여 관련법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우
        <br/>•	3."캐러벨"은 다음 각 호의 경우에 회원자격을 상실시킬 수 있습니다.
        <br/>o	가. "캐러벨"이 본조2항에 의해 회원자격을 제한 또는 정지시킨 후, 동일한 행위가 2회 이상 반복 되거나 30일 이내에 그 사유가 시정되지 아니하는 경우
        <br/>o	나. 회원이 구매한 물품에 대한 서비스요금 결제를 30일 이내에 이행하지 않을 경우
        <br/>o	다. 회원이 위법, 불법 혹은 부정한 목적으로 본 서비스를 사용하였다고 "캐러벨"이 객관적 자료에 의거 합리적으로 판단했을 경우
        <br/>•	4."캐러벨"이 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우 회원에게 이를 통지하고, 말소전에 최소한 30일 이상의 기간을 정하여 소명할 기회를 부여합니다. (회원의 소재불명 등으로 인해 소명 기회의 제공이 어려운 경우는 "캐러벨"의 판단으로 회원등록을 말소할 수 있습니다.)
        <br/>•	
        <br/>제8조(회원에 대한 통지)
        <br/>•	1."캐러벨"이 회원에 대한 통지를 하는 경우, 회원이 "캐러벨"과 미리 약정하여 지정한 전자우편 주소로 할 수 있습니다.
        <br/>•	2."캐러벨"은 불특정다수 회원에 대한 통지의 경우 1주일이상 "캐러벨" 공지 및 게시판에 게시함으로서 개별통지에 갈음할 수 있으며 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 개별통지(공지 또는 SMS)를 합니다.
        <br/>제9조(계약의 성립)
        <br/>"캐러벨"은 해외배송 서비스 이용과 관련하여 이용자와 아래의 절차를 통해 배송대행 및 수입대행 계약을 체결합니다.
        <br/>•	1.배송대행
        <br/>o	가. 회원이 "캐러벨" 회원가입 후 부여받은 "캐러벨 주소”로 회원이 해외 전자상거래 사이트 (온라인 쇼핑몰)에서 직접 구매하여 "캐러벨"에 배송신청서 작성이 완료된 물품이 정상 입고된 순간 "캐러벨"과 회원 간의 배송대행 계약은 체결되며, 회원 역시 배송대행 서비스를 이용함에 동의한 것으로 간주합니다.
        <br/>"캐러벨"은 입고 된 상품 내역 및 서비스요금 결제통지서를 회원에게 통보하여 회원은 서비스 요금을 결제할 의무가 부여 됩니다.
        <br/>o	나. "캐러벨"과 회원의 배송대행 계약성립은 국제운송 및 수입통관, 국내 수취처 인도까지의 운송 전과정을 "캐러벨"에 일임하였음을 의미하며, 또한 회원은 수입통관 등의 진행에 있어 "캐러벨"의 요청이 있을 경우 이를 이행하겠다는 동의로 간주합니다.
        <br/>o	다. "한국외상결제"와 회원의 배송대행 계약 시 사용자가 선택한 제3자 업체에게 배송대행지 주소 제공 및 배송대행 업무를 일임할 수 있으며, 이 경우 해당 제3자 업체의 계약 요건에 따라 당사와 회원간의 계약사항이 당사의 판단으로 조정될 수 있음을 이해하고 동의함으로 간주합니다.
        <br/>•	2.수입대행
        <br/>o	가. 회원이 "캐러벨" 사이트내에 있는 물품 혹은 회원이 해외 인터넷 쇼핑몰에서 구매하고자 하는 물품을 구체적으로 명시하여 구매를 요청한 것에 대하여 수입대행에 따르는 비용결제를 통해 수입대행을 의뢰하고 이에 대해 "캐러벨"이 결제확인 SMS문자서비스를 통해 회원에게 발송 또는 "캐러벨" 사이트에서 이를 통보하여 회원에게 입고된 시점에 회원과 "캐러벨"간에 수입대행계약이 성립됩니다.
        <br/>o	나. 회원이 구매대행 의뢰한 물품을 "캐러벨 주소”로 수령하고, "캐러벨"은 입고 내역 및 서비스요금 결제통지서를 회원에게 통보하여 회원이 서비스요금 결제시 "캐러벨"과 회원간에 배송대행계약이 성립합니다.
        <br/>o	다. "캐러벨"과 회원의 계약성립은 해외 인터넷 쇼핑몰에서의 구매 및 결제 등 수입대행 전과정과 국제운송 및 수입통관, 국내 수취처 인도까지의 운송 전 과정을 "캐러벨"에게 일임하였음을 의미합니다.
        <br/>o	라. "한국외상결제"와 회원의 수입대행 계약 시 사용자가 선택한 제3자 업체에게 배송대행지 주소 제공 및 배송대행 업무를 일임할 수 있으며, 이 경우 해당 제3자 업체의 계약 요건에 따라 당사와 회원간의 계약사항이 당사의 판단으로 조정될 수 있음을 이해하고 동의함으로 간주합니다.

       <br/> <br/>•	3."캐러벨"은 회원의 수입대행 및 배송대행계약 요청에 대해 다음 각 호에 해당하면 승낙하지 않을 수 있습니다.
        <br/>o	가. 신청내용에 허위, 기재누락, 오기가 있는 경우
        <br/>o	나. 회원이 관련법령 및 동 약관에서 금지하는 재화에 대해 수입대행 또는 배송대행을 요청하는 경우
        <br/>o	다. 기타 수입대행 또는 배송대행신청을 승낙하는 것이 "캐러벨" 기술상 현저히 지장이 있다고 판단하는 경우
        <br/>o	
        <br/>제10조(서비스 대상물품 및 금지상품)
        <br/>"캐러벨"은 이용자가 자가 사용의 목적으로 구매하여 수입하는 경우 서비스를 제공하며, 한편 아래의 경우 고객의 동의를 구한 후 한국으로 발송하거나 해당 발송자에게로 반송할 수 있습니다. 이용자의 귀책사유로 인해 이용자의 소재가 불명확하여 상당한 기간 내에 이를 보완할 것을 이용자에게 요구하였음에도 그 기간 내에 보완하지 않은 경우 "캐러벨"은 당해 물품을 발송인에게 반송하고 이를 이용자에게 통보하며, 소요비용은 이용자로부터 정산하거나 "캐러벨"의 임의대로 물품을 처리하여 충당할 수 있습니다.
        <br/>단, 판매용 목적으로 구매하여 수입하는 경우는 사업자통관을 진행 할 경우 서비스 제공됩니다.
        <br/>•	가. 동물, 금•은괴, 화폐, 의약품, 무기류, 인체의 일부, 포르노그래피, 정밀금속, 석재류, 냉장보관물품, 냉동 또는 냉장을 요하는 물품 및 폭발물, 가연성 위험물 등
        <br/>•	나. 관세법에 의한 수입, 수출 불가 / 항공,해상운송 불가 품목일 경우
        <br/>o	(1) 현금 및 수표, 어음, 주식 그 밖의 유가증권류
        <br/>o	(2) 신용카드, 현금카드 등의 카드류
        <br/>o	(3) 금융기관의 예적금 통장 또는 예적금 인출용 카드
        <br/>o	(4) 수신처가 당사임을 용이하게 판별할 수 있는 상호나 기타 문구가 기재되지 않은 것
        <br/>o	(5) 서신 또는 현행법상 서신으로 정의된 통신 수단
        <br/>o	(6) 살아 있는 동식물, 죽은 동물 또는 박제 동물
        <br/>o	(7) 인체 또는 인체의 일부, 사체, 유골, 위패
        <br/>o	(8) 음식물과 그 밖에 변질 또는 부패하기 쉬운 것
        <br/>o	(9) 각성제, 대마, 마약, 향정신성 약품 및 기타 금지 약물,식약청고시수입금지품목등
        <br/>o	(10) 총포, 도검, 무기, 병기, 화약류, 폭발물, 독극물
        <br/>o	(11) 의약품, 의료기기
        <br/>o	(12) 아동 포르노, 성인 비디오, 그 밖의 외설물
        <br/>o	(13) 부정한 방법.수단으로 수득된 물
        <br/>o	(14) 경유국가를 포함한 수출입국가, 주, 지방자치단체의 법령에 따라 수송, 수출 및 수입 등이 금지되거나 제한되고 있는 것
        <br/>o	(15) 그 밖에 운송 사업자의 운송 약관상 취급이 인정되지 않는 것
        <br/>o	(16) 그 밖에한국관세청또는 수출국의 관세청 및 해당국가의 법률로정한 수출금지품목,당사가 부적당하다고 인정한 것
        <br/>•	다. 송장(Invoice) 부실기재, 포장불량 등으로 본 약관에 따른 서비스를 제공하기에 적절하지 않다고 "캐러벨"이 판단하는 경우
        <br/>•	라. 기타 관련법령에 의하여 수출입이 제한되는 물품을 대상으로 한 경우
        <br/>제11조(지급방법 및 적립금)
        <br/>"캐러벨" 서비스 이용에 대한 대금지급방법은 다음 각 호의 방법 중 가용한 방법으로 할 수 있습니다.
        <br/>•	가. 해외에서 사용가능한 신용카드 및 체크카드 결제
        <br/>•	나. 쿠폰 및 회사가 정하는 마일리지 (마일리지는 마이캐시를 일컫는다.)
        <br/>•	다. 기타 "캐러벨"이 인정하는 결제수단
        <br/>제12조(수신확인통지, 배송신청 변경 및 취소)
        <br/>•	1."캐러벨"은 이용자의 운송 및 수입대행신청이 있는 경우 이용자에게 수신확인통지를 합니다.
        <br/>•	2.수신확인통지를 받은 이용자는 의사표시의 불일치 등이 있는 경우에 수신확인통지를 받은 후 즉시 배송 신청 변경 및 취소를 요청할 수 있습니다.
        <br/>•	3."캐러벨" 주소에 도달한 물품이 제10조 각호에 해당하는 경우에는 당해 물품에 대한 배송 계약을 해지할 수 있으며, 이 경우 물품의 반송 등 사후조치를 위해 소요되는 일체의 비용은 이용자의 부담으로 합니다.
        <br/>제13조(서비스별 요금 결제, 재화 등의 공급 및 보관)
        <br/>•	1.배송대행 서비스
        <br/>o	가. 회원이 "캐러벨 주소”를 사용하여 물품을 구매할 경우에는 "캐러벨"은 "캐러벨 주소”에 회원이 주문한 물품이 도착한 후 관세 등 제세금, 국제운송료로 구분된 서비스 요금 결제요청 통지(E-Mail 및 SMS)를 회원에게 하고 회원은 제11조 각 항의 형태로 해당 금액을 3개월 이내에 결제 해야하며, 미결제시 회원이 주문한 물품은 "캐러벨" 주소에서 회원에게로 배송되지 않습니다.
        <br/>o	나. 회원은 전항의 기간 내에 금액을 결제하지 않을 경우 대금지급 확인일까지의 기간에 대해 소정의 보관료 및 지연손해금이 추가로 부과될 수 있으며 부과된 금액에 대하여 반드시 지급 하여야 합니다.
        <br/>o	다. 고의 또는 중과실로 인한 경우를 제외하고 물품이 "캐러벨 주소”에 일부상품 도착 후 30일이후부터 보관료가 발생하고, 30일 경과 이후에 발생하는 물품의 도난, 훼손, 멸실 등에 대하여 "캐러벨"은 책임을 지지 아니하며, 결제요청 통지 후 3개월 이후에는 임의대로 처분하여 보관료로 충당합니다.
        <br/>o	라. 회원이 배송신청서 작성시 선택한 배송신청 옵션 사항에 따라 물품의 포장은 진행되며, "캐러벨"은 운송료를 최소화하기 위해서 과대포장 여부와 부실한 포장상태, 상품의 훼손여부 상태를 점검을 하여 물품이 안전하게 배송될 수 있도록 최선을 다합니다.
        <br/>물품의 하자발생, 배송 방법상 문제가 있다고 판단된 경우 회원에게 “오류입고” 통보 후 회원 간 협의를 통해 재포장을 할 수 있으며, 이 과정에서 추가적인 포장비용이 발생할 경우 “캐러벨" 이용자에게 추가요금을 청구할 수 있습니다.
        <br/>추가포장에 동의하지 아니하는 경우에는 포장 부적합 또는 포장 불충분으로 인하여 발생한 손해에 대해서는 회사는 책임을 지지 않습니다. 다만, 회사의 고의 또는 중과실이 있는 경우에는 그러하지 아니합니다.
        <br/>o	라. 9조 1항 다. 목의 내용에 따라 제3자 업체에게 배송대행 업무가 일임된 경우 해당 배송 대행 업무 도중 발생한 손실에 대해서 회사는 책임을 지지 않으며, 제3자 업체의 보상안에 따라 이용자가 적절한 보상을 받을 수 있도록 회사가 판단하는 적절한 범위 내에서 필요한 정보의 제공 및 기타 지원을 제공합니다.
        <br/>o	
        <br/>•	2.수입대행 서비스
        <br/>o	가. 회원이 "캐러벨"에서 물품의 수입대행을 의뢰할 경우에는 "캐러벨" 에 제11조의 형태로 결제해야 합니다.
        <br/>o	나. "캐러벨"과 회원간의 수입대행계약에 의해 구매대행 된 물품이 "캐러벨" 주소에 입고된 물품에 대해 검수 후 국내의 수취처까지 배송하여 이용자가 수령할 수 있도록 합니다.
        <br/>o	다. "캐러벨"은 회원 물품의 수입대행을 수행함에 따른 전체 금액을 회원이 결제하기 전에 각각의 거래계약 유형별로 수입대행가격, 국제운송료 형태로 구분하여 회원에게 고지합니다.
        <br/>o	라. "캐러벨"에 명기된 가격은 수입대행가격(해외 인터넷쇼핑몰 등에서의 물품 구입가격,  “캐러벨 주소”까지의 운송료, Sales Tax등 + "캐러벨"의 구매대행 수수료 + 수입관세, 수입부가세)과 국제운송료가 모두 포함 된 가격입니다. 단, 개별 물품의 상황에 따라서 "캐러벨"에 고지된 기타비용(재포장 수수료, 수입관세, 수입부가세,국내 추가 배송료 등)이 추가로 발생할 수 있습니다.
        <br/>o	마. "캐러벨"은 상품을 판매하는 해외 인터넷쇼핑몰 등 공급자 정보(쇼핑몰 명 등)와 "캐러벨"에 명기된 가격의 상세내역에 대하여 이용자의 별도 요청이 있을 시 이를 E-Mail 또는 SMS 등을 통해 제공합니다.
        <br/>o	바. " 캐러벨"은 이용자가 "캐러벨"에서 수입대행을 의뢰한 상품에 대해서 이용자의 대금 결제단위를 기준으로 포장하여 배송함을 원칙으로 합니다.
        <br/>다만 결제 단위당 복수의 상품의 경우 각 상품의 해외 인터넷쇼핑몰 등 공급자가 다를 경우에는 이를 "캐러벨" 해외 물류센터에 도착하는 순서대로 배송 할 수 있으며, 의도적으로 관세 등 제세금을 절감하기 위한 행위(분할 재포장 등)는 하지 않습니다.
        <br/>o	사. 9조1항 다. 목의 내용에 따라 제3자 업체에게 배송대행 업무가 일임된 경우 해당 배송 대행 업무 도중 발생한 손실에 대해서 회사는 책임을 지지 않으며, 제3자 업체의 보상안에 따라 이용자가 적절한 보상을 받을 수 있도록 회사가 판단하는 적절한 범위 내에서 필요한 정보의 제공 및 기타 지원을 제공합니다.
        <br/>제14조(국제운송 및 통관)
        <br/>•	1.국제운송
        <br/>o	가. "캐러벨"은 회사"와 국제복합운송계약을 체결한 운송 및 통관업무제휴사를 통하여 "캐러벨 주소"에서부터 국내(한국)까지의 항공및 해상운송, 수입통관, 회원이 지정한 수취처까지의 국내배송의 용역을 제공합니다.
        <br/>o	나. 상기 "가" 항에서의 "캐러벨 주소”에서 회원이 지정한 수취처까지의 운송구간에서 물품의 파손 등 하자가 발생하였을 때, 배송대행형 거래계약 유형에 해당하는 배송대행 서비스에서는 상품가 금액 제한없이 상품가 및 국제운송료를 보상하며 수입대행형 거래계약 유형에 해당하는 수입대행 서비스에서는 수입대행가격 및 국제운송료를 포함한 총 금액을 보상합니다.
        <br/>단, 상품가 $500이상의 고가의 상품의 경우 특수포장을 신청하셨을 경우에만 보상진행 됩니다.
        <br/>o	다. 본 조에 의거 보상되는 물품가격의 기준은 고객이 배송신청서 혹은 구매대행 신청서 상에 기재한 금액을 기준으로 보상하며, 국내 택배보상 불가 품목의 경우 보상에서 제외됩니다.
        <br/>•	2.통관
        <br/>o	가. "캐러벨"은 개인이 자가사용 목적으로 수입하는 개인수입통관원칙에 의거, 이용자를 납세의무자로하고 수입요건을 구비하여 운송 및 통관업무제휴사를 통하여 통관절차를 수행합니다.
        <br/>o	나. 이 때 발생하는 관세 및 수입부가세 등 제세금은 운송 및 통관업무제휴사가 이용자를 대신하여 대한민국 세관에 대납하고 "캐러벨"은 이용자가 기 결제한 관세 및 수입부가세 등 제세금을 운송 및 통관업무제휴사와 정산하는 절차를 거칩니다.
        <br/>수입 물품의 과세가격이 $150 이하인 경우만 면세, $150 이 초과된 경우에는 과세처리가 되며, 해외공급자가 동일하거나 다른 경우에도 합산하여 과세하며 나눔배송의 경우에도 합산과세 처리 됩니다.
        <br/>o	아래와 같은 목록통관배제물품은 일반수입신고를 하여야 합니다.
        <br/>목록통관 배제대상물품
        <br/>	1. 국제운송
        <br/>	2. 한약재
        <br/>	3. 야생동물 관련 제품
        <br/>	4. 농림축수산물등 검역대상물품
        <br/>	5. 건강기능식품
        <br/>	6. 지식재산권 위반 의심물품
        <br/>	7. 식품류,과자류
        <br/>	8. 화장품(기능성화장품, 태반함유화장품, 스테로이드제 함유화장품 및 성분미상 등 유해화장품에 한함)
        <br/>	9. 전자상거래물품 등의 특별통관절차에 관한 고시 제3-3조 제3항에 따라 특별통관대상업체로 지정되지 아니한 전자상거래업체가 수입하는 물품
        <br/>	10. 통관목록 중 품명,규격,수량,가격 등이 부정확하게 기재된 물품
        <br/>	11. 그 밖에 법 제226조에 따른 세관장확인대상물품 등 목록통관이 타당 하지 아니하다고 세관장이 인정하는 물품
        <br/>제15조(반품, 환급 등)
        <br/>•	1.배송대행 서비스
        <br/>o	가. "캐러벨 주소”에 회원이 주문한 물품이 도착하여 한국으로 발송되기 전에 회원의 당해 물품에 대한 배송대행계약의 중도해지요청(반품 등)이 "캐러벨"에 도달한 경우 당해 물품은 회원의 요청에 따라 반송하며 이를 위해 소요되는 일체의 비용은 회원이 부담합니다.
        <br/>o	나. "캐러벨 주소”에 회원이 주문한 물품이 도착되어 회원이 결제한 후 한국으로 발송된 시점 이후에 전항의 중도해지요청이 접수될 경우 원 배송대행계약의 효력은 계속 존재하여 "캐러벨"이 당해 물품을 국내의 수취처까지 배송완료함으로써 원 배송대행계약에 대한 이행을 완료하게 되며, 중도해지요청에 대해서는 비용일체를 회원이 부담하는 것을 전제조건으로 하여 반송을 대행해 줄 수 있습니다.
        <br/>o	다. 본조 제1항 또는 제2항의 경우 회원이 지정한 수취처 이외의 제3의 지역으로 송부할 것을 요청하거나 해당 판매자에게 반송하였을 때 수취자가 물품을 수취거부 하거나 반품을 불인정하는 경우는 당해 물품을 회원에게 송부하고 그에 따른 일체의 비용은 회원이 부담합니다.
        <br/>•	2.수입대행 서비스
        <br/>o	가. "캐러벨" 사이트에서 회원이 수입대행을 신청한 재화가 품절 등의 사유로 인도를 할 수 없을 때에는 그 사유를 이용자에게 통지하고 사전에 재화 대금을 받은 경우에는 대금을 받은 날로부터 2 영업일 이내에, 그렇지 않은 경우에는 그 사유발생일로부터 2 영업일 이내에 계약해지 및 환급절차를 취합니다.
        <br/>o	나. 회원의 반품 신청에 의한 반품, 환급 규정은 다음과 같으며, 회원은 물품이 배송된 날로부터 10일 이내에 반품의사를 밝혀야 합니다.
        <br/>	① 단순변심에 의한 반품
        <br/>서비스 이행구간별로 해외 인터넷쇼핑몰 등에서 회원을 대신하여 구매하는데 제공된 수입대행 수수료, 국제/국내운송료, 수입제세금, 기타 제반비용 등에 대해 회원이 부담하는 것을 조건으로 반품을 받을 수 있습니다.
        <br/>	② 상품 상이, 결실, 파손, 손상, 오염에 의한 반품
        <br/>"캐러벨"의 해외 주소로부터 국내 회원의 수취전까지 국제복합운송 중 수입대행 상품의 분실,파손, 손상, 오배송, 오염 등이 발생할 경우 이에 대한 책임은 "캐러벨"에 있으며 상품 대금을 포함한 총결제금액 전액을 회원에게 직접 배상합니다. 또한, 상기된 하자로 인한 반품이 "캐러벨"의 해외주소에서 수취전 해외쇼핑몰의 귀책사유 임이 객관적으로 증명될 경우 "캐러벨"은 회원에게 반품을 받은 후, "캐러벨"이 회원을 대신하여 해외쇼핑몰에 클레임을 제기할 수 있습니다.
        <br/>o	다. 반품접수된 상품을 반환받는 경우 3영업일 이내에 이미 지급받은 상품 등의 대금을 환급합니다. (다만, 환급액에서 수입대행업체의 귀책사유가 없어 환급하지 않아도 될 비용항목을 제외 할 수 있습니다.)
        <br/>제16조 (상품 주문의 연락)
        <br/>회원은 상품 구입 후 즉시 당사로 당사의 소정 양식에 따라 회원에게 상품을 발송하는 데 필요한 정보를 정확하게 통지해야 합니다.
        <br/>제17조 (검품)
        <br/>•	1.당사는 상품을 개봉하여 그 내용물을 검품할 수 있습니다. 단, 당사는 반드시 검품해야 할 의무는 없으며, 검품 결과가 해당 상품의 품질, 하자 유무, 진품 여부 및 발송지, 경유지 및 목적지 국가의 관계 법령에 위반되지 않음을 보증하는 것은 아닙니다.
        <br/>•	2.전항의 검품 시에 범죄를 통한 수익의 이전 방지에 관한 법률과 그 밖의 법령에 위반되거나 위반할 우려가 있는 물품을 발견한 경우, 당사는 경찰이나 그 밖의 관련 관공서에 통보하여 상품을 제출하는 등의 조치를 취할 수 있습니다.
        <br/>•	3.상품의 검품 및 기타 본 조항에서 규정한 조치로 인해 회원에게 손해가 발생한 경우 회사의 고의 또는 중과실로 인한 경우를 제외하고 당사는 책임을 지지 않습니다.
        <br/>제18조 (본 서비스 제공의 거절)
        <br/>•	1.당사는 아래에 규정한 사유 또는 그와 같은 우려가 있는 경우에는 본 서비스의 제공을 이미 수락한 경우라도 본 서비스의 제공을 거절할 수 있습니다.
        <br/>o	(1) 상품이 제10조에서 규정한 상품일 때
        <br/>o	(2) 회원의 소재 또는 상품의 발송처 주소를 확인할 수 없을 때
        <br/>o	(3) 대금상환등 저희회사에서 수령할 수 없는 지불수단으로 구입된 상품일 때
        <br/>o	(4) 회원이 상품 수령을 거절한 때
        <br/>o	(5) 운송 사업자가 상품을 운송하지 않을 때
        <br/>o	(6) 제12조의 통지가 없을 때
        <br/>o	(7) 제12조의 통지에 따라 회원이 통지한 상품 정보와 당사가 수령한 상품이 다르거나 제12조의 통지가 부정확할 때
        <br/>o	(8) 수출입국 세관에서 금지한 때
        <br/>o	(9) 회원이 본 서비스의 이용 요금을 지불하지 않을 때
        <br/>o	(10) 회원이 본 약관을 위반한 때
        <br/>o	(11) 기타 당사가 부적당하다고 판단한 때
        <br/>•	2.당사는 본 서비스 제공의 일시 중단, 정지 등으로 회원에게 어떤 손해가 발생하더라도 전혀 책임을 지지 않습니다.
        <br/>제19조 (본 서비스의 변경 및 폐지)
        <br/>당사는 언제라도 본 서비스의 내용을 15일 이전 게시판을 통해 사전 고지안내 후 변경 또는 폐지할 수 있습니다. 변경 또는 폐지로 인해 회원에게 불이익이나 손해가 발생하더라도 당사는 책임을 지지 않습니다
        <br/>제20조 (본 서비스의 중단, 정지)
        <br/>•	1.당사는 다음 중 어느 하나의 사유에 해당하는 경우, 회원에게 사전 통지 없이 본 서비스의 일부 또는 전부를 일시 중단하거나 정지할 수 있습니다.
        <br/>o	(1) 본 서비스의 제공을 위한 장치, 시스템의 보수 점검, 업데이트를 실시하는 경우
        <br/>o	(2) 화재, 정전, 천재지변, 시스템 장애 등으로 본 서비스를 제공하기 어려운 경우
        <br/>o	(3) 필요한 전기통신 사업자의 서비스가 제공되지 않는 경우
        <br/>o	(4) 기타 당사가 본 서비스의 일시 중단 또는 정지가 필요하다고 판단한 경우
        <br/>•	2.당사는 본 서비스 제공의 일시 중단, 정지 등으로 회원에게 어떤 손해가 발생하더라도 전혀 책임을 지지 않습니다.
        <br/>제21조 (서비스제한,금지 행위)
        <br/>•	1.회원은 본 서비스를 이용할 때 다음 각 호에 해당하는 행위의경우에는 고객의 동의를 구한 후 해당 발송자에게로 반송할 수 있습니다. 이때 반송에 소요되는 비용은 이용자로부터 정산하거나, 회사의임의대로 물품을 처리하여 충당할 수 있습니다.
        <br/>o	(1) 본 서비스를 부정한 목적으로 이용하는 행위
        <br/>o	(2) 당사와 기타 제3자의 상표권, 저작권, 의장권, 특허권 등의 지적재산권, 초상권, 초상사용권과 기타 권리를 침해하는 행위
        <br/>o	(3) 사기 등의 범죄와 연관된 행위
        <br/>o	(4) 제3자로 위장하여 본 서비스를 이용하는 행위
        <br/>o	(5) 당사와 기타 제3자의 설비를 불법으로 이용하거나 그 운영에 지장을 주는 행위
        <br/>o	(6) 법령, 본 약관, 개별 약관 또는 공공의 질서와 선량한 풍속에 위반되는 행위
        <br/>o	(7) 본 서비스의 운영을 방해하는 행위
        <br/>o	(8) 수입, 수출 불가 / 항공운송 불가 품목일 경우, 송장(Invoice) 부실기재, 포장불량 등으로 본 약관에 따른 서비스를 제공하기에 적절하지 않다고 회사가 판단하는 경우
        <br/>o	(9) 기타관계법령에의해 수출입이 제한되거나 당사가 부적당하다고 판단하는 행위
        <br/>•	2.회원이 본 약관 또는 개별 약관을 위반함으로써 당사에 어떠한 손해가 발생한 경우, 당사는 회원에게 손해 배상을 청구할 수 있습니다.
        <br/>제22조 (회사와 회원의 의무)
        <br/>•	1.회사는 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로 재화•용역을 제공하는데 최선을 다하여야 합니다.
        <br/>•	2.회사는 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록 이용자의 개인정보(신용정보 포함) 보호를 위한 보안 시스템을 갖추어야 합니다.
        <br/>•	3.회원은 다음 각 호의 행위를 하여서는 안됩니다.
        <br/>o	a. 신청 또는 변경시 허위 내용의 등록
        <br/>o	b. 타인의 정보 도용
        <br/>o	c. “캐러벨”에 게시된 정보의 변경
        <br/>o	d. “캐러벨”이 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시
        <br/>o	e. “캐러벨”를 이용한 기타 제3자의 저작권 등 지적재산권에 대한 침해
        <br/>o	f. “캐러벨”이 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
        <br/>o	g. 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 몰에 공개 또는 게시하는 행위 또는 타인을 비방하는 게시 행위 (단, 이 경우 회사는 임의적으로 게시물을 삭제할 수 있습니다.)
        <br/>제23조 (저작권의 귀속 및 이용제한)
        <br/>•	1.회사가 작성한 저작물에 대한 저작권 기타 지적재산권은 회사에 귀속합니다.
        <br/>•	2.이용자는 “캐러벨” 을 이용함으로써 얻는 정보 중 회사에게 지적재산권이 귀속된 정보를 회사의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 등 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.
        <br/>제24조 (분쟁해결)
        <br/>•	"캐러벨"은 회원이 제기하는 정당한 의견이나 불만을 접수하여 그 피해를 보상처리하기 위하여 피해보상처리기구를 설치, 운영합니다. "캐러벨"은 이용자로부터 제출되는 불만사항 및 의견을 우선적으로 처리하도록 최선을 다합니다.
        <br/>제25조 (관할법원 및 준거법)
        <br/>•	1.본 약관 및 개별 약관의 준거법은 대한민국법으로 합니다.
        <br/>•	2.회사와 회원 간에 발생한 소송은 제소 당시의 이용자의 주소에 의하고, 주소가 없는 경우에는 거소를 관할하는 지방법원의 전속관할로 합니다. 다만, 제소 당시 이용자의 주소 또는 거소가 분명하지 않거나 외국 거주자의 경우에는 민사소송법상의 관할법원에 제기합니다.
      </div>
    );
}

export function Term2() {
    return (
      <div>
      캐러벨 개인정보처리방침 
      <br/>본 개인정보처리방침은 당사가 본 사이트에서 수집한 정보를 활용하는 방식을 알려주고 있습니다. 본 사이트를 이용하거나 개인정보를 제출하기 전에 본 개인정보처리방침을 읽어보시기 바랍니다.
      <br/>본 사이트의 활용은 귀하가 본 개인정보처리방침에 명시된 규정에 동의함을 의미합니다. 본 규정은 변경될 수 있으며 변경된 규정은 전부 게시됩니다.
      <br/>귀하의 제공 정보가 활용되는 방식을 확실히 이해할 수 있도록 본 사이트를 방문할 때마다 개인정보처리방침을 읽어보시길 권장합니다.
      <br/>1. 개인정보 수집에 대한 동의
      <br/>회사는 이용자들이 회사의 개인정보처리방침 또는 이용약관의 내용에 대하여 『동의』 버튼 또는 『취소』버튼을 클릭할 수 있는 절차를 마련하여, 『동의』버튼을 클릭하면 개인정보 수집에 대해 동의한 것으로 봅니다.
      <br/>2. 개인정보의 수집목적 및 이용목적
      <br/>회사가 이용자들의 개인정보를 수집하는 목적은 서비스 이용자의 신분을 확인하고 당사 서비스를 이용하고자 하는 의사를 확인하며, 이용자에게 최적의 서비스를 제공하고자 하는 것입니다. 대부분의 서비스는 별도의 사용자 등록이 없이 언제든지 사용할 수 있습니다.
      <br/>그러나 회사는 서비스(해외경매, 해외구매 등)를 통하여 이용자들에게 맞춤 식 서비스를 비롯한 보다 더 향상된 양질의 서비스를 제공하기 위하여 이용자 개인의 정보를 수집하고 있습니다.
      <br/>회사는 이용자의 사전 동의 없이는 이용자의 개인 정보를 함부로 공개하지 않으며, 수집된 정보는 아래와 같이 이용하고 있습니다.
      <br/>•	가. 이용자들이 제공한 개인정보를 바탕으로 보다 더 유용한 서비스를 개발할 수 있습니다.
      <br/>회사는 신규 서비스개발이나 콘텐트의 확충 시에 기존 이용자들이 회사에 제공한 개인정보를 바탕으로 개발해야 할 서비스의 우선 순위를 보다 더 효율적으로 정하고, 회사는 이용자들이 필요로 할 콘텐츠를 합리적으로 선택하여 제공할 수 있습니다.
      <br/>•	나. 각 수집정보 별 수집목적은 다음과 같습니다.
      <br/>o	① 성명, 아이디, 비밀번호: 서비스이용에 따른 문의사항의 처리, 본인식별 제공
      <br/>o	② 개인통관고유부호: 캐러벨에서 제공하는 해외배송대행/구매대행 서비스 이용에 따른 통관 시 제공
      <br/>o	③ 이 메일주소, 전화번호: 공지사항 전달, 본인의사 확인, 불만처리 등 원활한 의사소통을 위해 확보, 새로운 서비스나 신상품 및 이벤트 정보 등 안내
      <br/>o	④ 은행계좌정보, 신용카드정보, 핸드폰번호, 전화번호: 유료서비스이용에 대한 요금결재 및 물품대금 결제 등
      <br/>o	⑤ 주소, 전화번호: 청구서, 물품배송 시 배송지 주소 및 연락처 확보 등
      <br/>o	⑥ IP Address, 방문 일시: 불량회원의 부정 이용 방지와 비인가 사용 방지 등
      <br/>o	⑦ 그 외 선택항목: 개인 맞춤 서비스를 제공하기 위한 자료 등
      <br/>3. 수집하는 개인정보의 항목
      <br/>회사가 수집하는 개인정보 항목에 따른 구체적인 수집 방법 및 항목은 다음과 같습니다.
      <br/>가. 개인정보 수집항목
      <br/>•	1) 홈페이지 / APP 회원
      <br/>o	① 개인회원 : 아이핀/본인인증 정보, 생년월일/성별, (의류,신발)사이즈 정보, 성명, 아이디, 비밀번호, E-mail, 전화번호, 휴대폰 번호, 주소, 메일·SMS 수신여부, 영문명, 개인통관고유부호, 추천인 ID, 국적, 거주 국가
      <br/>o	② 기업 회원 : 아이디, 비밀번호, 상호명, 사업자등록번호, 영문회사명, 대표자 성명, 담당자명, 담당자 영문명, 팩스번호, 담당자 전화번호, 담당자 휴대폰 번호, 회사주소
      <br/>•	2) 국내택배·국제발송 서비스
      <br/>o	① 택배 발송 : 송·수하인에 대한 성명, 전화번호, 휴대폰 번호, 주소, E-mail(온라인 예약시)
      <br/>o	② 운송장 조회, 운송장 영수증 발급 : 성명, 전화번호
      <br/>o	③ 특송화물 수입통관 진행 시 : 일반통관 및 특송화물에 대하여 받으시는 분의 개인통관고유부호, 성명, 전화번호, 휴대번호, E-mail, 여권사본,기타 서류
      <br/>•	3) 해외 배송·구매 대행 서비스
      <br/>o	① 배송신청(캐러벨) : 송·수하인에 대한 이름, 전화번호, 휴대폰 번호, 주소, E-mail, 대금결제정보(신용카드, 계좌이체),개인통관고유부호
      <br/>o	② 물품구매(캐러벨) : 송·수하인에 대한 이름, 전화번호, 휴대폰 번호, 주소, E-mail, 대금결제정보(신용카드, 계좌이체),개인통관고유부호
      <br/>•	4) 고객 문의
      <br/>o	① 고객센터 1:1문의 : 성명, 전화번호, E-mail
      <br/>o	② 구매대행 문의, 견적 의뢰 : 성명, 전화번호, 휴대폰 번호, E-mail, 주소
      <br/>•	5) 기타
      <br/>o	① 이벤트 응모 : 성명, 전화번호, 휴대폰 번호, 주소, E-mail, SNS ID 등 기타 응모에 필요한 정보
      <br/>o	② 자동 수집 항목 : 서비스 이용기록, 접속 로그, 쿠키, 접속 IP 정보
      <br/>o	③ 기타 서비스 이용과정 및 사업 처리과정에서 수집될 수 있는 개인정보 : 결제기록, 이용정지 기록
      <br/>나. 개인정보 수집 방법
      <br/>•	홈페이지, 팩스, 전화, E-mail, 서면양식, 제휴사로부터의 제공, 생성정보 수집 툴을 통한 수집
      <br/>•	회사는 홈페이지 회원제에 따른 서비스 제공에 필요한 최소한의 정보만을 수집합니다.
      <br/>•	이용자는 개인정보의 수집 및 이용동의에 거부할 수 있으며, 다만 이 경우 회원가입/이용이 제한될 수 있습니다.
      <br/>•	회사는 이용자들의 개인정보를 "1. 개인정보의 수집목적 및 이용목적"에서 고지한 범위 내에서 사용하며, 이용자의 사전 동의 없이는 동 범위를 초과하여 이용하지 않습니다
      <br/>4. 개인정보의 보유 및 이용기간
      <br/>회사는 개인정보 수집 및 이용목적이 달성된 후에는 예외 없이 해당 정보를 지체 없이 파기합니다.
      <br/>•	가. 회원 가입 정보의 경우 : 회원 탈퇴 요청이 있거나 회원 자격을 상실한 때, 1년 이상 홈페이지 개인정보를 미 사용할 때
      <br/>•	나. 설문조사, 이벤트 등 일시적 목적을 위하여 수집한 경우 : 당해 설문조사, 이벤트 등이 종료한 때
      <br/>•	다. 사업을 폐지하는 경우
      <br/>다만, 관계법령의 규정에 의하여 보존할 필요가 있는 경우 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.
      <br/>[전자상거래 등에서의 소비자보호에 관한 법률]
      <br/>•	1) 보존 항목 : 계약 또는 청약철회 등에 관한 기록 (보존 기간 : 5년)
      <br/>•	2) 보존 항목 : 대금결제 및 재화 등의 공급에 관한 기록 (보존 기간 : 5년)
      <br/>•	3) 보존 항목 : 소비자의 불만 또는 분쟁처리에 관한 기록 (보존 기간 : 3년)
      <br/>•	4) 보존 항목 : 표시/광고에 관한 기록 (보존 기간 : 6개월)
      <br/>[관세법]
      <br/>•	1) 보존 항목 : 적하목록에 관한 자료 (보존 기간 : 2년)
      <br/>•	2) 보존 항목 : 수입신고필증 및 수입거래 관련 계약서 도는 이에 갈음하는 서류 (보존 기간 : 5년)
      <br/>•	3) 보존 항목 : 수출신고필증, 반송신고필증, 수출 물품.반송물품 가격결정에 관한 자료 및 수출거래, 반송거래 관련 계약서 또는 이에 갈음하는 서류 (보존 기간 : 3년)
      <br/>[통신비밀보호법]
      <br/>•	1) 보존 항목 : 로그인 기록 (보존 기간 : 3개월)
      <br/>5. 개인정보의 목적 외 사용 및 제3자에 대한 제공
      <br/>가. 회사는 인터넷 서비스화면을 통하여 공개된 정보를 제외하고는 이용자의 개인정보를 당사가 제공하는 인터넷 서비스 외의 용도로 사용하거나 이용자의 동의 없이 제3자에게 제공하지 않습니다. 다만, 다음 각호의 경우에는 예외로 합니다.
      <br/>•	① 이용자들이 사전에 공개에 동의한 경우
      <br/>•	② 서비스 제공에 따른 요금정산을 위하여 필요한 경우
      <br/>•	③ 홈페이지에 게시한 캐러벨 서비스 이용 약관이나 기타 회원 서비스 등 이용약관 또는 정책을 위반한 경우
      <br/>•	④ 캐러벨 서비스를 이용하여 타인에게 정신적, 물질적 피해를 줌으로써 그에 대한 법적인 조치를 취하기 위하여 개인정보를 공개해야 한다고 판단되는 충분한 근거가 있는 경우
      <br/>•	⑤ 기타 법에 의해 요구된다고 선의로 판단되는 경우 (ex. 관련법에 의거 적법한 절차에 의한 정부/수사기관의 요청이 있는 경우 등)
      <br/>•	⑥ 이용자의 서비스 이용에 따른 불만사항 및 문의사항(민원사항)의 처리를 위하여 “5. 개인정보 취급 위탁에 대한 안내”에서와 같이 전문업체에 해당 민원사항의 처리에 필요한 개인정보를 제공하는 경우
      <br/>나. 회사는 이용자들에게 보다 더 나은 서비스를 제공하기 위하여 개인정보를 제휴사에게 제공하거나 제휴사등과 공유할 수 있습니다. 개인정보를 제공하거나 공유할 경우에는 사전에 이용자들에게 제휴사등이 누구인지, 제공 또는 공유되는 개인정보항목이 무엇인지, 왜 그러한 개인정보가 제공되거나 공유되어야 하는지, 언제까지 어떻게 보호, 관리되는지에 대해 개별적으로 고지하여 동의를 구하는 절차를 거치게 되며, 이용자가 동의하지 않은 경우에는 제휴사등에게 제공하거나 제휴사등과 공유하지 않습니다. 또한 이용자가 개인정보의 제공에 동의하더라도 언제든지 그 동의를 철회할 수 있습니다.
      <br/>6. 이용자 자신의 개인정보 관리(열람, 정정, 삭제 등)에 관한 사항
      <br/>가. 이용자는 언제든지 캐러벨 홈페이지를 이용하여 등록되어 있는 자신의 개인정보를 조회하거나 수정할 수 있으며 동의철회 또는 탈퇴신청을 요청할 수도 있습니다.
      <br/>나. 이용자의 개인정보 조회 및 수정을 위해서는 캐러벨 회원관리 메뉴에서 아이디와 비밀번호를 사용하여 로그인(LOG-IN)하면 되는데, 아이디(ID), 성명을 제외한 모든 입력사항을 수정할 수 있습니다. 또한, 비밀번호를 잊어버린 경우에는 회원 로그인 메뉴 하단에 있는 "비밀번호 찾기"을 클릭하여 본인 확인에 필요한 사항을 입력하시면, 본인여부 확인 후 전자우편 또는 휴대전화번호를 통하여 비밀번호를 알려 드립니다.
      <br/>다. 이용자는 캐러벨 서비스의 회원 아이디(ID)에 대하여 동의철회 또는 탈퇴신청을 할 수 있으나 이로 인하여 모든 서비스를 이용할 수 없게 되는 것은 아니며, 단지 회원서비스 등 로그인이 요구되는 일부 서비스에 대해서만 그 기능을 이용할 수 없게 됩니다. 회사는 이용자의 요청에 의해 해지 또는 삭제된 개인정보는 “4. 개인정보의 보유 및 이용기간” 에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.
      <br/>라. 이용자 및 법정 대리인의 권리와 그 행사방법
      <br/>•	1) 개인정보 열람 요구
      <br/>•	2) 오류 등이 있을 경우 정정 요구
      <br/>•	3) 삭제 요구
      <br/>•	4) 처리정지 요구
      <br/>o	① 정보주체는 회사에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다.
      <br/>o	② 제 1항에 따른 권리 행사는 회사에 대해 서면, 전화, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 회사는 이에 대해 지체 없이 조치하겠습니다.
      <br/>o	③ 정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 회사는 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다.
      <br/>o	④ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제 11호 서식에 따른 위임장을 제출 하셔야 합니다.
      <br/>o	⑤ 정보주체는 개인정보보호법 등 관계법령을 위반하여 회사가 처리하고 있는 정보주체 본인이나 타인의 개인정보 및 사생활을 침해하여서는 아니됩니다.
      <br/>7. 쿠키(cookie)의 운영에 관한 사항
      <br/>이용자들에게 특화된 맞춤서비스를 제공하기 위해서 캐러벨은 이용자들의 정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다. 쿠키는 웹사이트를 운영하는데 이용되는 서버(HTTP)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다. 이용자가 캐러벨에 접속한 후 로그인(LOG-IN)하여 회원서비스 등의 서비스를 이용하기 위해서는 쿠키를 허용하셔야 합니다. 캐러벨는 이용자들에게 적합하고 보다 유용한 서비스를 제공하기 위해서 쿠키를 이용하여 아이디에 대한 정보를 찾아냅니다. 쿠키는 이용자의 컴퓨터는 식별하지만 이용자를 개인적으로 식별하지는 않습니다.
      <br/>쿠키를 이용하여 이용자들이 방문한 캐러벨의 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 이용자 규모 등을 파악하여 더욱 더 편리한 서비스를 만들어 제공할 수 있고 이용자에게 최적화된 정보를 제공할 수 있습니다. 이용자들은 쿠키에 대하여 사용여부를 선택할 수 있습니다. 웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용할 수도 있고, 쿠키가 저장될 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수도 있습니다. 다만, 쿠키의 저장을 거부할 경우에는 로그인이 필요한 캐러벨 일부 서비스는 이용할 수 없습니다.
      <br/>8. 개인정보관련 기술적-관리적 대책
      <br/>회사는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적 대책을 강구하고 있습니다.
      <br/>가. 이용자들의 개인정보는 비밀번호에 의해 철저히 보호되고 있습니다. 캐러벨 회원 아이디(ID)의 비밀번호는 본인만이 알고 있으며, 개인정보의 확인 및 변경도 비밀번호를 알고 있는 본인에 의해서만 가능합니다. 따라서 이용자께서는 비밀번호를 누구에게도 알려주시면 안됩니다. 이를 위해 회사에서는 기본적으로 PC에서의 사용을 마치신 후 온라인상에서 로그아웃(LOG-OUT)하시고 웹 브라우저를 종료하도록 권장합니다. 특히 다른 사람과 PC를 공유하여 사용하거나 공공장소(회사나 학교, 도서관, 인터넷 게임방 등)에서 이용한 경우에는 개인정보가 다른 사람에게 알려지는 것을 막기 위해 위와 같은 절차가 더욱 필요할 것입니다.
      <br/>나. 회사는 해킹이나 컴퓨터 바이러스 등에 의해 회원의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다. 개인정보의 훼손에 대비해서 자료를 수시로 백업하고 있고, 최신 백신프로그램을 이용하여 이용자들의 개인정보나 자료가 누출되거나 손상되지 않도록 방지하고 있으며, 암호알고리즘 등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다. 그리고 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있으며, 기타 시스템적으로 안정성을 확보하기 위한 가능한 모든 기술적 장치를 갖추려 노력하고 있습니다.
      <br/>다. 회사의 개인정보관련 취급 직원은 담당자에 한정시키고 있고 이를 위한 별도의 비밀번호를 부여하여 정기적으로 갱신하고 있으며, 담당자에 대한 수시 교육을 통하여 캐러벨 개인정보처리방침의 준수를 항상 강조하고 있습니다. 그리고 사내 통신비밀전담기구 등을 통하여 캐러벨 개인정보처리방침의 이행사항 및 담당자의 준수여부를 확인하여 문제가 발견될 경우 즉시 수정하고 바로 잡을 수 있도록 노력하고 있습니다.
      <br/>9. 개인정보관련 의견수렴 및 불만처리에 관한 사항
      <br/>가. 회사는 개인정보보호와 관련하여 회원 여러분들의 의견을 수렴하고 있으며 불만을 처리하기 위하여 모든 절차와 방법을 마련하고 있습니다. 이용자는 하단에 명시한 "11. 캐러벨 개인정보 관리책임자 및 담당자의 소속-성명 및 연락처"항을 참고하여 전화나 메일을 통하여 불만사항을 신고할 수 있고, 회사는 이용자들의 신고사항에 대하여 신속하고도 충분한 답변을 해 드릴 것입니다.
      <br/>나. 또는 정부에서 설치하여 운영중인 아래의 기관에 불만처리를 신청할 수 있습니다. ① 개인정보 침해신고센터 (http://privacy.kisa.or.kr, 전화 02-405-5118) ② 개인정보 분쟁조정위원회 (http://www.kopico.or.kr, 전화 02-405-5150) ③ 정보보호마크 인증위원회 (http://www.eprivacy.or.kr, 전화 02-550-9531 ~2) ④ 경찰청 (http://www.police.go.kr, 민원전화: 182)
      <br/>10. 캐러벨 개인정보 관리책임자 및 담당자의 소속 - 성명 및 연락처
      <br/>캐러벨은 이용자가 좋은 정보를 안전하게 이용할 수 있도록 최선을 다하고 있습니다. 개인정보를 보호하는데 있어 이용자께 고지한 사항들에 반하는 사고가 발생할 경우 개인정보관리 책임자가 책임을 집니다.
      <br/>가. 회원 개인정보와 관련한 아이디(ID)의 비밀번호에 대한 보안유지책임은 해당 이용자 자신에게 있습니다. 캐러벨에서는 비밀번호에 대해 어떠한 방법으로도 이용자에게 직접적으로 질문하는 경우는 없으므로 타인에게 비밀번호가 유출되지 않도록 각별히 주의하시기 바랍니다. 특히 "8. 개인정보관련 기술적-관리적 대책" 항에서 명시한 것과 같이 공공장소에서 온라인상에서 접속해 있을 경우에는 더욱 유의하셔야 합니다.
      <br/>나. 캐러벨은 개인정보에 대한 의견수렴 및 불만처리를 담당하는 개인정보 관리책임자 및 담당자를 지정하고 있고, 연락처는 아래와 같습니다.
      <br/>[개인정보 관리책임자] 성명: 임견우 / 소속부서: 총괄 / 직책: 대표이사 / 전화번호: 070-8657-2258 / E-mail: info@koreacredits.com
      <br/>토스 페이먼츠 개인정보 수집이용 동의
      <br/>토스페이먼츠(이하 회사라 함)는 "신용정보의 이용 및 보호에 관한 법률", "전자금융거래법", "전자상거래 등에서의 소비자보호에 관한 법률", "정보통신망 이용촉진 및 정보보호 등에 관한 법률" 및 "개인정보 보호법" 등 관련 법령을 준수하여, 이용자 권익 보호에 최선을 다하고 있습니다.
      <br/>1. 개인정보의 수집·이용 목적
      <br/>[전자금융거래 서비스 제공: 전자결제지급대행, 자체 간편결제]
      <br/>결제수단(신용카드 등)을 이용한 전자결제 서비스 제공
      <br/>전자결제 결과 조회 및 통보
      <br/>세금계산서 및 현금영수증 발행
      <br/>결제한 거래의 취소 또는 환불, 상품 배송 등 전자상거래 관련 서비스 제공
      <br/>[서비스 이용자 관리 및 민원대응]
      <br/>이용자 본인확인, 개인식별, 공지사항의 전달, 고객 불만 및 민원처리 등
      <br/>금융사고 조사, 분쟁해결, 민원처리, 법령상 의무이행
      <br/>새로운 정보와 고지사항의 안내
      <br/>※ 회사는 개인정보를 위의 목적으로 처리하며, 동의 받은 목적 이외의 용도로 처리하지 않습니다.
      <br/>2. 수집·이용하는 개인정보 항목
      <br/>[전자결제 서비스 이용자 및 구매정보]
      <br/>구매자명, 구매자ID, 상품 주문번호, 상품명, 구매자 휴대폰번호, 구매자 이메일, 결제금액
      <br/>[결제수단별 결제 정보-결제수단 별 위 이용자 구매정보가 포함됨]
      <br/>신용카드: 카드번호, 유효기간, 생년월일 혹은 사업자번호, 비밀번호 앞 2자리
      <br/>휴대폰: 휴대폰번호, 생년월일, 이동통신사
      <br/>가상계좌: 은행명, 입금자명, 생년월일 또는 사업자번호
      <br/>상품권: 상품권 발행사 아이디, 비밀번호 또는 상품권 번호
      <br/>현금영수증 발급정보 : 주민등록번호, 휴대폰번호, 현금영수증카드, 기타카드, 사업자번호 중 1개
      <br/>결제 정보 수신 및 결제대금예치서비스 : (선택) 이메일주소, 휴대폰번호
      <br/>[자체 간편결제 서비스]
      <br/>이름, 암호화된 CI, 성별, 생년월일, 휴대폰번호, 이메일, 내외국인 구분 정보, 통신사, 결제수단식별정보(가상카드번호), 은행명, 계좌번호, 구매자ID
      <br/>(단, 최초 카드 등록 시 카드번호, CVC, 비밀번호, 유효기간 정보를 추가 수집하며 이후 결제 시 결제수단식별정보(가상카드번호)로 결제가 이루어짐)
      <br/>3. 개인정보의 보유 및 이용기간
      <br/>원칙적으로 개인정보의 수집·이용 목적이 달성되면 지체 없이 파기합니다. 단, 관련 법령의 규정 또는 회사 내부 방침에 의하여 보존할 필요가 있는 경우 아래에서 정한 기간 동안 개인정보를 보유합니다.
      <br/>개인정보의 보유기간
      <br/>구분	보존 근거	보존기간
      <br/>부정 이용 방지를 위한 결제 이용에 관한 기록	부정 이용 방지 및 탐지	5년
      <br/>휴대폰 본인확인 서비스 이용에 관한 기록	정보통신 이용촉진 및 정보보호 등에 관한 법률	6개월
      <br/>계약 또는 청약철회 등에 관한 기록	전자상거래 등에서의 소비자보호에 관한 법률	5년
      <br/>1만원 이상 대금결제 및 재화 등의 공급에 관한 기록	전자상거래 등에서의 소비자보호에 관한 법률	5년
      <br/>1만원 미만 대금결제 및 재화 등의 공급에 관한 기록	전자상거래 등에서의 소비자보호에 관한 법률	1년
      <br/>소비자의 불만 또는 분쟁처리에 관한 기록	전자상거래 등에서의 소비자보호에 관한 법률	3년
      <br/>웹사이트 방문 또는 자체 간편결제 접속에 관한 기록	통신비밀보호법에 관한 법률	3개월
      <br/>4. 이용자는 회사의 개인정보 수집·이용 동의를 거부할 권리가 있습니다.
      <br/>다만, 개인정보의 수집·이용 동의를 거부할 경우 전자결제 서비스의 이용이 제한될 수 있음을 알려드립니다.
      <br/>개인정보 제3자 제공 동의
      <br/>토스페이먼츠(이하 회사라 함)는 전자금융거래 서비스의 제공을 위하여 개인정보를 제3자에게 제공하고 있으며 제공 내용은 이용 서비스 및 결제수단 별로 다음과 같습니다.
      <br/>1. 개인정보를 제공목적, 제공받는 자 및 항목
      <br/>개인정보의 제공목적, 제공받는 자 및 항목
      <br/>제공목적	제공받는 자	제공항목
      <br/>신용카드 결제	[신용카드사] KB카드, 비씨카드, 롯데카드, 삼성카드, NH농협카드, 현대카드, 신한카드, KEB하나카드, 우리카드	[거래정보] 카드번호, 고객명, 생년월일, 전화번호
      <br/>[비인증결제의 경우 거래정보] 카드번호, 유효기간, 생년월일, 카드 비밀번호 앞 2자리)
      <br/>※하나/농협카드는 비밀번호 4자리 그 외 카드사는 비밀번호 앞 2자리
      <br/>[은행] 경남은행, 광주은행, 국민은행, 기업은행, 농협은행, 대구은행, 부산은행, KDB산업은행, 새마을금고, 수협은행, 신한은행, 신협, 우리은행, 우체국, 전북은행, 제주은행, KEB하나은행, 씨티은행, SC제일은행, 산림조합중앙회, 케이뱅크, 카카오뱅크, 토스뱅크
      <br/>[VAN사] ㈜코밴, ㈜한국신용카드결제, (주)나이스정보통신
      <br/>해외카드 결제	VISA, MASTER, JCB, AMEX, Discover, Diners, 유니온페이 인터내셔널	카드번호, 유효기간, 전화번호, 회원ID, 카드 비밀번호
      <br/>간편 결제	㈜비바리퍼블리카, ㈜에스에스지닷컴, 롯데멤버스㈜, 삼성전자㈜, 엔에이치엔페이코㈜, (주)카카오페이, (주)네이버파이낸셜, (주)블루월넛, Apple Korea	전화번호, 상품명, 상품금액 [선택] 성함, ID, 이메일주소
      <br/>신용카드 ARS결제	㈜엘지유플러스	카드번호, 전화번호, 유효기간
      <br/>계좌이체 결제	금융결제원	상품명, 결제금액
      <br/>가상계좌 결제	[은행] 국민은행, 농협은행, 우리은행, 신한은행, KEB하나은행, 기업은행, 우체국, 부산은행, 경남은행, 대구은행, 수협은행	가상계좌번호
      <br/>휴대폰 결제	[통신사] SK텔레콤㈜, ㈜케이티, ㈜엘지유플러스	이통사정보, 휴대폰번호, 생년월일, 성별
      <br/>[중개사] ㈜다날, 갤럭시아커뮤니케이션즈㈜, 페이레터
      <br/>상품권 결제	㈜한국문화진흥, ㈜페이즈북앤라이프	'구매자ID, 비밀번호'(또는 상품권번호)
      <br/>자체간편결제	신용카드	[신용카드사] 신한카드, KB카드, 하나카드, BC카드, 삼성카드, NH농협카드, 현대카드, 롯데카드, 우리카드, 씨티카드	카드번호, CVC, 유효기간, 비밀번호 앞2자리, ※하나/NH농협카드 일 경우 비밀번호 4자리
      <br/>계좌이체	금융결제원,
      <br/>[은행] NH농협은행, 농협중앙회, KB은행, KDB산업은행, 우리은행, 신한은행, 기업은행, 경남은행, 광주은행, 대구은행, 부산은행, 새마을금고, 수협, 수협중앙회, 신협, 우체국, 전북은행, 제주은행, KEB하나은행, 씨티은행, SC제일은행, 카카오뱅크, K뱅크, 산림조합중앙회, 토스뱅크
      <br/>[증권사] 교보증권, 미래에셋대우, 삼성증권, 신한금융투자, 유진투자증권, 이베스트투자증권, 키움증권, 하이투자증권, 한국투자증권, 한화투자증권, 현대차증권, KB증권, NH투자증권, SK증권, 메리츠증권, 대신증권, DB금융투자, 토스증권	휴대전화번호, 이름, 계좌번호, 계좌 비밀번호
      <br/>글로벌 서비스	Boku, PayPal	주문번호, 구매 물품, 구매자ID, 이름, 이메일, 전화번호, 배송지정보, 구매금액, 국가코드
      <br/>현금영수증 서비스	국세청	[현금영수증 발급정보] 주민등록번호 혹은 사업자번호, 휴대폰번호, 현금영수증 등록카드번호
      <br/>오픈뱅킹 서비스
      <br/>(출금이체, 입금이체, 잔액조회) 제공	금융결제원	이름, 생년월일, 휴대전화번호, 금융기관명 및 계좌번호, 암호화된 동일인 식별정보(CI), 이메일
      <br/>※ 회사는 개인정보를 위의 목적으로 제3자 제공하며, 동의받은 목적 이외의 제3자에게 제공하지 처리하지 않습니다.
      <br/>2. 개인정보의 보유 및 이용기간
      <br/>개인정보는 원칙적으로 개인정보의 수집·이용 목적이 달성되면 지체없이 파기합니다. 단, 다른 법령에 특별한 규정이 있는 경우 관련 법령에서 정하는 기간동안 보유합니다.
      <br/>3. 이용자는 회사의 개인정보 제3자 제공에 대한 동의를 거부할 권리가 있습니다.
      <br/>다만, 개인정보의 제3자 제공 동의를 거부할 경우 전자결제지급대행 서비스의 이용이 제한될 수 있음을 알려드립니다.
      </div>
    );
  }


  export function Term3() {
    return (
      <div>
      전자금융거래 기본약관(이용자용)
      <br/>제1조 (목적)
      <br/>이 약관은 전자지급결제대행서비스 및 결제대금예치서비스를 제공하는 토스페이먼츠 주식회사(이하 '회사'라 합니다)와 이용자 사이의 전자금융거래에 관한 기본적인 사항을 정함으로써 전자금융거래의 안정성과 신뢰성을 확보함에 그 목적이 있습니다.
      <br/>제2조 (용어의 정의)
      <br/>이 약관에서 정하는 용어의 정의는 다음과 같습니다.
      <br/>① 전자금융거래'라 함은 회사가 전자적 장치를 통하여 전자지급결제대행서비스 및 결제대금예치서비스(이하 '전자금융거래 서비스'라고 합니다)를 제공하고, 이용자가 회사의 구성원과 직접 대면하거나 의사소통을 하지 아니하고 전산화된 방식으로 이를 이용하는 거래를 말합니다.
      <br/>② '전자지급결제대행 서비스'라 함은 전자적 방법으로 재화의 구입 또는 용역의 이용에 있어서 지급결제정보를 송신하거나 수신하는 것 또는 그 대가의 정산을 대행하거나 매개하는 서비스를 말합니다.
      <br/>③ ‘결제대금예치서비스'라 함은 이용자가 재화의 구입 또는 용역의 이용에 있어서 그 대가(이하 '결제대금'이라 한다)의 전부 또는 일부를 재화 또는 용역(이하 '재화 등'이라 합니다)을 공급받기 전에 미리 지급하는 경우, 회사가 이용자의 물품수령 또는 서비스 이용 확인 시점까지 결제대금을 예치하는 서비스를 말합니다.
      <br/>④ '이용자'라 함은 이 약관에 동의하고 회사가 제공하는 전자지급결제대행 서비스를 이용하는 자를 말합니다.
      <br/>⑤ '접근매체'라 함은 전자금융거래에 있어서 거래지시를 하거나 이용자 및 거래내용의 진실성과 정확성을 확보하기 위하여 사용되는 수단 또는 정보로서 전자식 카드 및 이에 준하는 전자적 정보(신용카드번호를 포함한다), '전자서명법'상의 인증서, 금융기관 또는 전자금융업자에 등록된 이용자번호, 이용자의 생체정보, 이상의 수단이나 정보를 사용하는데 필요한 비밀번호 등 전자금융거래법 제2조 제10호에서 정하고 있는 것을 말합니다.
      <br/>⑥ '거래지시'라 함은 이용자가 전자금융거래계약에 따라 금융기관 또는 전자금융업자에게 전자금융거래의 처리를 지시하는 것을 말합니다.
      <br/>⑦ '오류'라 함은 이용자의 고의 또는 과실 없이 전자금융거래가 전자금융거래계약 또는 이용자의 거래지시에 따라 이행되지 아니한 경우를 말합니다.
      <br/>제3조 (약관의 명시 및 변경)
      <br/>① 회사는 이용자가 전자지급결제대행 서비스를 이용하기 전에 이 약관을 게시하고 이용자가 이 약관의 중요한 내용을 확인할 수 있도록 합니다.
      <br/>② 회사는 이용자의 요청이 있는 경우 서면제공 방식 또는 전자문서의 전송방식에 의하여 본 약관의 사본을 이용자에게 교부합니다.
      <br/>③ 회사가 약관을 변경하는 때에는 그 시행일 1월 전에 변경되는 약관을 지급결제정보 입력화면 및 회사의 홈페이지에 게시함으로써 이용자에게 공지합니다.
      <br/>④ 이용자는 제3항의 규정에 따른 약관의 변경내용이 게시되거나 통지된 후부터 변경되는 약관의 시행일 전의 영업일까지 전자금융거래의 계약을 해지할 수 있습니다. 전단의 기간 안에 이용자가 약관의 변경내용에 대하여 이의를 제기하지 아니하는 경우에는 약관의 변경을 승인한 것으로 봅니다.
      <br/>제4조 (전자지급결제대행서비스의 종류)
      <br/>회사가 제공하는 전자지급결제대행서비스는 지급결제수단에 따라 다음과 같이 구별됩니다.
      <br/>① 신용카드결제대행서비스: 이용자가 결제대금의 지급을 위하여 제공한 지급결제수단이 신용카드인 경우로서, 회사가 전자결제시스템을 통한 신용카드 지불정보의 송,수신 및 결제대금의 정산을 대행하거나 매개하는 서비스를 말합니다.
      <br/>② 계좌이체대행서비스: 이용자가 결제대금을 회사의 전자결제시스템을 통하여 금융기관에 등록한 자신의 계좌에서 출금하여 원하는 계좌로 이체할 수 있는 실시간 송금 서비스를 말합니다.
      <br/>③ 가상계좌서비스: 이용자가 결제대금을 현금으로 결제하고자 경우 회사의 전자결제시스템을 통하여 자동으로 이용자만의 고유한 일회용 계좌의 발급을 통하여 결제대금의 지급이 이루어지는 서비스를 말합니다.
      <br/>④ 기타: 회사가 제공하는 서비스로서 지급결제수단의 종류에 따라 '휴대폰 결제대행서비스', 'KT전화(ARS,폰빌)결제대행서비스', '상품권결제대행서비스'등이 있습니다.
      <br/>제5조 (결제대금예치서비스의 내용)
      <br/>① 이용자(이용자의 동의가 있는 경우에는 재화 등을 공급받을 자를 포함합니다. 이하 본조에서 같습니다)는 재화 등을 공급받은 사실을 재화 등을 공급받은 날부터 3영업일 이내에 회사에 통보하여야 합니다.
      <br/>② 회사는 이용자로부터 재화 등을 공급받은 사실을 통보 받은 후 회사와 통신판매업자간 사이에서 정한 기일 내에 통신판매업자에게 결제대금을 지급합니다.
      <br/>③ 회사는 이용자가 재화 등을 공급받은 날부터 3영업일이 지나도록 정당한 사유의 제시 없이 그 공급받은 사실을 회사에 통보하지 아니하는 경우에는 이용자의 동의 없이 통신판매업자에게 결제대금을 지급할 수 있습니다.
      <br/>④ 회사는 통신판매업자에게 결제대금을 지급하기 전에 이용자에게 결제대금을 환급받을 사유가 발생한 경우에는 그 결제대금을 소비자에게 환급합니다.
      <br/>⑤ 회사는 이용자와의 결제대금예치서비스 이용과 관련된 구체적인 권리,의무를 정하기 위하여 본 약관과는 별도로 결제대금예치서비스이용약관을 제정할 수 있습니다.
      <br/>제6조 (접근매체의 선정, 관리 등)
      <br/>① 회사는 전자지급결제대행 서비스 제공 시 접근매체를 선정하여 이용자의 신원, 권한 및 거래지시의 내용 등을 확인할 수 있습니다.
      <br/>② 이용자는 접근매체를 제3자에게 대여하거나 사용을 위임하거나 양도 또는 담보 목적으로 제공할 수 없습니다.
      <br/>③ 이용자는 자신의 접근매체를 제3자에게 누설 또는 노출하거나 방치하여서는 안되며, 접근매체의 도용이나 위조 또는 변조를 방지하기 위하여 충분한 주의를 기울여야 합니다.
      <br/>④ 회사는 이용자로부터 접근매체의 분실이나 도난 등의 통지를 받은 때에는 그 때부터 제3자가 그 접근매체를 사용함으로 인하여 이용자에게 발생한 손해를 배상할 책임이 있습니다.
      <br/>제7조 (회사의 책임)
      <br/>① 접근매체의 위조나 변조로 발생한 사고로 인하여 이용자에게 발생한 손해에 대하여 배상책임이 있습니다. 다만, 이용자가 접근매체를 제3자에게 대여하거나 그 사용을 위임한 경우 또는 양도나 담보의 목적으로 제공한 경우, 회사가 보안강화를 위하여 전자금융거래 시 요구하는 추가적인 보안조치를 이용자가 정당한 사유 없이 거부하여 정보통신망에 침입하여 거짓이나 그 밖의 부정한 방법으로 획득한 접근매체의 이용으로 사고가 발생한 경우, 이용자가 제6조 제2항에 위반하거나 제3자가 권한 없이 이용자의 접근매체를 이용하여 전자금융거래를 할 수 있음을 알았거나 알 수 있었음에도 불구하고 이용자가 자신의 접근매체를 누설 또는 노출하거나 방치한 경우 그 책임의 전부 또는 일부를 이용자가 부담하게 할 수 있습니다.
      <br/>② 회사는 계약체결 또는 거래지시의 전자적 전송이나 처리과정에서 발생한 사고로 인하여 이용자에게 손해가 발생한 경우에는 그 손해를 배상할 책임이 있습니다. 다만, 본조 제1항 단서에 해당하거나 법인('중소기업기본법' 제2조 제2항에 의한 소기업을 제외한다)인 이용자에게 손해가 발생한 경우로서 회사가 사고를 방지하기 위하여 보안절차를 수립하고 이를 철저히 준수하는 등 합리적으로 요구되는 충분한 주의의무를 다한 경우 그 책임의 전부 또는 일부를 이용자가 부담하게 할 수 있습니다.
      <br/>③ 회사는 전자금융거래를 위한 전자적 장치 또는 ‘정보통신망 이용촉진 및 정보보호 등에 관한 법률’ 제2조제1항제1호에 따른 정보통신망에 침입하여 거짓이나 그 밖의 부정한 방법으로 획득한 접근매체의 이용으로 발생한 사고로 인하여 이용자에게 그 손해가 발생한 경우에는 그 손해를 배상할 책임이 있습니다.
      <br/>제8조 (거래내용의 확인)
      <br/>① 회사는 이용자와 미리 약정한 전자적 방법을 통하여 이용자의 거래내용(이용자의 '오류정정 요구사실 및 처리결과에 관한 사항'을 포함합니다)을 확인할 수 있도록 하며, 이용자의 요청이 있는 경우에는 요청을 받은 날로부터 2주 이내에 전자적양식, 모사전송, 서면 등의 방법으로 거래내용에 관한 서면을 교부합니다. 전자적 장치의 운영장애, 그 밖의 사유로 거래내용을 확인하게 할 수 없는 때에는 인터넷 등을 이용하여 즉시 그 사유를 알리고, 그 사유가 종료된 때부터 이용자가 거래내용을 확인할 수 있도록 하여야 합니다.
      <br/>② 회사가 이용자에게 제공하는 거래내용 중 거래계좌의 명칭 또는 번호, 거래의 종류 및 금액, 거래상대방을 나타내는 정보, 거래일자, 전자적 장치의 종류 및 전자적 장치를 식별할 수 있는 정보와 해당 전자금융거래와 관련한 전자적 장치의 접속기록은 5년간, 건당 거래금액이 1만원 이하인 소액 전자금융거래에 관한 기록, 전자지급수단 이용시 거래승인에 관한 기록, 이용자의 오류정정 요구사실 및 처리결과에 관한 사항은 1년간의 기간을 대상으로 하되, 회사가 가맹점에 대한 전자지급결제대행 서비스 제공의 대가로 수취한 수수료에 관한 사항은 제공하는 거래내용에서 제외됩니다.
      <br/>③ 이용자가 본조 제1항에서 정한 서면교부를 요청하고자 할 경우 다음의 주소 및 전화번호로 요청할 수 있습니다.
      <br/>주소 : 서울특별시 강남구 테헤란로 131 한국지식재산센터 15층 토스페이먼츠 주식회사
      <br/>이메일 주소 : support@tosspayments.com
      <br/>전화번호 : 1544-7772
      <br/>제9조 (오류의 정정 등)
      <br/>① 이용자는 전자지급결제대행 서비스를 이용함에 있어 오류가 있음을 안 때에는 회사에 대하여 그 정정을 요구할 수 있습니다.
      <br/>② 회사는 전항의 규정에 따른 오류의 정정요구를 받은 때에는 이를 즉시 조사하여 처리한 후 정정요구를 받은 날부터 2주 이내에 그 결과를 이용자에게 알려 드립니다.
      <br/>③ 이용자는 다음의 주소 및 전화번호로 본 조항상의 정정 요구를 할 수 있습니다.
      <br/>주소 : 서울특별시 강남구 테헤란로 131 한국지식재산센터 15층 토스페이먼츠 주식회사
      <br/>이메일 주소 : support@tosspayments.com
      <br/>전화번호: 1544 - 7772
      <br/>④ 회사는 스스로 전자금융거래에 오류가 있음을 안 때에는 이를 즉시 조사하여 처리한 후 오류가 있음을 안 날부터 2주 이내에 오류의 원인과 처리 결과를 이용자에게 알려 드립니다.
      <br/>제10조 (전자지급결제대행 서비스 이용 기록의 생성 및 보존)
      <br/>① 회사는 이용자가 전자지급결제대행 서비스 이용거래의 내용을 추적, 검색하거나 그 내용에 오류가 발생한 경우에 이를 확인하거나 정정할 수 있는 기록을 생성하여 보존합니다.
      <br/>② 전항의 규정에 따라 회사가 보존하여야 하는 기록의 종류 및 보존방법은 제8조 제2항에서 정한 바에 따릅니다.
      <br/>제11조 (거래지시의 철회 제한)
      <br/>① 이용자가 전자지급거래를 한 경우, 지급의 효력은 ‘전자금융거래법’ 제13조 각호의 규정에 따릅니다.
      <br/>② 이용자는 ‘전자금융거래법’ 제13조 각호의 규정에 따라 지급의 효력이 발생하기전까지 거래지시를 철회할 수 있습니다. 단, 금융기관, 이동통신사 등의 규정에 의거 거래지시의 철회가 제한될 수 있습니다.
      <br/>③ 전자지급수단별 거래지시의 철회 방법 및 제한 등은 다음 각호와 같습니다.
      <br/>신용카드결제, 계좌이체결제, 가상계좌결제 : 전자상거래 등에서의 소비자보호에 관한 법률 등 관련 법령에서 정한 바에 따라 재화의 구입 또는 용역의 이용 거래에서의 청약의 철회 등을 한 경우에 한하여 철회가 가능합니다.
      <br/>휴대폰결제, KT전화(ARS,폰빌)결제: 전 가호의 방법에 따른 청약철회 요청건 중 이동통신사의 규정에 의거 결제일 해당월 말일까지 발생한 거래건에 한하여 철회가 가능합니다.
      <br/>상품권결제: 전 가호의 방법에 따른 청약철회 요청 건 중 시스템 장애 등으로 정상적인 서비스를 이용하지 못한 경우에 한하여 결제일로부터 10일 이내 청약 철회가 가능합니다.
      <br/>제12조 (추심이체의 출금 동의 및 철회)
      <br/>① 회사는 이용자의 요청이 있는 경우 이용자의 계좌가 개설되어 있는 금융회사 등이 추심이체를 실행할 수 있도록 금융회사 등을 대신하여 전자금융거래법령 등 관련 법령에 따른 방법으로 출금에 대한 동의를 진행합니다.
      <br/>② 회사는 전 항에 따른 이용자의 동의 사항을 추심 이체를 실행하는 해당 금융회사 등에 제출합니다.
      <br/>③ 이용자는 이용자의 계좌의 원장에 출금기록이 끝나기 전까지 회사 또는 해당 금융회사 등에 제1항의 규정에 따른 동의의 철회를 요청할 수 있습니다.
      <br/>④ 전 항에도 불구하고 회사 또는 금융회사 등은 대량으로 처리하는 거래 또는 예약에 따른 거래 등의 경우에는 미리 이용자와 정한 약정에 따라 동의의 철회시기를 달리 정할 수 있습니다.
      <br/>⑤ 이용자가 제3항에 따라 출금 동의 철회를 요청한 경우, 이용자는 출금 동의 철회 요청 이후 발생한 출금에 대해서 제14조 제1항의 담당자에게 이의를 제기할 수 있습니다. 다만, 본 조항은 동의 철회요청 이전에 발생한 출금에 대해서는 적용되지 않습니다.
      <br/>제13조 (전자금융거래정보의 제공금지)
      <br/>회사는 전자지급결제대행서비스 및 결제대금예치서비스를 제공함에 있어서 취득한 이용자의 인적사항, 이용자의 계좌, 접근매체 및 전자금융거래의 내용과 실적에 관한 정보 또는 자료를 이용자의 동의를 얻지 아니하고 제3자에게 제공,누설하거나 업무상 목적 외에 사용하지 아니합니다. 단, ‘금융실명 거래 및 비밀 보장에 관한 법률’ 제4조 제1항 단서의 규정에 따른 경우 그 밖의 다른 법률에서 정하는 바에 따른 경우에는 그러하지 아니합니다.
      <br/>제14조 (분쟁처리 및 분쟁조정)
      <br/>① 이용자는 다음의 분쟁처리 책임자 및 담당자에 대하여 전자지급결제대행 서비스 및 결제대금예치서비스 이용과 관련한 의견 및 불만의 제기, 손해배상의 청구 등의 분쟁처리를 요구할 수 있습니다.
      <br/>담당자 : 토스페이먼츠 RM(리스크)팀
      <br/>연락처 : 전화번호 1544-7772, 팩스 02-6919-2354
      <br/>이메일 : rm@tosspayments.com
      <br/>② 이용자가 회사에 대하여 분쟁처리를 신청한 경우에는 회사는 15일 이내에 이에 대한 조사 또는 처리 결과를 이용자에게 안내합니다.
      <br/>③ 이용자는 '금융위원회의 설치 등에 관한 법률' 제51조의 규정에 따른 금융감독원의 금융분쟁조정위원회나 '소비자기본법' 제35조 제1항의 규정에 따른 소비자원에 회사의 전자지급결제대행서비스 및 결제대금예치서비스의 이용과 관련한 분쟁조정을 신청할 수 있습니다.
      <br/>제15조 (회사의 안정성 확보 의무)
      <br/>회사는 전자금융거래의 안전성과 신뢰성을 확보할 수 있도록 전자금융거래의 종류별로 전자적 전송이나 처리를 위한 인력, 시설, 전자적 장치 등의 정보기술부문 및 전자금융업무에 관하여 금융위원회가 정하는 기준을 준수합니다.
      <br/>제16조 (이용시간)
      <br/>① 회사는 이용자에게 연중무휴 1일 24시간 전자금융거래 서비스를 제공함을 원칙으로 합니다. 단, 금융기관 기타 결제수단 발행업자의 사정에 따라 변경될 수 있습니다.
      <br/>② 회사는 정보통신설비의 보수, 점검 기타 기술상의 필요나 금융기관 기타 결제수단 발행업자의 사정에 의하여 서비스 중단이 불가피한 경우, 서비스 중단 3일 전까지 게시가능한 전자적 수단을 통하여 서비스 중단 사실을 게시한 후 서비스를 일시 중단할 수 있습니다. 다만, 시스템 장애보국, 긴급한 프로그램 보수, 외부요인 등 불가피한 경우에는 사전 게시없이 서비스를 중단할 수 있습니다.
      <br/>제17조 (약관외 준칙 및 관할)
      <br/>① 이 약관에서 정하지 아니한 사항에 대하여는 전자금융거래법, 전자상거래 등에서의 소비자 보호에 관한 법률, 통신판매에 관한 법률, 여신전문금융업법 등 소비자보호 관련 법령에서 정한 바에 따릅니다.
      <br/>② 회사와 이용자간에 발생한 분쟁에 관한 관할은 민사소송법에서 정한 바에 따릅니다.
    </div>
    );
  }


  export function Term4() {
    return (
      <div>
        <br/>제15조(반품, 환급 등)
        <br/>• 1.배송대행 서비스
        <br/>o 가. "캐러벨 주소”에 회원이 주문한 물품이 도착하여 한국으로 발송되기 전에 회원의 당해 물품에 대한 배송대행계약의 중도해지요청(반품 등)이 
        <br/>"캐러벨"에 도달한 경우 당해 물품은 회원의 요청에 따라 반송하며 이를 위해 소요되는 일체의 비용은 회원이 부담합니다.
        <br/>o 나. "캐러벨 주소”에 회원이 주문한 물품이 도착되어 회원이 결제한 후 한국으로 발송된 시점 이후에 전항의 중도해지요청이 접수될 경우 원 배송대행계약의 효력은 계속 존재하여 "캐러벨"이 당해 물품을 국내의 수취처까지 배송완료함으로써 원 배송대행계약에 대한 이행을 완료하게 되며, 중도해지요청에 대해서는 비용일체를 회원이 부담하는 것을 전제조건으로 하여 반송을 대행해 줄 수 있습니다.
        <br/>o 다. 본조 제1항 또는 제2항의 경우 회원이 지정한 수취처 이외의 제3의 지역으로 송부할 것을 요청하거나 해당 판매자에게 반송하였을 때 수취자가 물품을 수취거부 하거나 반품을 불인정하는 경우는 당해 물품을 회원에게 송부하고 그에 따른 일체의 비용은 회원이 부담합니다.
        <br/>• 2.수입대행 서비스
        <br/>o 가. "캐러벨" 사이트에서 회원이 수입대행을 신청한 재화가 품절 등의 사유로 인도를 할 수 없을 때에는 그 사유를 이용자에게 통지하고 사전에 재화 대금을 받은 경우에는 대금을 받은 날로부터 2 영업일 이내에, 그렇지 않은 경우에는 그 사유발생일로부터 2 영업일 이내에 계약해지 및 환급절차를 취합니다.
        <br/>o 나. 회원의 반품 신청에 의한 반품, 환급 규정은 다음과 같으며, 회원은 물품이 배송된 날로부터 10일 이내에 반품의사를 밝혀야 합니다.
        <br/> ① 단순변심에 의한 반품
        <br/>서비스 이행구간별로 해외 인터넷쇼핑몰 등에서 회원을 대신하여 구매하는데 제공된 수입대행 수수료, 국제/국내운송료, 수입제세금, 기타 제반비용 등에 대해 회원이 부담하는 것을 조건으로 반품을 받을 수 있습니다.
        <br/> ② 상품 상이, 결실, 파손, 손상, 오염에 의한 반품
        <br/>"캐러벨"의 해외 주소로부터 국내 회원의 수취전까지 국제복합운송 중 수입대행 상품의 분실,파손, 손상, 오배송, 오염 등이 발생할 경우 이에 대한 책임은 "캐러벨"에 있으며 상품 대금을 포함한 총결제금액 전액을 회원에게 직접 배상합니다. 또한, 상기된 하자로 인한 반품이 "캐러벨"의 해외주소에서 수취전 해외쇼핑몰의 귀책사유 임이 객관적으로 증명될 경우 "캐러벨"은 회원에게 반품을 받은 후, "캐러벨"이 회원을 대신하여 해외쇼핑몰에 클레임을 제기할 수 있습니다.
        <br/>o 다. 반품접수된 상품을 반환받는 경우 3영업일 이내에 이미 지급받은 상품 등의 대금을 환급합니다. (다만, 환급액에서 수입대행업체의 귀책사유가 없어 환급하지 않아도 될 비용항목을 제외 할 수 있습니다.)
      </div>
    );
  }

  export function Term5() {
    return(
      <div>
      제1조(목적)
      <br/>주식회사 한국외상결제(이후 “회사”, “당사”)와 이용자 간의 계약에 따른 양자의 권리•의무 및 책임사항을 규정함을 목적으로 합니다.
      <br/>제2조 (정의)
      <br/>•	1."이용자"라 함은 회사가 제공하는 서비스를 통하여 계약 의사를 회사에 전달하고 본 계약을 체결하는 자를 말합니다.
      <br/>•	2.“최종고객”이라 이용자가 유선 혹은 무선상으로 회사에 전달한 계약 내용에서 물품의 최종 수령인을 말합니다. 
      <br/>•	본 계약은 수입대행형 거래계약 유형에 해당하는 서비스로서, 회원이 해외 인터넷 쇼핑몰에서 구매하고자 하는 물품을 구체적으로 명시하여 구매를 요청한 것에 대하여 이용자가 물품구매 및 배송에 따르는 총 비용을 결제시 회사가 해외사용이 가능한 기명식 법인신용카드 및 해외송금(Wire Transfer) 등을 통해 해당 상품을 판매하는 해외 인터넷 쇼핑몰 등에서 이용자를 대신해 구매 및 결제를 하고 구매대행 된 상품이 회사가 제공하는 해외의 중간 배송처에 입고된 이후 국제운송 및 수입통관을 거쳐 이용자가 지정하는 국내 수취처까지 배송하여 이를 이용자가 수령할 수 있도록 한다.
      <br/>•	본 계약은 회사가 제공하는 다른 모든 서비스와 별개의 계약으로서,  본 계약을 체결할 때 본 계약의 당사자는 본 계약서의 계약사항에 따라 규정된 권리, 의무만을 가집니다.
      <br/>제3조 (가격산정)
      <br/>•	본 계약의 적정가격은 회사가 판단하여 이용자에게 전달합니다.
      <br/>•	계약 상의 의무에 따른 업무 수행 중 불가피하다고 판단될 경우 회사는 이용자에게 추가 비용을 청구할 수 있으며 이용자가 그에 해당하는 금액을 회사에 지급하기 전까지 업무 수행을 중단할 수 있습니다.
      <br/>제4조(서비스의 중단)
      <br/>•	1. 회사는 컴퓨터 등 정보통신설비의 보수, 점검, 교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 계약상의 업무를 일시적으로 중단할 수 있습니다.
      <br/>•	2. 회사는 제1항의 사유로 서비스의 제공이 일시적 중단됨으로 인하여 이용자가 입은 손해에 대하여 배상합니다. 단,  당사가 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.
      <br/>•	3.사업종목의 전환, 사업의 포기, 업체간의 통합 등의 이유로 서비스를 제공할 수 없게 되는 경우에는 회사는 제8조에 정한 방법으로 이용자에게 통지하고 이용자가 이로 인하여 손해를 입은 경우에는 이용자가 지불한 계약 금액 이내의 범위에서 이용자에게 보상합니다.
      <br/>제5조(계약의 성립)
      <br/>•	1. 본 계약이 체결되는 시점은 회사가 계약의 의사를 전달받고 이에 응하여 계약의 적정가격을 이용자에게 통보한 후 이용자가 그에 해당하는 금액을 회사의 은행계좌에 입금된 시점으로 합니다.
      <br/>•	2. 다음 각 호에 해당하는 경우 회사가 판단하여 계약을 수락하기 전까지 어떠한 경우에도 계약이 체결되지 않으며. 그 사실을 뒤늦게 인지한 경우에 계약은 무효로 할 수 있습니다.
      <br/>o	가. 신청내용에 허위, 기재누락, 오기가 있는 경우
      <br/>o	나. 관련법령 및 동 약관에서 금지하는 재화에 대해 수입대행 또는 배송대행을 요청하는 경우
      <br/>o	다. 기타 계약을 승낙하는 것에 기술상 현저히 지장이 있다고 판단하는 경우
      <br/>•	3. 회사는 최종고객의 개인정보를 수령하는 과정에 따르는 모든 의무를 이용자가 이미 수행하였다고 간주하고 업무를 진행하며, 그렇지 않은 경우 어떠한 책임도 지지 않습니다. 
      <br/>제6조(서비스 대상물품 및 금지상품)
      <br/>회사는 아래의 경우 고객의 동의를 구한 후 한국으로 발송하거나 해당 발송자에게로 반송할 수 있습니다. 이용자의 귀책사유로 인해 이용자의 소재가 불명확하여 상당한 기간 내에 이를 보완할 것을 이용자에게 요구하였음에도 그 기간 내에 보완하지 않은 경우 이용자는 당해 물품을 발송인에게 반송하고 이를 이용자에게 통보하며, 소요비용은 이용자로부터 정산하거나 회사의 임의대로 물품을 처리하여 충당할 수 있습니다.
      <br/>단, 판매용 목적으로 구매하여 수입하는 경우는 사업자통관을 진행 할 경우 서비스 제공됩니다.
      <br/>•	가. 동물, 금•은괴, 화폐, 의약품, 무기류, 인체의 일부, 포르노그래피, 정밀금속, 석재류, 냉장보관물품, 냉동 또는 냉장을 요하는 물품 및 폭발물, 가연성 위험물 등
      <br/>•	나. 관세법에 의한 수입, 수출 불가 / 항공,해상운송 불가 품목일 경우
      <br/>o	(1) 현금 및 수표, 어음, 주식 그 밖의 유가증권류
      <br/>o	(2) 신용카드, 현금카드 등의 카드류
      <br/>o	(3) 금융기관의 예적금 통장 또는 예적금 인출용 카드
      <br/>o	(4) 수신처가 당사임을 용이하게 판별할 수 있는 상호나 기타 문구가 기재되지 않은 것
      <br/>o	(5) 서신 또는 현행법상 서신으로 정의된 통신 수단
      <br/>o	(6) 살아 있는 동식물, 죽은 동물 또는 박제 동물
      <br/>o	(7) 인체 또는 인체의 일부, 사체, 유골, 위패
      <br/>o	(8) 음식물과 그 밖에 변질 또는 부패하기 쉬운 것
      <br/>o	(9) 각성제, 대마, 마약, 향정신성 약품 및 기타 금지 약물,식약청고시수입금지품목등
      <br/>o	(10) 총포, 도검, 무기, 병기, 화약류, 폭발물, 독극물
      <br/>o	(11) 의약품, 의료기기
      <br/>o	(12) 아동 포르노, 성인 비디오, 그 밖의 외설물
      <br/>o	(13) 부정한 방법.수단으로 수득된 물
      <br/>o	(14) 경유국가를 포함한 수출입국가, 주, 지방자치단체의 법령에 따라 수송, 수출 및 수입 등이 금지되거나 제한되고 있는 것
      <br/>o	(15) 그 밖에 운송 사업자의 운송 약관상 취급이 인정되지 않는 것
      <br/>o	(16) 그 밖에한국관세청또는 수출국의 관세청 및 해당국가의 법률로정한 수출금지품목,당사가 부적당하다고 인정한 것
      <br/>•	다. 송장(Invoice) 부실기재, 포장불량 등으로 본 약관에 따른 서비스를 제공하기에 적절하지 않다고 "트레이더스"가 판단하는 경우
      <br/>•	라. 기타 관련법령에 의하여 수출입이 제한되는 물품을 대상으로 한 경우
      <br/>제7조(지급방법)
      <br/>•	1. 본 계약의 지급방법은 이용자가 합법적, 정상적으로 가능한 범위 내에서 회사가 지정한 당사 명의의 은행계좌로 입금하는 것으로 합니다.
      <br/>제8조(수신확인통지, 배송신청 변경 및 취소)
      <br/>•	1."트레이더스"는 이용자의 운송 및 수입대행신청이 있는 경우 이용자에게 수신확인통지를 합니다.
      <br/>•	2.수신확인통지를 받은 이용자는 의사표시의 불일치 등이 있는 경우에 수신확인통지를 받은 후 즉시 배송 신청 변경 및 취소를 요청할 수 있습니다.
      <br/>•	3."트레이더스" 주소에 도달한 물품이 제10조 각호에 해당하는 경우에는 당해 물품에 대한 배송 계약을 해지할 수 있으며, 이 경우 물품의 반송 등 사후조치를 위해 소요되는 일체의 비용은 이용자의 부담으로 합니다.
      <br/>제9조(국제운송 및 통관)
      <br/>•	1.국제운송
      <br/>o	가. 회사는 국제복합운송계약을 체결한 운송 및 통관업무제휴사를 통하여 국내(한국)까지의 항공및 해상운송, 수입통관, 회원이 지정한 수취처까지의 국내배송의 용역을 제공합니다.
      <br/>o	나. 본 조에 의거 보상되는 물품가격의 기준은 고객이 회사에 전달한 신청서 상에 기재한 금액을 기준으로 보상하며, 국내 택배보상 불가 품목의 경우 보상에서 제외됩니다.
      <br/>•	2.통관
      <br/>o	가. 회사는 개인이 자가사용 목적으로 수입하는 개인수입통관원칙에 의거, 최종고객을 납세의무자로하고 수입요건을 구비하여 운송 및 통관업무제휴사를 통하여 통관절차를 수행합니다.
      <br/>o	나. 이 때 발생하는 관세 및 수입부가세 등 제세금은 운송 및 통관업무제휴사가 납세의무자를 대신하여 대한민국 세관에 대납하고 회사는 이용자가 기 결제한 관세 및 수입부가세 등 제세금을 운송 및 통관업무제휴사와 정산하는 절차를 거칩니다.
      <br/>수입 물품의 과세가격이 $150 이하인 경우만 면세, $150 이 초과된 경우에는 과세처리가 되며, 해외공급자가 동일하거나 다른 경우에도 합산하여 과세됩니다.
      <br/>o	아래와 같은 목록통관배제물품은 일반수입신고를 하여야 합니다.
      <br/>목록통관 배제대상물품
      <br/>	1. 국제운송
      <br/>	2. 한약재
      <br/>	3. 야생동물 관련 제품
      <br/>	4. 농림축수산물등 검역대상물품
      <br/>	5. 건강기능식품
      <br/>	6. 지식재산권 위반 의심물품
      <br/>	7. 식품류,과자류
      <br/>	8. 화장품(기능성화장품, 태반함유화장품, 스테로이드제 함유화장품 및 성분미상 등 유해화장품에 한함)
      <br/>	9. 전자상거래물품 등의 특별통관절차에 관한 고시 제3-3조 제3항에 따라 특별통관대상업체로 지정되지 아니한 전자상거래업체가 수입하는 물품
      <br/>	10. 통관목록 중 품명,규격,수량,가격 등이 부정확하게 기재된 물품
      <br/>	11. 그 밖에 법 제226조에 따른 세관장확인대상물품 등 목록통관이 타당 하지 아니하다고 세관장이 인정하는 물품
      <br/>제10조 (검품)
      <br/>•	1.당사는 상품을 개봉하여 그 내용물을 검품할 수 있습니다. 단, 당사는 반드시 검품해야 할 의무는 없으며, 검품 결과가 해당 상품의 품질, 하자 유무, 진품 여부 및 발송지, 경유지 및 목적지 국가의 관계 법령에 위반되지 않음을 보증하는 것은 아닙니다.
      <br/>•	2.전항의 검품 시에 범죄를 통한 수익의 이전 방지에 관한 법률과 그 밖의 법령에 위반되거나 위반할 우려가 있는 물품을 발견한 경우, 당사는 경찰이나 그 밖의 관련 관공서에 통보하여 상품을 제출하는 등의 조치를 취할 수 있습니다.
      <br/>•	3.상품의 검품 및 기타 본 조항에서 규정한 조치로 인해 회원에게 손해가 발생한 경우 회사의 고의 또는 중과실로 인한 경우를 제외하고 당사는 책임을 지지 않습니다.
      <br/>제11조 (본 계약의 중단, 정지)
      <br/>•	1. 당사는 아래에 규정한 사유 또는 그와 같은 우려가 있는 경우에는 본 계약을 이미 체결한 상태라도 사전 통지 없이 본 계약을 파기 할 수 있습니다.
      <br/>o	(1) 상품이 제6조에서 규정한 상품일 때
      <br/>o	(2) 최종고객의 소재 또는 상품의 발송처 주소를 확인할 수 없을 때
      <br/>o	(3) 대금상환등 회사에서 수령할 수 없는 지불수단으로 구입된 상품일 때
      <br/>o	(4) 최종고객이 상품 수령을 거절한 때
      <br/>o	(5) 운송 사업자가 상품을 운송하지 않을 때
      <br/>o	(6) 수출입국 세관에서 금지한 때
      <br/>o	(7) 회원이 본 서비스의 이용 요금을 지불하지 않을 때
      <br/>o	(8) 회원이 본 약관을 위반한 때
      <br/>o	(9) 기타 당사가 부적당하다고 판단한 때
      <br/>•	2. 당사는 다음 중 어느 하나의 사유에 해당하는 경우, 사전 통지 없이 본 계약을 파기할 수 있으며, 그에 따른 어떠한 책임을 지지 않습니다.
      <br/>o	(1) 본 서비스의 제공을 위한 장치, 시스템의 보수 점검, 업데이트를 실시하는 경우
      <br/>o	(2) 화재, 정전, 천재지변, 시스템 장애 등으로 본 서비스를 제공하기 어려운 경우
      <br/>o	(3) 필요한 전기통신 사업자의 서비스가 제공되지 않는 경우
      <br/>o	(4) 기타 당사가 본 서비스의 일시 중단 또는 정지가 필요하다고 판단한 경우
      <br/>•	3. 당사는 본 계약의 파기로 인하여 이용자에게 어떤 손해가 발생하더라도 전혀 책임을 지지 않습니다.
      <br/>제12조 (서비스제한,금지 행위)
      <br/>•	1. 회사는 업무 진행 중 다음 각 호에 해당하는 경우에는 고객의 동의를 구한 후 해당 발송자에게로 반송할 수 있습니다. 이때 반송에 소요되는 비용은 이용자로부터 정산하거나, 회사의임의대로 물품을 처리하여 충당할 수 있습니다.
      <br/>o	(1) 본 서비스를 부정한 목적으로 이용하는 행위
      <br/>o	(2) 당사와 기타 제3자의 상표권, 저작권, 의장권, 특허권 등의 지적재산권, 초상권, 초상사용권과 기타 권리를 침해하는 행위
      <br/>o	(3) 사기 등의 범죄와 연관된 행위
      <br/>o	(4) 제3자로 위장하여 본 서비스를 이용하는 행위
      <br/>o	(5) 당사와 기타 제3자의 설비를 불법으로 이용하거나 그 운영에 지장을 주는 행위
      <br/>o	(6) 법령, 본 약관, 개별 약관 또는 공공의 질서와 선량한 풍속에 위반되는 행위
      <br/>o	(7) 본 서비스의 운영을 방해하는 행위
      <br/>o	(8) 수입, 수출 불가 / 항공운송 불가 품목일 경우, 송장(Invoice) 부실기재, 포장불량 등으로 본 약관에 따른 서비스를 제공하기에 적절하지 않다고 회사가 판단하는 경우
      <br/>o	(9) 기타관계법령에의해 수출입이 제한되거나 당사가 부적당하다고 판단하는 행위
      <br/>•	2. 이용자가 본 계약의 계약사항을 위반함으로써 당사에 어떠한 손해가 발생한 경우, 당사는 이용자에게 손해 배상을 청구할 수 있습니다.
      <br/>제13조 (회사와 이용자의 의무)
      <br/>•	1.회사는 법령과 이 계약이 금지하거나 공서양속에 반하는 행위를 하지 않으며 본 계약이 정하는 사항에 따라 재화•용역을 제공하는데 최선을 다하여야 합니다.
      <br/>•	2.회사는 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록 이용자의 개인정보(신용정보 포함) 보호를 위한 보안 시스템을 갖추어야 합니다.
      <br/>•	3.회원은 다음 각 호의 행위를 하여서는 안됩니다.
      <br/>o	a. 신청 또는 변경시 허위 내용의 등록
      <br/>o	b. 타인의 정보 도용
      <br/>o	c. “트레이더스”에
      <br/>제14조 (분쟁해결)
      <br/>•	회사는 이용자가 제기하는 정당한 의견이나 불만을 접수하여 그 피해를 보상처리하기 위하여 피해보상처리기구를 설치, 운영합니다. 회사는 이용자로부터 제출되는 불만사항 및 의견을 우선적으로 처리하도록 최선을 다합니다.
      <br/>제16조 (관할법원 및 준거법)
      <br/>•	1.본 <br/>약관 및 개별 약관의 준거법은 대한민국법으로 합니다.
      <br/>•	2.회사와 회원 간에 발생한 소송은 제소 당시의 이용자의 주소에 의하고, 주소가 없는 경우에는 거소를 관할하는 지방법원의 전속관할로 합니다. 다만, 제소 당시 이용자의 주소 또는 거소가 분명하지 않거나 외국 거주자의 경우에는 민사소송법상의 관할법원에 제기합니다.
      </div>
    )
  }