import { useState } from "react";
import { signInWithEmailAndPassword,
    updatePassword ,RecaptchaVerifier, signInWithPhoneNumber, EmailAuthProvider, reauthenticateWithCredential} from "firebase/auth";
import { auth } from "../firebase";

 export function FindAccount() {
    const [phoneNumber, setPhoneNumber] = useState("");
    const [Code, setCode] = useState("");

    const [email, setEmail] = useState("");
    const [passwordorigin, setPasswordorigin] = useState("");

    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");

    const phoneauth = () => {
        
    }

    const findAccount = () => {
        let a = "+82" + phoneNumber.substring(1, phoneNumber.length);
        
    }
    

    const handleSubmitPhoneNumber = () => {
      const a = "+82" + phoneNumber.substring(1);
        const appVerifier = new RecaptchaVerifier('recaptcha-container', {
            size: "invisible", // this property is important otherwise the captcha will be displayed on the screen
        }, auth);
        signInWithPhoneNumber(auth, a, appVerifier)
        .then((confirmationResult) => {
            window.confirmationResult = confirmationResult;
            
        
      }).catch((error) => {
        
      });
    };
  
    const handleSubmitCode = () => {
      let confirmationResult = window.confirmationResult;
      confirmationResult.confirm(Code)
        .then(result => {
          const user = result.user;
          setPhoneNumber("");
            setCode("");
        })
        .catch(err => {
            
        });
  
    };

    const changepassword = () => {
        let credential = EmailAuthProvider.credential(email, passwordorigin);
        
        if(auth.currentUser){
            reauthenticateWithCredential(auth.currentUser, credential).then(() => {
                updatePassword(auth.currentUser, password).then(() => {
                    
                }).catch((error) => {
                    
                });
            }).catch((error) => {
                
            });
        } else{
            signInWithEmailAndPassword(auth, email, passwordorigin).then(() => {
                updatePassword(auth.currentUser, password).then(() => {
                    
                }).catch((error) => {
                    
                });
            }).catch((error) => {
                
            });
        }
    }
  

    return (
        <div className="Coupon-background">
        <div id="recaptcha-container"></div>
        <div className='login-title'>
            <h1 className='contents-text-title font18'>전화번호 로그인</h1>
        </div>
            <div className="login-title">전화번호</div>
            <div className="couponcode-div">
                <input className="couponcode-input" type="tel" placeholder="010-0000-0000" 
                value={phoneNumber}
                onChange={e => setPhoneNumber(e.target.value)}/>
                <button className="couponcode-button" onClick={handleSubmitPhoneNumber}>인증</button>
            </div>
            <div className="couponcode-div margin-2">
                <input className="code-input2" type="tel" placeholder="인증번호를 입력해주세요" 
                value={Code}
                onChange={e => setCode(e.target.value)}/>
            </div>
            <div className="login-div">
                <button className="login-button button-margin" onClick={handleSubmitCode}>로그인</button>
            </div>

            <div className='login-title button-margin'>
                <h1 className='contents-text-title font18'>비밀번호 변경</h1>
            </div>
            <div className="login-title">이메일</div>
            <div className="couponcode-div">
                <input className="phone-input" type="tel" placeholder="email@domain.com" 
                value={email}
                onChange={e => setEmail(e.target.value)}/>
            </div>
            <div className="login-title">비밀번호</div>
            <div className="couponcode-div">
                <input className="phone-input" type="password" placeholder="영문,숫자,특수기호 포함 8자~30자" 
                value={passwordorigin}
                onChange={e => setPasswordorigin(e.target.value)}/>
            </div>
            <div className="login-title">새 비밀번호</div>
            <div className="couponcode-div">
                <input className="phone-input" type="password" placeholder="영문,숫자,특수기호 포함 8자~30자" 
                value={password}
                onChange={e => setPassword(e.target.value)}/>
            </div>
            <div className="login-title">새 비밀번호확인</div>
            <div className="couponcode-div">
                <input className="phone-input" type="password" placeholder="영문,숫자,특수기호 포함 8자~30자" 
                value={password2}
                onChange={e => setPassword2(e.target.value)}/>
            </div>
            <div className="login-div">
                <button onClick={changepassword} className="login-button button-margin">비밀번호 변경</button>
            </div>

        </div>
    );
  }

  export function FindPassword() {
    return (
        <div className="Coupon-background">
            <div className='Billing-title'>
                <h1 className='contents-text-title font18'>비밀번호 찾기</h1>
            </div>
            
        </div>
    );
  }
