import './App.css';
import { Link } from 'react-router-dom';

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';


function App() {

  return (
    <div>
      <div className='Background'>
        <div className='Background-image'>
          <div className='Background-image-black'>
            <div className='mobile-textbox'>
            </div>
            <Carousel
                showArrows={true}
                infiniteLoop={true}
                showThumbs={false}
                autoPlay={false}
                showStatus={false}
                className='Carousel'
            >
                <div>
                  <h1 className='body-text4 font1 text-shadow'>다양한 배송 서비스 분석</h1>
                  <h1 className='body-text5 font2 text-shadow'>배송대행 상품비교</h1>
                  <h1 className='body-text5 font3 text-shadow'>지금바로 시작하기</h1>
                  <Link to='/shipping' className='button-round-120px'>
                    <div className='mobile-button-round'>
                      <h1 className='font30 button-text'>배송대행</h1>
                    </div>
                  </Link>
                  <div className='button-round-120px'>

                  </div>
                </div>
                <div>
                  <h1 className='body-text4 font1 text-shadow'>해외 쇼핑몰에도 간편하게</h1>
                  <h1 className='body-text5 font2 text-shadow'>구매대행 주문</h1>
                  <h1 className='body-text4 font3 text-shadow'>간편하게 구매하기</h1>
                  <Link to='/order' className='button-round-120px'>
                    <div className='mobile-button-round'>
                      <h1 className='font30 button-text'>구매대행</h1>
                    </div>
                  </Link>
                  <div className='button-round-120px'>

                  </div>
                </div>
                <div>
                  <h1 className='body-text4 font1 text-shadow'>문의사항 및 요청 접수</h1>
                  <h1 className='body-text5 font2 text-shadow'>고객센터</h1>
                  <h1 className='body-text4 font3 text-shadow'> </h1>
                  <Link to='/about' className='button-round-120px'>
                    <div className='mobile-button-round'>
                      <h1 className='font30 button-text'>바로가기</h1>
                    </div>
                  </Link>
                  <div className='button-round-120px'>

                  </div>
                </div>
            </Carousel>
          </div>
        </div>
        
      </div>
    </div>

  );
}

export default App;
