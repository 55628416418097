import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import OrderComponent from "./OrderComponent";
import { db } from "../firebase";
import { ref, uploadString } from 'firebase/storage'
import { storage } from "../firebase";
import { auth } from "../firebase";

import bank from '../img/bank-account.webp';
import account1 from '../img/account1.webp';
import nss1 from '../img/nss1-1.png';
import nss2 from '../img/nss1-2.png';
import nss3 from '../img/nss2-1.png';
import nss4 from '../img/nss2-2.png';
import personal from '../img/personal.png';
import nss from '../img/nss.webp';
import coupang from '../img/coupang.svg';

function SellerPage() {
    const navigate = useNavigate();

    const [attachment, setAttachment] = useState();
    const [message, setMessage] = useState("");

    const [termcheck1, setTermcheck1] = useState(false);
    const [termcheck2, setTermcheck2] = useState(false);
    const [showterm, setShowterm] = useState(false);

    const [show, setShow] = useState(0);

    const onFileChange = (evt) => {
      // 업로드 된 file
      const files = evt.target.files;
      const theFile = files[0];
  
      // FileReader 생성
      const reader = new FileReader();
  
      // file 업로드가 완료되면 실행
      reader.onloadend = (finishedEvent) => {
        // 업로드한 이미지 URL 저장
        const result = finishedEvent.currentTarget.result;
        setAttachment(result);
      };
      // 파일 정보를 읽기
      reader.readAsDataURL(theFile);
    };
  
    const onClearAttachment = () => setAttachment(null);
  
    const onSubmit = () => {
        if(attachment == null){
            setMessage("파일을 업로드해주세요.");
        } else{
            let b = Math.random().toString(30).substring(2, 15);
            const stor = storage;
            const fileRef = ref(stor, 'files/' + auth.currentUser.uid + '/' + b + '.xlsx');
            navigate("/success" , {state : { title : "주문완료" , text : "입금이 확인된 후 배송이 진행됩니다."} });
        }
    };

    const infobutton = (index) => {
        if(show == index){
            setShow(0);
        } else{
            setShow(index);
        }
    }

    const contract = () => {
        if(termcheck1 && termcheck2){
            onSubmit();
        } else{
            setMessage("약관에 동의해주세요.");
        }
    }


    return (
            <div className='About-Background'>
                <div className='body-div'>
                    <div className='contents-div'>
                            <div className='title'>
                                <h1 className='contents-text-title font18'>구매대행 셀러 주문</h1>
                            </div>
                            <form>
                                <div className="shipping-rate">
                                    <input className="seller-input" placeholder="" type="file" accept="*" onChange={onFileChange}/>
                                </div>
                            </form>
                            <div className="button-margin">
                                <div className="title">
                                    <h1 className='contents-text-title font18'>* 개인정보 위탁업체 제공이 안내된 상품만 주문해주세요</h1>
                                    <h1 className='contents-text-title font18'>예시 이미지</h1>
                                    <img alt="smartstore" src={personal} className="account-image1"/>
                                </div>
                                <h1 className='contents-text-title font18'>주문방법 안내</h1>
                                <div className='title button-margin'>
                                    <img alt="smartstore" onClick={() => infobutton(1)} src={nss} className="seller-logo"/>
                                    <img alt="smartstore" onClick={() => infobutton(2)} src={coupang} className="seller-logo"/>
                                </div>
                                {
                                    show == 1 ?
                                    <div>
                                        <div className='title'>
                                            <h1 className='contents-text-title font18'>스마트스토어 주문방법</h1>
                                        </div>
                                        <h2 className='contents-text-title font18'>1. 주문내역 엑셀 다운로드</h2>
                                        <h2 className='contents-text-title font18'>2. 엑셀 주문</h2>
                                    </div> : null
                                }
                                {
                                    show == 2 ?
                                    <div>
                                        <div className='title'>
                                            <h1 className='contents-text-title font18'>쿠팡 주문방법</h1>
                                        </div>
                                        <h2 className='contents-text-title font18'>1. 주문내역 엑셀 다운로드</h2>
                                        <h2 className='contents-text-title font18'>2. 엑셀 주문</h2>
                                    </div> : null
                                }
                                <div>
                                    <div className='title'>
                                        <h1 className='contents-text-title font18'>계약사항 확인</h1>
                                    </div>
                                    <div onClick={() => setTermcheck1(!termcheck1)}>
                                        <div className="signup-checkbox-input-div">
                                            <input className="signup-checkbox" type="checkbox" checked={termcheck1} />
                                        </div>
                                        <span className="signup-checkbox-text">개인정보 제공에 따른 안내를 수행하였습니다.</span>
                                    </div>
                                    <div onClick={() => setTermcheck2(!termcheck2)}>
                                        <div className="signup-checkbox-input-div">
                                            <input className="signup-checkbox" type="checkbox" checked={termcheck2} />
                                        </div>
                                        <span className="signup-checkbox-text">구매대행 계약의 내용을 확인하였으며 이에 동의합니다.</span>
                                        <div onClick={() => setShowterm(!showterm)} className="signup-checkbox-term">내용보기</div>
                                    </div>
                                    <h4 className="seller-message">{message}</h4>
                                        <div className="Billing-button-div">
                                            <button onClick={contract} className="Billing-button button-margin">주문하기</button>
                                        </div>
                                </div>
                                <div className="signup-checkbox-div button-margin">
                                <h1 className='contents-text-title font18'>입금 안내</h1>
                                </div>
                                <div className="account-div">
                                    <img alt="bank" src={account1} className="account-image" />
                                    <div className="account-text">
                                        <div className="account-title">(주)한국외상결제</div>
                                        <div>518-074529-01-010</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
    );
  }

export default SellerPage;
