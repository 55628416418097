import { db } from "../firebase";
import { get, ref, remove, set } from "firebase/database";
import { auth } from "../firebase";
import { useState } from "react";
import { useNavigate ,useLocation } from "react-router-dom";
import { loadTossPayments } from '@tosspayments/payment-sdk'

import { Term4 } from "./Termpages";
import { useEffect } from "react";
const clientKey = "live_ck_oeqRGgYO1r5bL10qoEW8QnN2Eyaz";

function BillComponent() {
    const navigate = useNavigate();
    const { state } = useLocation();

    const tossPayments = loadTossPayments(clientKey)

    const [show, setShow] = useState(false);

    const [postaddress, setpostaddress] = useState("")
    const [postaddress2, setpostaddress2] = useState("")
    const [postCity, setpostCity] = useState("")
    const [postState, setpostState] = useState("")
    const [postZip, setpostZip] = useState("")
    const [postTel, setpostTel] = useState("")
    const [postcode, setpostcode] = useState("")

    const [Contact, setContact] = useState("")
    const [guide, setguide] = useState("")

    const [shipping, setshipping] = useState("")

    useEffect(() => {
        if(state.orderlist != undefined){
            if(state.orderlist['shipping'] != undefined){
                setshipping(state.orderlist['shipping'])
            }
        }

        auth.onAuthStateChanged((user) => {
            get(ref(db, "/users/" + auth.currentUser.uid + "/postcode/")).then((snapshot) => {
                setpostcode(snapshot.val())
            })
        });
        if(state.order){
            get(ref(db, "/center/" + state.order[0] + "/" + state.order[1] + "/")).then((snapshot) => {
                setpostaddress(snapshot.val().Address1)
                setpostaddress2(snapshot.val().Address2)
                setpostCity(snapshot.val().City)
                setpostState(snapshot.val().State)
                setpostZip(snapshot.val().ZipCode)
                setpostTel(snapshot.val().Tel)
            })
            get(ref(db, "/center/" + state.order[0] + "/" )).then((snapshot) => {
                setContact(snapshot.val().Contact)
                setguide(snapshot.val().guide)
            })
        }
    }, []);

    const date = new Date(state.date).getFullYear()
    + "년 " + (new Date(state.date).getMonth() + 1) + "월 "  + new Date(state.date).getDate() + "일";

    const testcrypt = () => {
        let type = "";
        if(state.type == "shipping"){
            type = "배송대행";
        }
        if(state.type == "order"){
            type = "구매대행";
        }

        loadTossPayments(clientKey).then(tossPayments => {
            
            tossPayments.requestPayment('카드', { // 결제 수단 파라미터
            // 결제 정보 파라미터
            amount: state.price - state.couponprice, // 결제 금액
            orderId: state.key, // 주문번호
            orderName: type + "결제",
            customerName: state.KoreanName, // 결제 고객 이름
            successUrl: 'http://localhost:3000/payment/' + state.paymentkey, // 결제 성공 시 리다이렉트할 URL
            failUrl: 'http://localhost:3000/usage',
          })
          .catch(function (error) {
            if (error.code === 'USER_CANCEL') {
              // 결제 고객이 결제창을 닫았을 때 에러 처리
            } else if (error.code === 'INVALID_CARD_COMPANY') {
              // 유효하지 않은 카드 코드에 대한 에러 처리
            }
          })
          
          })
          
          
    }

    const billtest = () => {
        testcrypt();
    }

    const cancelbill = () => {
        if(state.couponkey != ""){
            set(ref(db, "/users/" + auth.currentUser.uid + "/coupons/" + state.couponkey + "/used/"), false);
        }
        remove(ref(db, "/users/" + auth.currentUser.uid + "/bill/" + state.key + "/"))
        navigate("/success" , {state : { title : "주문취소" , text : "주문이 정상적으로 취소되었습니다."} });
    }

    const cancelrequest = () => {
        if(state.couponkey != ""){
            set(ref(db, "/users/" + auth.currentUser.uid + "/coupons/" + state.couponkey + "/used/"), false);
        }
        set(ref(db, "/users/" + auth.currentUser.uid + "/bill/" + state.key + "/status/"), "배송취소중");
        set(ref(db, "/cancel/" + auth.currentUser.uid + "/" + state.key ), "배송취소중");
        navigate("/success" , {state : { title : "주문취소" , text : "주문이 정상적으로 취소되었습니다."} });
    }

    const backrequest = () => {
        if(state.couponkey != ""){
            set(ref(db, "/users/" + auth.currentUser.uid + "/coupons/" + state.couponkey + "/used/"), true);
        }
        set(ref(db, "/users/" + auth.currentUser.uid + "/bill/" + state.key + "/status/"), "결제완료");
        set(ref(db, "/cancel/" + auth.currentUser.uid + "/" + state.key ), "재접수");
        navigate("/success" , {state : { title : "주문접수" , text : "주문이 정상적으로 접수되었습니다."} });
    }

    useState(() => {
        if(state.coupon == ""){
            state.coupon = "없음";
        }
        if(state.request == ""){
            state.request = "없음";
        }
    }, []);

    return ( 
        <div className="Coupon-background" >
            <div className="Bill1">
                주문내역
            </div>
            <div className="Bill2">
                {state.status == "결제완료" ? state.dilivered : state.status}
                {state.status == "결제완료" ? <div>
                    배송대행지 주소<br/>
                    Address1 : {postaddress} <br/>
                    Address2 : {postaddress2} <br/> 개인 사서함번호 {postcode}<br/> (위 주소의 CRV 뒤에 반드시 입력해주세요 예시) CRV-123456 )<br/>
                    City : {postCity} <br/>
                    State : {postState} <br/>
                    ZipCode : {postZip} <br/>
                    Tel : {postTel}<br/>
                </div> : null}
                <div className="button-margin">
                {state.status == "결제완료" ? state.status : null}
                </div>
            </div>
            <div className="Bill2">
                결제금액
            </div>
            <div className="Bill2">
                
            {  (state.price - state.couponprice) > 0 ? state.price - state.couponprice : 0}원 { state.couponprice == 0 ? null : "(할인 " + state.couponprice + "원)"}
            {shipping == "" ? null : "(배송비 " +  shipping + "원 포함)"}
            </div>
            <div className="Bill2">
                배송주소
            </div>
            <div className="Bill3">
                <div>
                {state.KoreanName} {state.FirstName} {state.LastName} / {state.customcode} <br/> {state.Address} {state.Address2}
                </div>
            </div>
            <div className="Bill2">
                결제방법
            </div>
            <div className="Bill3">
                {state.payment}
            </div>
            <div className="Bill2">
                사용한 쿠폰
            </div>
            <div className="Bill3">
                <div>
                {state.couponprice > 0 ? state.couponprice + "원" : null}
                </div>
                <div>
                {state.coupon}
                </div>
            </div>
            <div className="Bill2">
                요청사항
            </div>
            <div className="Bill3">
                {state.request}
            </div>
            <div className="Bill2">
                주문시간
            </div>
            <div className="Bill3">
                {date}
            </div>
            {
                state.status == "결제완료" ?
                <div className="button-margin" onClick={() => setShow(!show)}>
                    <span className="signup-checkbox-text font18">환불안내</span>
                    <div className="signup-checkbox-term">내용보기</div>
                    {
                        show ?
                        <div>
                        <Term4 />
                        </div> : null
                    }
                </div> : null
            }
            {
                state.status == "결제대기중" ?
                <div className="Billing-button-div">
                    {state.payment == "무통장입금" ? null : <button onClick={billtest} className="Billing-button button-margin">결제하기</button>}
                    <button onClick={cancelbill} className="Billing-button button-margin">주문 취소</button>
                </div> : null
            }
            {
                state.status == "결제완료" ?
                <div className="Billing-button-div">
                    <button onClick={cancelrequest} className="Billing-button button-margin">배송취소신청</button>
                </div> : null
            }
            {
                state.status == "배송취소중" ?
                <div className="Billing-button-div">
                    <button onClick={backrequest} className="Billing-button button-margin">배송 재신청</button>
                </div> : null
            }
            <div className="Bill1 button-margin">
            </div>
        </div>
    );
  }

export default BillComponent;
