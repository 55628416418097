import { useState } from "react";
import { auth, db } from "../firebase";
import { ref, get, set } from "firebase/database";

 export function Home() {
    return <div className="Background" >Home</div>;
  }

export function About() {

  const [summary, setsummary] = useState("");
  const [contact, setContact] = useState("");

  const request = () => {
    if(summary != "" && contact != ""){
    let b = Math.random().toString(30).substring(2, 15);


    
    
    if(auth.currentUser){
      const a = ref(db, "/cs/user/" + auth.currentUser.uid  + "/" + b);
      let c = {}
  
      c["summary"] = summary;
      c["contact"] = contact;
      if(auth.currentUser){
        c["uid"] = auth.currentUser.uid;
      } else{
        c["uid"] = "not login"
      }
  
      if(get(a).snapshot){
        
      } else{
        
        set(a, c)
      }
      
      setsummary("");
      setContact("");
    } else{
      const a = ref(db, "/cs/guest/" + b);
      let c = {}
  
      c["summary"] = summary;
      c["contact"] = contact;
      if(auth.currentUser){
        c["uid"] = auth.currentUser.uid;
      } else{
        c["uid"] = "not login"
      }
  
      if(get(a).snapshot){
        
      } else{
        
        set(a, c)
      }
      
      setsummary("");
      setContact("");
    }
    }
  }

    return (
      <div>
        <div className='About-Background'>
              <div className='About-Background-image'>
                <div className='About-textbox'>
                  <h1 className='body-text about-text text-shadow'>고객센터</h1>
                  <h1 className='about-text1 font20 text-shadow'>문의사항 및 요청사항을 남겨주세요</h1>
                </div>
              </div>
            <div className='About-contents-div'>
              <textarea value={summary} onChange={e => setsummary(e.target.value)} className="About-textarea" placeholder="문의사항을 남겨주시면 신속하게 확인 후 답변드리겠습니다." />
              <textarea value={contact} onChange={e => setContact(e.target.value)} className="About-textarea-contact" placeholder="문의사항을 안내받을 연락처(이메일, 전화번호 등)을 남겨주세요" />
              <div className="shipping-button-align-center">
                <button className="service-button" onClick={request}>문의하기</button>
              </div>  
            </div>
        </div>
      </div>
    );
  }

